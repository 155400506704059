import { HomePageData } from "../../Data/HomePageData"
import { GetTranlatedValue } from "../../Data/LanguageContext"

export const SevenSection = () => {
    return (
        <div className="container" style={{ paddingTop: 80, paddingBottom: 20 }}>
            <div className='row'>
                <div className='col-lg-9 mx-auto'>
                    <h2 className=' text-center mb-4' style={{ fontWeight: 700, color: '#3c5363' }}>
                        {GetTranlatedValue(HomePageData?.SEVENTHSECTION?.seventhheading)}
                    </h2>
                    <p className='text-center mb-5'>
                        {GetTranlatedValue(HomePageData?.SEVENTHSECTION?.seventhtext)}
                    </p>
                </div>
            </div>
            {/* <svg class="w-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1413.527 364.239">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_7876" data-name="Rectangle 7876" width="65.504" height="48.112" fill="#194250"></rect>
                    </clipPath>
                </defs>
                <g id="Group_18547" data-name="Group 18547" transform="translate(23.783 -4515.76)">
                    <g id="Bg" transform="translate(-22.369 4517.134)">
                        <path id="Path_2" data-name="Path 2" d="M0,319.865C132.747,190.058,440.856,62.7,705.859,148.421S1350.979,98.458,1410.739,0" transform="translate(0 0)" fill="none" stroke="#b0ffed" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="8"></path>
                    </g>
                    <text id="_15_Industry_Awards_" data-name="15+ Industry Awards" transform="translate(250 4770.999)" fill="#00b087" font-size="30" font-family="Montserrat-SemiBold, Montserrat" font-weight="600"><tspan x="-23.325" y="0"></tspan><tspan fill="#2c5363" font-size="25"><tspan x="-61.013" y="29" >Sign Up</tspan></tspan></text>
                    <text id="_8_operating_spaces" data-name="8+ operating spaces" transform="translate(677 4760.999)" fill="#00b087" font-size="30" font-family="Montserrat-SemiBold, Montserrat" font-weight="600"><tspan x="-18.63" y="0"></tspan><tspan fill="#2c5363" font-size="25"><tspan x="-63.362" y="29">Source</tspan></tspan></text>
                    <text id="_70_Adoption_Rate" data-name="70%
                  Adoption
                  Rate" transform="translate(1115 4770.499)" fill="#00b087" font-size="30" font-family="Montserrat-SemiBold, Montserrat" font-weight="600"><tspan x="-31.815" y="0"></tspan><tspan fill="#2c5363" font-size="25"><tspan x="-60.063" y="29">Service</tspan></tspan></text>
                    <g id="Group_18546" data-name="Group 18546">
                        <circle id="Ellipse_2564" data-name="Ellipse 2564" cx="46" cy="46" r="46" transform="translate(200 4630)" fill="#ebfffa"></circle>
                        <g id="Group_16577" data-name="Group 16577" transform="translate(220 4645)">
                            <g id="Group_16576" data-name="Group 16576">
                                <svg width="64px" height="64px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>i</title> <g id="Complete"> <g id="user-add"> <g> <path d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#2c5363" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.984"></path> <circle cx="9" cy="7" r="4" fill="none" stroke="#2c5363" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.984"></circle> <line x1="17" y1="11" x2="23" y2="11" fill="none" stroke="#2c5363" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.984"></line> <line x1="20" y1="8" x2="20" y2="14" fill="none" stroke="#2c5363" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.984"></line> </g> </g> </g> </g></svg>
                            </g>
                        </g>
                    </g>
                    <g id="Group_18505" data-name="Group 18505" transform="translate(160 513)">
                        <g id="Group_18502" data-name="Group 18502" transform="translate(0 36)">
                            <circle id="Ellipse_2568" data-name="Ellipse 2568" cx="46" cy="46" r="46" transform="translate(461 4061)" fill="#ebfffa"></circle>
                            <g id="Group_16579" data-name="Group 16579" transform="translate(475.244 4075.15)">
                                <g id="Group_16578" data-name="Group 16578">
                                    <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="64px" height="64px" fill="#000000">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0">
                                        </g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round">
                                        </g>
                                        <g id="SVGRepo_iconCarrier">
                                            <circle class="st0" cx="16" cy="16" r="6" fill="none" stroke="#2c5363" strokeWidth={1.216} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
                                            </circle>
                                            <circle class="st0" cx="25" cy="7" r="2" fill="none" stroke="#2c5363" strokeWidth={1.216} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
                                            </circle>
                                            <path class="st0" d="M26.5,8.3c1.6,2.2,2.5,4.8,2.5,7.7c0,7.2-5.8,13-13,13S3,23.2,3,16S8.8,3,16,3c2.9,0,5.5,0.9,7.7,2.5" fill="none" stroke="#2c5363" strokeWidth={1.216} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
                                            </path>
                                        </g>
                                    </svg>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_18504" data-name="Group 18504" transform="translate(260 450)">
                        <circle id="Ellipse_2568-2" data-name="Ellipse 2568" cx="46" cy="46" r="46" transform="translate(795 4160)" fill="#ebfffa"></circle>
                        <g id="Group_16580" data-name="Group 16580" transform="translate(807.596 4175.109)">
                            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 12L11 14L15.5 9.5M7.33377 3.8187C8.1376 3.75455 8.90071 3.43846 9.51447 2.91542C10.9467 1.69486 13.0533 1.69486 14.4855 2.91542C15.0993 3.43846 15.8624 3.75455 16.6662 3.8187C18.5421 3.96839 20.0316 5.45794 20.1813 7.33377C20.2455 8.1376 20.5615 8.90071 21.0846 9.51447C22.3051 10.9467 22.3051 13.0533 21.0846 14.4855C20.5615 15.0993 20.2455 15.8624 20.1813 16.6662C20.0316 18.5421 18.5421 20.0316 16.6662 20.1813C15.8624 20.2455 15.0993 20.5615 14.4855 21.0846C13.0533 22.3051 10.9467 22.3051 9.51447 21.0846C8.90071 20.5615 8.1376 20.2455 7.33377 20.1813C5.45794 20.0316 3.96839 18.5421 3.8187 16.6662C3.75455 15.8624 3.43846 15.0993 2.91542 14.4855C1.69486 13.0533 1.69486 10.9467 2.91542 9.51447C3.43846 8.90071 3.75455 8.1376 3.8187 7.33377C3.96839 5.45794 5.45794 3.96839 7.33377 3.8187Z" stroke="#2c5363" stroke-width="0.9600000000000002" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </g>
                    </g>
                </g>
            </svg> */}
        </div>
    )
}