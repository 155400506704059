import React from 'react';
import './PaymentSegment.css';

const PaymentSegment = () => {
    // Sample data for two segments
    const segments = [
        {
            image: 'https://signdesk.com/images/linkIt/Finance_Industry.png',
            title: 'Bill Payment',
            text: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        },
        {
            image: 'https://signdesk.com/images/linkIt/Entertainment_Sector.png',
            title: 'Cash Collection',
            text: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        }
    ];
    const segments2 = [
        {
            image: 'https://signdesk.com/images/linkIt/Insurance_Services.png',
            title: 'Loan Repayment',
            text: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            image: 'https://signdesk.com/images/linkIt/Ed-Tech_Firms.png',
            title: ' Insurance Premium',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            image: 'https://signdesk.com/images/linkIt/Medical_Industry.png',
            title: 'Recharges',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
    ];

    return (
        <div className="main-Paymentsegment">
            <div className='container'>
                <h1><span>Lorem ipsum </span>dolor sit amet, consectetur</h1>
                <div className='paymentsegment-content'>
                    <div className='row'>
                        {segments.map((segment, index) => (
                            <div key={index} className='col-lg-6 col-md-6 col-sm-12 py-3'>
                                <div className='paymentsegment-1st' >
                                    <img src={segment.image} alt={`Segment ${index + 1}`} />
                                    <h2>{segment.title}</h2>
                                    <p>{segment.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='paymentsegment-content'>
                <div className='row'>
                    {segments2.map((segments2, index) => (
                        <div key={index} className='col-lg-4 col-md-4 col-sm-12 py-3'>
                            <div className='paymentsegment-1st' >
                                <img src={segments2.image} alt={`Segment ${index + 1}`} />
                                <h2>{segments2.title}</h2>
                                <p>{segments2.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </div >
    );
};

export default PaymentSegment;
