import React from 'react'
import './ReadMore.css'

const ImpactDT = () => {
    return (
        <>
            <div className='read-more-container'>
                <div className='read-more-main-container d-flex align-items-center justify-content-center'>
                    <div className='header text-center text-white'>
                        <h1>Digital Transformation</h1>
                    </div>
                </div>
            </div>
            <div className='read-more-main-container'>
                <div className='row mt-5'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='blog-intro'>
                            <div className='intro row'>
                                <h2>Introduction</h2>
                                <p>
                                    On the 75th year of our Independence, India is not only the largest and one of the most progressive democracy in this world but also one of the fastest-growing major economies, expanding at a rate almost three times the world average and on track to become a US$5 trillion economy before the end of the decade. We must acknowledge the role of multiple technological innovations which has been a key facilitator to this.
                                </p>
                                <p>
                                    Digital Transformation has made the most significant impact in our Lives in the last decade. This is concretized by the following facts
                                </p>
                            </div>
                            <div>
                                <ul className='blog-ul row'>
                                    <li>
                                        India today has more than 75 crore smartphones users
                                    </li>
                                    <li>
                                        133 crore Aadhaar cards bringing over 90% of the population in its fold
                                    </li>
                                    <li>
                                        More than 80 crore internet users,
                                    </li>
                                    <li>
                                        From 4G we are now advancing towards 5G.
                                    </li>
                                    <li>
                                        Universal Payments Interface (UPI) processed more than 46 billion transactions, breaching the US$1trillion mark
                                    </li>
                                    <li>
                                        Our data tariffs is amongst the lowest in the world
                                    </li>
                                    <li>
                                        The global digital transformation market size is expected to grow from USD 469.8 billion in 2020 to USD 1009.8 billion by 2025, at a CAGR of 16.5%, India digital transformation market is expected to reach $710.0 billion by 2024, registering a CAGR of 74.7%.
                                    </li>
                                </ul>
                            </div>
                            <div className='intro row'>
                                <p>
                                    The intent of the government towards digitization front in form of the ‘Digital India’ campaign has played a key role in bringing about this transformation.
                                </p>
                                <b>Impact to the Common lives</b>
                                <p>
                                    Digitization has made a significant impact to the Lives of the common man. Let’s see some of them
                                </p>
                            </div>
                            <div>
                                <ul className='blog-ul row'>
                                    <li>
                                        <b>UPI Payments</b>
                                        <br />
                                        The biggest impact perhaps is seen in terms of online payments or UPI payments, the literal omnipresence of QR codes around us itself explains the success of the initiative. The biggest paradigm shift has been in the way we pay, not only making transactions cashless but also bringing ease of financial supervision.
                                    </li>
                                    <li>
                                        <b>
                                            Digital platforms
                                        </b>
                                        <br />
                                        Digitization has redefined convenience, practically in all spheres of our lives be it Purchasing our essentials like groceries to helping us with an online medical consultation, ordering our medicines online, satiating our taste buds at comfort of our homes by offering food online, avoiding woeful ques be it for our Journey tickets or cinema tickets, Booking ourselves accommodations: Hotels or Rentals, keeping our dressing shelves abreast of latest fashion trends by purchasing apparels online or keeping ourselves socially active through social media platforms and what not! It’s all available at a click on your mobile.
                                    </li>
                                    <li>
                                        <b>Growth Opportunities</b>
                                        <br />
                                        India has witnessed a paradigm shift in terms of aspirations, that of taking up entrepreneurship as a substitute to employment. We have over 70000 start ups and over 100 unicorns as on date growing from just over 450 in 2016. Initiatives like AEPS, E-tailing, Point of sales Person in Insurance and banking correspondents in banking, customer Service Centre’s etc. have brought plethora of opportunity to Individuals for taking up entrepreneurship.
                                    </li>
                                </ul>
                            </div>
                            <div className='intro row'>
                                <p>
                                    It will be right to say that we have rapidly embraced the concept of digital transformation and currently the above mentioned topics are the most trending ones now but in the next few years we can expect more attention to be given on related topics that would include blockchain, metaverse and digital currencies.
                                </p>
                                <p>
                                    With the passage of time, digital transformation has become an integral part of many organisations and is all set to grow rapidly and be a game changer across all genre of industries and also will give birth to a new generation of professionals who would be mastering the nuances of digital transformation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImpactDT