import React from 'react'
import '../Banking/CspBankingHome.css'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { egovernanceServicePageData } from '../../Data/Services/EGovernanceData'


const CspEgovHome = () => {
    return (
        <div className='banking-home-container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
                        <div className='banking-left-content text-dark d-flex flex-column'>
                            <h2>
                                {GetTranlatedValue(egovernanceServicePageData?.EGOVERNANCESERVICES?.egovernanceheading)}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CspEgovHome