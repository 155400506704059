import React from 'react'
import BlogCards from './BlogCards/BlogCards'
import './Knowledge.css'
import LatestBlog from './LatestBlog/LatestBlog'

const Knowledge = () => {
    return (
        <div className='knowledge'>
            <LatestBlog />
            <BlogCards />
        </div>
    )
}

export default Knowledge