import React from 'react'
import Enterprise from './Enterprise/Enterprise'
import OurSolutions from './OurSolutions/OurSolutions'
import './Solutions.css'
import SolutionsHome from './SolutionsHome/SolutionsHome'

const Solutions = () => {
    return (
        <div className='solutions'>
            <SolutionsHome />
            <Enterprise />
            <OurSolutions />
        </div>
    )
}

export default Solutions