export const LANGUAGES = {
    HINDI: "HINDI",
    ENGLISH: "ENGLISH",
    MARATHI: "MARATHI"
}

export const GetTranlatedValue = (value) => {
    let type = localStorage.getItem("language");
    switch (type) {
        case "HINDI":
            return value?.HINDI;
        case "ENGLISH":
            return value?.ENGLISH;
        case "MARATHI":
            return value?.MARATHI;
        default:
            return value.ENGLISH;
    }
}


export const GetFontValue = () => {
    let type = localStorage.getItem("language");
    switch (type) {
        case "HINDI":
            return "Tiro Devanagari Hindi";
        case "ENGLISH":
            return "Montserrat";
        case "MARATHI":
            return "Tiro Devanagari Hindi";
        default:
            return "";
    }
}


export const GetWordDictonary = () => {
    let type = localStorage.getItem("language");
    switch (type) {
        case "HINDI":
            return ['बैंकिंग', 'भुगतान', 'निवेश', 'वित्तीय', 'यात्रा'];
        case "ENGLISH":
            return ['Banking', 'Payments', 'Investment', 'Financial', 'Travel',];
        case "MARATHI":
            return ['बँकिंग', 'पेमेंट्स', 'गुंतवणूक', 'आर्थिक', 'प्रवास',];
        default:
            return ['Banking', 'Payments', 'Investment', 'Financial', 'Travel',];;
    }
}