import React from 'react'
import './Contact.css'
import jigsaw from './jigsaw.png'
import { GetTranlatedValue } from '../Data/LanguageContext'
import { ContactUsPageData } from '../Data/ContactUsData'

const Contact = () => {
    return (
        <div className='contact-container '>
            <div className='contact-back d-flex justify-content-center'>
                <div className='d-flex flex-column '>
                    <h1 className='text-center'>
                        {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactusheading)}
                    </h1>
                    <p className='d-flex justify-content-center align-items-center'>
                        {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactusheading2)}
                    </p>
                </div>
            </div>
            <div className='contact-main-container py-5'>
                <div className='row contact-row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 p-3 contact-about-content'>
                        <div className='contact-h3 d-flex justify-content-start'>
                            <h3 className='d-flex justify-content-start'>  {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.teamcsp)}</h3>
                        </div>
                        <div className='contact-us-img-li'>
                            <div className='d-flex'>
                                <img src={jigsaw} alt='cont' />
                                <p>
                                    {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.filldetails)}
                                </p>
                            </div>
                        </div>

                        <div className='contact-us-img-li'>
                            <div className='d-flex'>
                                <img src={jigsaw} alt='cont' />
                                <p>
                                    {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.callus)}<span style={{fontWeight:600}}>  {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactnumber)}</span>
                                    <span> {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactnumbertext)}</span> 
                                </p>
                            </div>
                        </div>

                        <div className='contact-us-img-li'>
                            <p>{GetTranlatedValue(ContactUsPageData?.CONTACTUS?.mailus)} <span className='text-primary'>{GetTranlatedValue(ContactUsPageData?.CONTACTUS?.mail)}</span>{GetTranlatedValue(ContactUsPageData?.CONTACTUS?.address)}</p></div>

                    </div>
                    <div className='contact-form col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center'>
                        <div className='form'>
                            <div className='row'>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="fname" className="form-label">Full Name</label>
                                    <input type="text" className="form-control" id="fname" name="fullname" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="mobile" className="form-label">Mobile Number</label>
                                    <input type="phone" className="form-control" id="mobile" name="mobile" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="cname" className="form-label">Message</label>
                                    <textarea type="text" className="form-control" rows={4} id="cname" name="companyname" />
                                </div>
                                <div className='contact-btn-mobile'>
                                    <button className='btn text-white my-4 py-2 rounded-0 col-12'>Let's Talk</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact