import React from 'react'
import { Link } from 'react-router-dom'
import './OTP.css'
// import OTPIco from './shield.png'

const OTP = () => {

    return (
        <div className='otp-container'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column align-items-center justify-content-center'>
                    <div className='otp-heading'>
                        <h1 className='text-white'>OTP<br />Verification<br />Process</h1>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                    <div className='otp-box d-flex flex-column my-0 align-items-center justify-content-center'>
                        <div className='otp-icon d-flex align-items-center justify-content-center py-2'>
                            {/* <img src={OTPIco} alt='otp' /> */}
                        </div>
                        <div className='d-flex align-items-center pt-4'>
                            <h5>Enter OTP Code</h5>
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                            <form className='d-flex justify-content-between px-4 py-4'>
                                <input type='text' className='form-control text-center' maxLength={1} />
                                <input type='text' className='form-control text-center' maxLength={1} />
                                <input type='text' className='form-control text-center' maxLength={1} />
                                <input type='text' className='form-control text-center' maxLength={1} />
                            </form>
                            <div className='text-center d-flex flex-column align-items-center justify-content-center pt-2'>
                                <button className='btn opt-btn text-white'>VERIFY OTP</button>
                                <Link className='text-primary pt-3'>Resend OTP</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OTP