import CspBankingHome from "./CspBankingHome"
import CspBankingServices from "./CspBankingServices/CspBankingservices"

const CspBanking = () =>{
    return(
        <div className="about">
            <CspBankingHome/>
            <CspBankingServices/>
        </div>
    )
}
export default CspBanking