import React from 'react'
import { useEffect } from 'react'
import './Banking.css'
import Bankingsub from './Bankingsub'
import BankingImage from '../Assets/banking.jpg'
import { useLocation } from 'react-router-dom';
const Banking = () => {

    const location = useLocation();
    useEffect(() => {
        if (location.hash !== null) {
            let a = location.hash.toString().substr(1, location.hash.length);
            let dos = document.getElementsByClassName(a);
            if (dos.length > 0) {
                dos[0].click();
            }
        }
    }, [location]);

    return (
        <>
            <a  href='Services#banking' className={"banking"} style={{
                textIndent:'100%',
                whiteSpace:'nowrap',
                overflow:'hidden'
            }}></a>
            <div className='banking-container' id='banking'>
                <div className='banking-main-container'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-cente align-items-center p-0'>
                            <div className='bank-head'>
                                <h1>
                                    Banking
                                </h1>
                                <h5 className='pt-2'>
                                CSP empowers you to bring convenience to customers in your neighborhood by offering Money Transfer and Cash Withdrawal services through the Aadhaar Enabled Payment System (AePS). Our platform is lean and built with latest technology,ensures agile transactions with minimal failure.  
                                </h5>
                            </div>
                        </div>
                        <div className='services-side-img col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                            <div className='text-center'>
                                <img className='' src={BankingImage} alt='Banking' />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div>
                <Bankingsub />
            </div>
        </>
    )
}

export default Banking