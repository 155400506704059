import React, { useState } from "react";
import "./Login.css";
import Email from "./email.png";
import Less from "./previous.png";
import { Link } from "react-router-dom";
import Close from "./cancel.png";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import axios from "axios";
const Login = () => {
    const ToTop = () => {
        window.scrollTo(0, 0);
    }
    const { handleSubmit } = useForm("true");

    const [forgetEmail, setForgetEmail] = useState("");
    const [waitloader, setwaitloader] = useState(false);

    const handleemail = (e) => {
        console.log(e.target.value);
        setForgetEmail(e.target.value);
    };

    // Forget Password
    const forgetData = () => {
        let data = { mail: forgetEmail };

        let url = window.location.origin;
        axios
            .post(url + "/api/v1/forgetPassword", data)
            .then((res) => {
                console.log(res.data.data);

                toast(
                    <p style={{ fontSize: 16 }}>
                        Password send on your registered email ID
                    </p>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        newestOnTop: false,
                        closeOnClick: true,
                        rtl: false,
                        pauseOnFocusLoss: true,
                        draggable: true,
                        pauseOnHover: true,
                        type: "success",
                    }
                );

                document.getElementById("forget_btn_close").click();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const formdata = (data) => {
        console.log(data);
        let formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        let url = window.location.origin;
        formData.append("latitude", '11.99');
        formData.append("longitude", '11.88');
        axios
            .post(url + "/sso/login", formData)
            .then((response) => {
                console.log(response.data.data);
                if (response.data.data.Status) {
                    localStorage.setItem("token", response.data.data.AuthToken);
                    document.cookie = `authToken=${response.data.data.AuthToken}`;
                    toast.success("Login Successfully !", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setTimeout(() => {
                        window.location.replace(window.location.origin + "/csp/app");
                    }, 2000);
                } else {
                    toast.error(response.data.data.Message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((err) => {
                console.log("error", err);
            });
    };
    const getUrlStringAndRedirect = (e) => {
        e.preventDefault();
        let url = window.location.origin;
        axios.get(url + "/sso/Auth2").then(res => {
            if (typeof res.data.data.redirectUrl !== "undefined") {
                window.location.replace(res.data.data.redirectUrl);
            } else {
                setwaitloader(false);
                toast.error("Try Again !", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }).catch(err => {
            console.warn(err);
            setwaitloader(false);
            toast.error("Try Again !", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        })
    }

    return (
        <div className="login-container">
            <div className="login-main-container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center login-left-img">
                        <div className="login-heading-content">
                            <h1>
                                Welcome To
                                <br />
                                <span> Customer Service Point</span>{" "}
                            </h1>
                            <p className="text-white pt-3 w-90">
                                CSP uses a secure authentication system for
                                connecting our users to services available on  CSP.in. Enter your username and password here to
                                authenticate your log-in and enjoy seamless access to various Services and Offerings
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center login-form-small-device">
                        <div className="login-form">
                            <div className="login-form py-4 px-3">
                                <div className="d-flex align-items-center pb-4">
                                    <div className="col-1">
                                        <Link to="/">
                                            <div className="login-form-less d-flex justify-content-center align-items-center">
                                                <img src={Less} alt="back" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-11 d-flex justify-content-center align-items-center">
                                        <h5 className="m-0">
                                            <b>Login to Continue</b>
                                        </h5>
                                    </div>
                                </div>
                                <form className="row g-4" onSubmit={handleSubmit(formdata)}>

                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className="col-12 m-0 pt-2">
                                        <button
                                            onClick={getUrlStringAndRedirect}
                                            type="button"
                                            className="btn login-btn w-100 text-white py-2"
                                        >
                                            {
                                                waitloader ? <>
                                                    <div className="py-2 spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <span>Wait...!</span></> : <>Login With digilocker</>

                                            }


                                        </button>
                                        <ToastContainer />
                                    </div>

                                </form>
                                <hr />

                                <div className="term-condition d-flex justify-content-center">
                                    <Link to="/PrivacyPolicy">Privacy Policy&nbsp;&nbsp;|</Link>
                                    &nbsp;&nbsp;
                                    <Link to="/Terms&Conditions">Terms & Condition</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login-otp-box">
                <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Forget Password
                                </h5>
                                <img
                                    src={Close}
                                    className="btn-close-ico"
                                    id="forget_btn_close"
                                    alt="closebtn"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="pt-3 pb-2 px-2">
                                    <div className="col-md-12">
                                        <div className="position-relative">
                                            <input
                                                id="email"
                                                type="email"
                                                placeholder="Enter Registered Email Address"
                                                className="form-control input"
                                                onChange={handleemail}
                                                required
                                                key={"455"}
                                            />
                                            <img
                                                className="position-absolute h-100"
                                                src={Email}
                                                alt="user"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-4 pb-2 d-flex justify-content-center">
                                    <button
                                        onClick={forgetData}
                                        className="btn text-white login-otp-btn"
                                    >
                                        Submit
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
