import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';

const CspLoanMf = () => {


    const ServicesAeps = [

        {
            title: 'Loan against Mutual fund',
            paragraph: 'Help your customers get loans on their shares and mutual funds. The LAMF offering has the following features: Low Emi, Instant approval, No Pre payment charges, Retention of growth benefits, direct bank deposit, Flexible amounts   '
        },

    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 40 }}>
                    {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.loagainstmfheading)}
                </h1>
                <ul className="bottom-list">
                    {FinancialServicePageData.FINANCIALSERVICES.loagainstmfepara.map((sec, i) => (
                        <li>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                        <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspLoanMf;
