import CspPaymentServices from "./CspPaymentServices/CspPaymentService"
import CspPaymentHome from "./CspPaymenthome"

const CspPayment = () =>{
    return(
        <div className="about">
            <CspPaymentHome/>
            <CspPaymentServices/>
        </div>
    )
}
export default CspPayment