import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';

const CspCreditLine = () => {


    const ServicesAeps = [
        {
            title: 'Credit Line',
            paragraph: 'A credit line is a lending facility where a borrower can borrow money on a flexible and revolving basis from a lender. A borrower is required to apply for a credit line with the lender after which the details like income, credit score, borrowing history, etc. of the borrower are checked by the lender and the credit line limit is decided. A credit line is a more flexible mode of borrowing compared to a traditional loan. Different types of credit line include credit cards, small credit lines, etc.'
        },
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 40 }}>
                    {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.creditlineheading)}
                </h1>
                <ul className="bottom-list">
                    {FinancialServicePageData.FINANCIALSERVICES.creditlinepara.map((sec, i) => (
                        <li>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                        <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspCreditLine;
