import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { PaymentServicePageData } from '../../../Data/Services/PaymentServiceData';

const CspInsurancePremium = () => {

    const data2 = [
        {
            title: 'Insurance Premium Payment- CSP, through BBPS on its platform, brings to you the facility of Insurance Premium repayment. Help your customers pay their renewal premium for their LIC other insurance policies. By offering this facility to your customers, you would ensure a hassle free and timely renewal of their policies thus helping them to continue with their coverage and protection. This also provides an opportunity to build long term customer association and relationship opening doors for you to offer them other services or products as well.'
        },

    ];



    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(PaymentServicePageData?.PAYMENTSERVICES?.insuranceheading)}
                </h1>
                <div className='aeps-all-content'>
                    <div>
                        <h4 style={{ color: "#000" }}>{GetTranlatedValue(PaymentServicePageData?.PAYMENTSERVICES?.insurancecontent)}</h4>
                    </div>
                    <div className='loginlink'>
                        <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(PaymentServicePageData?.PAYMENTSERVICES?.fordeatils)}</p></a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CspInsurancePremium;
