import React from 'react'
import FinancialHome from './FinancialHome/FinanicalHome'
import WhatisFinancial from './WhatisFinancial/WhatisFinancial'
import FinancialReq from './FinancialReq/FinancialReq'
import FinancialSegment from './FinancialSegment/FinancialSegment'


const NewFinancial = () => {
    return (
        <div className='about'>
            <FinancialHome />
            <WhatisFinancial />
            <FinancialReq />
            <FinancialSegment />
        </div>
    )
}

export default NewFinancial