import '../WhatisPayment/WhatisPayment.css'
const WhatisPayment = () => {
    return (
        <div className="what-is-payment">
            <div className='container'>
                <div className='whatisbanking-heading'>
                    <h1>What is <span>Payment?</span></h1>
                </div>
                <div className='whatisbanking-content'>
                    <p>As per the available reports, nearly 190 million Indians still remain unbanked, this is despite the steep surge in Internet Usage and its coverage in terms of access across geographies and also the staggering growth of the smart phone market.</p>
                    <p>In order to enable banking services in unbanked and the rural geographies and also facilitate financial inclusion, Aadhar enabled payment Systems (AePS) was introduced in the year 2016 and by February 2023, 379.89 million transactions were recorded by AePS in India. The numbers are a strong indicative of the exponentially growing digital transactions and its scope. Also a fact worth mentioning here is the Intra state migratory population, around 450 million as per 2011 census, this speaks volumes about the available scope of usage of digital modes of transaction like AePS or DMT. A clear win for operators such services is the convenience that they offer to their customers in terms of availability and access.</p>
                    <p>The CSP platform brings to you digital Banking services to help bring convenience to your customers</p>
                </div>
            </div>
        </div>
    )
}
export default WhatisPayment