export const FooterServicePageData = {
    FOOTERSERVICES: {
        company: {
            HINDI: "कंपनी",
            ENGLISH: "Company",
            MARATHI: "कंपनी"
        },
        aboutus: {
            HINDI: "हमारे बारे में",
            ENGLISH: "About Us",
            MARATHI: "आमच्याबद्दल"
        },
        career: {
            HINDI: "करियर",
            ENGLISH: "Careers",
            MARATHI: "करिअर"
        },
        resources: {
            HINDI: "संसाधन",
            ENGLISH: "Resources",
            MARATHI: "संसाधने"
        },
        join: {
            HINDI: "हमसे जुड़ें",
            ENGLISH: "Join Us",
            MARATHI: "आमच्यात सामील व्हा"
        },
        contact: {
            HINDI: "संपर्क करें",
            ENGLISH: "Contact Us",
            MARATHI: "आमच्याशी संपर्क साधा"
        },
        services: {
            HINDI: "सेवाएँ",
            ENGLISH: "Services",
            MARATHI: "सेवा"
        },
        Banking: {
            HINDI: "बैंकिंग सेवाएँ",
            ENGLISH: "Banking Services",
            MARATHI: "बँकिंग सेवा"
        },
        Payment: {
            HINDI: "भुगतान सेवाएँ",
            ENGLISH: "Payment Services",
            MARATHI: "पेमेंट सेवा"
        },
        Financial: {
            HINDI: "वित्तीय सेवाएं",
            ENGLISH: "Financial Services",
            MARATHI: "आर्थिक सेवा"
        },
        Investment: {
            HINDI: "निवेश सेवाएँ",
            ENGLISH: "Investment Services",
            MARATHI: "गुंतवणूक सेवा"
        },
        Travel: {
            HINDI: "यात्रा सेवाएँ",
            ENGLISH: "Travel Services",
            MARATHI: "प्रवास सेवा"
        },
        importtantlink: {
            HINDI: "महत्वपूर्ण लिंक",
            ENGLISH: "Important Links",
            MARATHI: "महत्वाच्या लिंक्स"
        },
        PrivacyPolicy: {
            HINDI: "गोपनीयता नीति",
            ENGLISH: "Privacy Policy",
            MARATHI: "गोपनीयता धोरण"
        },
        TandC: {
            HINDI: "नियम एवं शर्तें",
            ENGLISH: "Terms & Conditions",
            MARATHI: "अटी व शर्ती"
        },
        Cookie: {
            HINDI: "कूकी नीति",
            ENGLISH: "Cookie Policy",
            MARATHI: "कुकी धोरण"
        },
        RefundCancellation: {
            HINDI: "धनवापसी एवं रद्दीकरण",
            ENGLISH: "Refund & Cancellation",
            MARATHI: "परतावा आणि रद्द करणे"
        },
        Desclaimer: {
            HINDI: "अस्वीकरण",
            ENGLISH: "Desclaimer",
            MARATHI: "डिस्क्लेमर"
        },
        ReachUs: {
            HINDI: "हम तक पहुंचें",
            ENGLISH: "Reach Us",
            MARATHI: "आमच्यापर्यंत पोहोचा"
        },
        Address: {
            HINDI: "कॉर्प.ऑफ 207, सिटी एवेन्यू कोलटे पाटिल द्वारा, रेंज रोवर शोरूम के पास, वाकड।",
            ENGLISH: "Corp.Off 207, City Avenue by Kolte Patil, near Range Rover ShowRoom, Wakad.",
            MARATHI: "Corp.Off 207, सिटी अव्हेन्यू बाय कोलते पाटील, रेंज रोव्हर शोरूमजवळ, वाकड."
        },
        mainDesclaimer: {
            HINDI: "अस्वीकरण: ग्राहक सेवा प्वाइंट (सीएसपी) सीएसपी इंडिया नेटवर्क प्राइवेट लिमिटेड द्वारा एक डिजिटल पेशकश है जिसका CIN नंबर U72900MH2022PTC385867 है।",
            ENGLISH: "Disclaimer : Customer Service Point ( CSP) is a digital offering by CSP India Network Private Limited bearing CIN No.U72900MH2022PTC385867.",
            MARATHI: "अस्वीकरण: ग्राहक सेवा पॉइंट (CSP) ही CSP इंडिया नेटवर्क प्रायव्हेट लिमिटेडची CIN No.U72900MH2022PTC385867 असलेली डिजिटल ऑफर आहे."
        },
        terms: {
            HINDI: "* कमाई का तात्पर्य हमारे भागीदारों द्वारा अर्जित कमीशन से है, जो सेवा की प्रकृति और सहमत नियमों और शर्तों के अधीन है।",
            ENGLISH: "*Earning refers to the commission earned by our partners, subject to the nature of service and agreed terms and conditions.",
            MARATHI: "*कमाई म्हणजे सेवेचे स्वरूप आणि मान्य केलेल्या अटी व शर्तींच्या अधीन आमच्या भागीदारांनी मिळवलेले कमिशन होय."
        },
        condition: {
            HINDI: "*चुनिंदा सेवाओं पर तत्काल कमीशन लागू हैकरण",
            ENGLISH: "*Instant Commision is applicable on Select Services",
            MARATHI: "*निवडक सेवांवर त्वरित कमिशन लागू आहे"
        },

        Copyright: {
            HINDI: "कॉपीराइट",
            ENGLISH: "Copyright",
            MARATHI: "कॉपीराइट"
        },
        rights: {
            HINDI: "सीएसपी इंडिया नेटवर्क प्राइवेट लिमिटेड। सर्वाधिकार सुरक्षित।",
            ENGLISH: "CopyCSP INDIA NETWORK PRIVATE LIMITED. All Rights Reserved.",
            MARATHI: "सीएसपी इंडिया नेटवर्क प्रायव्हेट लिमिटेड. सर्व हक्क राखीव."
        },
        startup: {
            HINDI: "एक स्टार्टअप इंडिया प्रमाणित कंपनी।",
            ENGLISH: "A Startup India Certified Company.",
            MARATHI: "एक स्टार्टअप इंडिया प्रमाणित कंपनी."
        },
    }
}