import React from 'react'
import './ServiceHome.css'

const ServiceHome = () => {

    return (
        <>
            <div className='service-home-container position-relative'>
                <div className='services-main-container container p-0'>

                    <div className='service-heading d-flex justify-content-center align-items-center'>
                        <h1 className='text-center text-white'>
                            Get empowered to bring convenience to your neighborhood. Set up a CSP! 
                        </h1>
                    </div>

                    <div className='py-5 service-heading-search d-flex justify-content-center'>
                        <div className='position-relative d-flex'>
                            <input className='form-control' type='text' placeholder='Search Keywords...' />
                            <div className='search-input-icon position-absolute p-2 h-100 d-flex align-items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-search text-muted ps-1" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='bg-light col-lg-12 col-md-12 col-sm-12 col-12 border rounded position-absolute service-new-tab' >
                    <div className=''>
                        <ul className='d-flex justify-content-evenly services-tab-list services-tab-list-bank '>
                            <li>
                                <a href='#bankingservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center active'>
                                        Banking
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href='#paymentservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Payments
                                    </div>
                                </a>
                            </li>
                            {/* <li>
                                <a href='#financial'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Financial
                                    </div>
                                </a>
                            </li> */}
                            <li>
                                <a href='#ticketingservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Ticketing
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href='#financeservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Finance
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href='#insuranceservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Insurance
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href='#governmentservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Government
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href='#legalservices'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Legal
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href='#other'>
                                    <div className='service-tab-div d-flex align-items-center justify-content-center'>
                                        Other
                                    </div>
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceHome