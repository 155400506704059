import React from 'react'
import './BecomePartner.css'
import { Link } from 'react-router-dom'
import BPartners from './Partners/BPartners'


const BecomePartner = () => {
    return (
        <>
            <div className='become-container'>
                <div className='become-main-container '>
                    <div className='become-content '>
                        <div className='row d-flex  justify-content-center'>
                            <div className='col-lg-6 col-md-6 co-sm-12 col-12 small-device-partner-left'>
                                <h1 className='text-white large-devices-become-heading'>
                                    <span className='py-5'>Partner with Us !</span><br />
                                    CSP invites you to create<br />a Growth Synergy<br /> 
                                </h1>

                                <h1 className='small-devices-become-heading'>
                                    <span className='py-5 become-color-span'>Partner with Us</span><br />
                                    <span className='text-white become-h1-span'>CSP invites you to create a Growth Synergy. Fill in your details, we would get in touch soon!</span>
                                </h1>
                            </div>
                            <div className='col-lg-5 col-md-6 co-sm-12 col-12'>
                                <div className='become-form pt-5 pb-3 px-4'>
                                    <form className="row g-3">
                                        <div className="col-md-6">
                                            <input type="text" placeholder='Name' className="form-control" id="name" />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" placeholder='Copmany Name' className="form-control" id="companyname" />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="number" placeholder='Phone Number' className="form-control" id="Phone Number" />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="email" placeholder='Work Email' className="form-control" id="email" />
                                        </div>
                                        <div className="col-12">
                                            <textarea rows={3} type="text" className="form-control" id="help" placeholder="How can we help ?" />
                                        </div>
                                        <p className='check-head '>Please Select You Are Interested In</p>
                                        <div className="checkboxes col-12 d-flex">
                                            <div className='col-2 d-flex justify-content-center'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                    <label className="form-check-label" for="gridCheck">
                                                        API
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-3 d-flex justify-content-center'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                    <label className="form-check-label" for="gridCheck">
                                                        PAYMENT
                                                    </label>
                                                </div>
                                            </div>

                                            <div className='col-4 d-flex justify-content-center small-device-channel-hide'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                    <label className="form-check-label" for="gridCheck">
                                                        PARTNER
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-3 d-flex justify-content-center'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                    <label className="form-check-label" for="gridCheck">
                                                        OTHER
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn w-100 text-white">Submit</button>
                                        </div>

                                        <div className='form-policy col-12 text-center'>
                                            <p>Read our <Link to='/PrivacyPolicy'>privacy policy</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='partner-bottom'>
                <BPartners />
                
            </div>
        </>
    )
}

export default BecomePartner