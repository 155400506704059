import React from 'react'
import { Link } from 'react-router-dom'
import './Enquiry.css'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { AboutPageData } from '../../Data/AboutPageData'

const Enquiry = () => {
    return (
        <>
            <div className='enquiry-top-container text-center text-white py-4'>
                <div className='enquiry-top'>
                    <h3 className='m-0'>
                        {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.contact)}
                    </h3>
                    <Link className='enquiry-no'>020-711-72777</Link>
                    <h3 className='m-0'>
                        {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.contactmail)}
                    </h3>
                </div>
            </div>
        </>
    )
}

export default Enquiry