import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import kgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import sellanytime from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import safety from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import buylow from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import physicalgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { InvestmentServicePageData } from '../../../Data/Services/InvestmentServiceData';



const CspDematAccount = () => {


    const data2 = [
        {
            title: 'A demat account also known as a dematerialized account is a type of electronic account used to hold securities such as stocks, bonds, mutual funds, and other financial instruments in digital form. It is a safe and convenient way to hold, trade, and manage investments without physical documents or certificates.The Indian markets over the last 10 years have given 12-13% compounded on the benchmark level.'
        },
    ];

    const bottomListItems = [
        {
            title: 'Guaranteed 24K Gold',
            paragraph: 'Over 4.2 million Demat accounts were opened in December 2023, trends indicate an exponential Growth'
        },
        {
            title: 'Sell anytime from home',
            paragraph: 'There has been a sizeable influx in the first time investors who are  in the age group of 25-35 years          '
        },
        {
            title: 'Earn income on gold',
            paragraph: 'People who have been working from 5-10 years and in an annual salary bracket of 5-15 lacs income are participating in the market'
        },
        {
            title: 'Safety guaranteed',
            paragraph: 'Significant traction with regard to participation in stock markets have been observed from Tier 2 and 3 geographies'
        },
    ];



    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.demataccountheading)}
                </h1>
                <div className='aeps-all-content'>
                    {InvestmentServicePageData.INVESTMENTSERVICES.demataccountpara.map((sec, i) => (
                        <div>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p className='mt-2'>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <h3 style={{ color: "#333", textAlign: "left", fontSize: 16, paddingBottom: 10 }}>
                    {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.digitalgolbelow)}
                </h3>
                <ul className="bottom-list">
                    {InvestmentServicePageData.INVESTMENTSERVICES.demataccountist.map((sec, i) => (
                        <li>
                            <div className='d-flex pb-2 gap-2'>
                                <div className='mt-1'>
                                    <img src={dot} style={{ width: 17 }}></img>
                                </div>
                                <div>
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='loginlink'>
                    <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.fordeatils)}</p></a>
                </div>

            </div>
        </div>
    );
};

export default CspDematAccount;
