import React from 'react'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { HomePageData } from '../../Data/HomePageData'

export const FourthSection = () => {
    const sectionData = [
        {
            id: 1,
            icon: 'https://csp.in/static/media/access.c5cff2c331042e14063b.png',
            title: 'Instant Access',
            subtitle: 'Easy Onboarding'
        },
        {
            id: 2,
            icon: 'https://csp.in/static/media/accountant.ac8d7fd14c667492be7c.png',
            title: 'Instant Opportunity',
            subtitle: 'Serve your Own Locality'
        },
        {
            id: 3,
            icon: 'https://csp.in/static/media/support.50b450811c4070e1db95.png',
            title: 'Instant Support',
            subtitle: 'Dashboard, Chat, Connect'
        },
        {
            id: 4,
            icon: 'https://csp.in/static/media/rupee.787c4e9d982be9867472.png',
            title: 'Instant Income',
            subtitle: 'Real time Commission*'
        },
    ]
    return (
        <div className='py-5 position-relative' style={{ backgroundColor: '#effaff' }}>
            <div className='position-absolute rounded-circle bg-white d-flex align-items-center justify-content-center' style={{
                boxShadow: '0px 3px 6px #00000029',
                height: 80,
                width: 80,
                top: -40,
                left: '50%',
                transform: 'translateX(-50%)'
            }}>
                <svg width="50px" height="50px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#16b287" stroke-width="0.576"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7071 6.29289C20.0976 6.68342 20.0976 7.31658 19.7071 7.70711L10.4142 17C9.63316 17.7811 8.36683 17.781 7.58579 17L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289Z" fill="#16b287"></path> </g></svg>
            </div>
            <div className='container mt-4'>
                <h2 className="text-center" style={{ fontWeight: 700 }}>{GetTranlatedValue(HomePageData?.FORTHSECTION?.forthseactionheading)} <span style={{ color: '#16b287' }}>  CSP!</span></h2>
                <p class="text-center mb-5">
                    {GetTranlatedValue(HomePageData?.FORTHSECTION?.forthseactiontext)}
                </p>
                <div className='row'>
                    {HomePageData.FORTHSECTION.benefits.map((sec, i) => (
                        <div key={"sec-forut"+i} class="col-md-6 col-lg-3 col-sm-6 pt-2 pb-3 pb-md-5 text-center">
                            <div>
                                <img src={sec.img} className='img-fluid' alt={"d"} style={{ height: 60 }} />
                                <div className='mt-4'>
                                    <b class="fw-700" style={{ fontSize: 18 }}>{GetTranlatedValue(sec.title)}</b>
                                    <p className='m-0' style={{ fontWeight: 600 }}>{GetTranlatedValue(sec.subtitle)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
