import React, { useState, useEffect } from 'react';
import '../../Banking/CspBankingServices/CspBankingServices.css';

const services = [
  {
    "title": "AePS",
    "content": require('./Aepsque.js').default
  },
  {
    "title": "BBPs",
    "content": require('./Bbps.js').default
  },
  {
    "title": "Digital Gold",
    "content": require('./DigitalGoldFaq.js').default
  },
  
];
// const data2 = [
//   {
//     title: 'In simple words Investment can be explained as putting ones money in an asset with the aim of generating income. Investments may come in different forms, such as Gold, Physical Assets, Mutual funds, Insurance etc. A recent trend in as observed in retail investors is their eagerness to invest their money in the stock market, this is clearly visible by the exponential rise in the no of Demat accounts opened in the recent past.At CSP we bring to you offerings which will help you to facilitate your customers for a prudent investment.'
//   },
// ]; 

const FaqQue = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        {/* <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}> Scope and Offering</h2>
          <div className='aeps-all-content'>
            {data2.map((item, index) => (
              <div key={index}>
                <h4 style={{ color: "#000", }}>{item.title}</h4>
              </div>
            ))}
          </div>
        </div> */}
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqQue;
