import React from 'react'
import MyImg from './my-business.png'

const MediaCards = () => {
    return (
        <div className='media-head pb-5'>
            <div className='row px-3'>
                <div className='col-lg-5 col-md-12 col-sm-12 col-12 pt-5'>
                    <div className='media-card-img d-flex align-items-center justify-content-center'>
                        <img src={MyImg} alt='media img' />
                    </div>
                </div>
                <div className='col-lg-7 col-md-12 col-sm-12 col-12 pt-5'>
                    <div className='media-card-content py-5 px-5 text-white d-flex flex-column align-items-center'>
                        <div>
                            <h5>Lorem Ipsum</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <button className='btn text-white'>Read More</button>
                        </div>
                    </div>
                </div> 
            </div>

            <div className='row px-3'>
                <div className='col-lg-7 col-md-12 col-sm-12 col-12 pt-5'>
                    <div className='media-card-content py-5 px-5 text-white d-flex flex-column align-items-center'>
                        <div>
                            <h5>Lorem Ipsum</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <button className='btn text-white'>Read More</button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-5 col-md-12 col-sm-12 col-12 pt-5'>
                    <div className='media-card-img d-flex align-items-center justify-content-center'>
                        <img src={MyImg} alt='media img' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MediaCards