import React, { useEffect, useState } from 'react'
import './AboutHome.css'
import AboutImg from './aboutimg.jpg'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { AboutPageData } from '../../Data/AboutPageData'
const AboutHome = () => {
    localStorage.getItem("language");
    const [s, sets] = useState(localStorage.getItem("language"));
    useEffect(() => {
        sets(localStorage.getItem("language"));
    }, [localStorage.getItem("language")]);

    return (
        <div className='about-home-container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12' style={{paddingBottom:"77px"}}>
                        <div className='about-left-content text-white d-flex flex-column about-home'>
                            <div className=' about-home-content pdiv d-flex flex-column '>
                                <p className=''>
                                    {/* CSP is a digitally powered, secure and simple platform enabled to provide multiple (300+) services across Banking, Bill Payment, Finance, Legal, Government, Travel etc. */}
                                    {/* {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.aboutheading)} */}
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading1)
                                    }
                                </p>
                                <p className=''>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading2)
                                    }                                </p>
                                <p>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading3)
                                    }                                </p>
                                <p>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading4)
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-center'>
                        <img className='about-home-img' src={AboutImg} alt='aboutimg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHome