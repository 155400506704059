import React from 'react'
import './Travel.css'

const Travelsub = () => {
    
    return (
        <div className='travel-sub-container'>
            <div className='travel-sub-main-container'>
                <div className='row text-center d0flex justify-content-center icon-services-container pb-3 pt-1'>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='travel-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-bus-station d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Bus Booking
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='travel-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-underground d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Train Booking
                        </p>
                    </div>

                    {/* <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='travel-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-plane d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Flight Booking
                        </p>
                        
                    </div> */}

                    {/* <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='travel-services d-flex justify-content-center align-items-center' >
                            <i class="flaticon flaticon-taxi d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Cab Booking
                        </p>
                    </div> */}

                    {/* <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='travel-services d-flex justify-content-center align-items-center' >
                            <i class="flaticon flaticon-hotel d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Hotel Booking
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='travel-services d-flex justify-content-center align-items-center' >
                            <i class="flaticon flaticon-traveler-with-a-suitcase d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Holidays Booking
                        </p>
                    </div> */}

                </div>

            </div>
        </div>
    )
}

export default Travelsub