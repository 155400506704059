import React from 'react';
import { useEffect } from 'react';
import './Services.css'
import Payments from './Payments/Payments'
import ServiceHome from './ServiceHome/ServiceHome'
import Travel from './Travel/Travel'
import Goverment from './Goverment/Goverment'
import Legal from './Legal/Legal'
import { useLocation } from 'react-router-dom';
import Banking from './Banking/Banking';
import Insurance from './Insurance/Insurance';
import Financial from './Financial/Financial';

const Services = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash !== null) {
            let a = location.hash.toString().substr(1, location.hash.length);
            let dos = document.getElementsByClassName(a);
            console.log("tab",dos)
            if (dos.length > 0) {
                dos[0].click();
            }
        }
    }, [location]);
    return (
        <div className='services'>
            <ServiceHome />
            <Banking/>
            <Payments/>
            <Financial/>
            <Goverment />
            <Legal />
            <Travel />
        </div>
    )
}

export default Services