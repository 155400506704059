export const AboutPageData = {

    ABOUTHEADING: {
        aboutheading1: {
            HINDI: "CSP.in, सीएसपी इंडिया नेटवर्क प्राइवेट लिमिटेड की एक पहल है, जो सेवा वंचित आबादी व छेत्रों तक, स्थानीय कस्टमर सर्विस पॉइंट के द्वारा,ग्राहक हेतु लाभकारी सेवाएं व जानकारी पहुँचाने की संकल्पना से प्रेरित है। ",
            ENGLISH: "CSP is an initiative by CSP India Network Private Limited. It draws its inspiration from ‘Customer Service Point’. ‘Customer Service’ is at the focal point in all our endeavors and it is our relentless pursuit to have customer centric products, offerings and services on our platform.",
            MARATHI: "CSP हा CSP इंडिया नेटवर्क प्रायव्हेट लिमिटेडचा एक उपक्रम आहे. आम्ही 'कस्टमर सर्व्हिस पॉइंट' मधून प्रेरणा घेतो. आमच्या सर्व प्रयत्नांमध्ये 'ग्राहक सेवा' हा केंद्रबिंदू आहे आणि आमच्या प्लॅटफॉर्मवर ग्राहक केंद्रित उत्पादने, ऑफर आणि सेवा मिळवण्याचा आमचा अथक प्रयत्न आहे."
        },
        aboutheading2: {
            HINDI: "हम भारत के कोने-कोने में मौजूद रहने की आकांक्षा रखते हैं। इस दिशा में काम करते हुए हम ऐसे व्यक्तियों से साझेदारी की पेशकश कर रहे हैं जो हमारे ग्राहक प्रतिनिधि के रूप में काम करने में रुचि रखते हैं। हमारी सेवाएँ और डिजिटल प्लेटफ़ॉर्म हमारे ग्राहक प्रतिनिधियों को अपने स्वयं के ग्राहक सेवा केंद्र स्थापित करने में मदद करते हैं और इस प्रकार उन्हें डिजिटल और आर्थिक रूप से सशक्त बनाते हैं। ग्राहक ग्राहक सेवा केंद्रों के माध्यम से और हमारे ग्राहक प्रतिनिधियों की सहायता से विभिन्न सेवाओं और सूचनाओं का लाभ उठा सकते ",
            ENGLISH: "We aspire to create a network of CSPs or ‘Customer Service Points’ across the length and breadth of Bharat and take digital and financial empowerment to the roots by providing opportunities of income for Individuals partnering with us as our ‘Grahak Pratinidhi’ and by bringing convenience in terms of access, agility and assistance to our customers",
            MARATHI: "आमचे 'ग्राहक प्रतिनिधी' म्हणून आमच्यासोबत भागीदारी करणाऱ्या व्यक्तींना उत्पन्नाच्या संधी उपलब्ध करून देऊन आणि सोयीसुविधा आणून संपूर्ण भारतभर CSP किंवा 'ग्राहक सेवा पॉइंट्स'चे जाळे तयार करण्याची आणि डिजिटल आणि आर्थिक सक्षमीकरणाची आमची इच्छा आहे. आमच्या ग्राहकांना प्रवेश, चपळता आणि सहाय्य मिळावे या दृष्टीने."
        },
        aboutheading3: {
            HINDI: "ग्राहक केंद्रितता हमेशा हमारे प्रयासों के मूल में रहेगी। हम अपने प्लेटफार्म पर ग्राहक केंद्रित उत्पाद, ऑफरिंग्स और सेवाएं प्रदान करने के  लिए सतत प्रयत्नशील हैं। हम 'डिजिटल भारत' के निर्माण की दिशा में वंचित भौगोलिक क्षेत्रों में वित्तीय समावेशन और विकास के अवसरों को प्रसारित करने के लिए प्रतिबद्ध हैं।",
            ENGLISH: "We are committed in percolating financial inclusion and opportunities of growth in underserved geographies towards building a ‘Digital Bharat.’",
            MARATHI: "'डिजिटल भारत' बनवण्याच्या दिशेने आर्थिक समावेशन आणि कमी सुविधा नसलेल्या भौगोलिक क्षेत्रांमध्ये वाढीच्या संधींचा लाभ घेण्यासाठी आम्ही वचनबद्ध आहोत."
        },
        aboutheading4: {
            HINDI: "हम पुणे में स्थित एक उभरता हुआ डिजिटल सेवा प्रदाता हैं। हमें डीपीआईआईटी, स्टार्टअप इंडिया द्वारा 'स्टार्टअप' के रूप में मान्यता दी गई है। 'विश्वास', 'पारदर्शिता' और 'एकजुटता' हमारे मूल मूल्य हैं और हम CSP को इन मूल्यों से प्रेरित एक ब्रांड बनाने के लिए तत्पर हैं।",
            ENGLISH: "We are a digital Service provider, located in Pune. We have been recognized as a ‘Start up’ by DPIIT, Startup India With the three T’s of ‘Trust’, ‘Transparency’ and ‘Togetherness’ as our core values, we see CSP maturing as a brand emanating these values!’",
            MARATHI: "'आम्ही पुण्यात स्थित एक विकसित होणारे सर्व्हिस एग्रीगेटर आहोत. डीपीआयआयटी, स्टार्टअप इंडिया द्वारे आम्हाला 'स्टार्ट अप' म्हणून ओळखले गेले आहे, 'विश्वास', 'परदर्शकता' आणि 'एकता' आमचे मूळ मूल्य आहेत. आणि आम्ही CSP ला या मूल्यांशी प्रेरित एक ब्रॅण्ड बनवण्यासाठी तत्पर आहे."
        },
        vissonandmission: {
            HINDI: "विजन और मिशन",
            ENGLISH: "Vision & Mission",
            MARATHI: "व्हिजन आणि मिशन"
        },
        corevlaues: {
            HINDI: "बुनियादी मूल्य",
            ENGLISH: "Core Values",
            MARATHI: "मूळ मूल्ये"
        },
        visson: {
            HINDI: "विज़न",
            ENGLISH: "Vision",
            MARATHI: "विज़न"
        },
        vissoncontent: {
            HINDI: "सरल, सुरक्षित और स्मार्ट ऑफरिंग्स की रचना करना जिनके के द्वारा आम जन जीवन को प्रभावित किया जा सके",
            ENGLISH: "Impact Lives by creating Simple, Secure and Smart Offerings",
            MARATHI: "सामान्य लोकांच्या जीवनावर प्रभावित करू शकतील अशा साध्या, सुरक्षित आणि स्मार्ट ऑफेरिंग्स  तयार करू"
        },
        missioncontent: {
            HINDI: "कस्टमर सर्विस पॉइंट्स का एक नेटवर्क बनाना और उनके माध्यम से हितधारकों के लिए कमाई, विकास और वित्तीय कल्याण के अवसर लाना",
            ENGLISH: "Create a network of Customer Service Points and through them bring opportunities of Earning, Growth and financial well-being to stakeholders",
            MARATHI: "कस्टमर सर्विस पॉईंट्स नेटवर्क तयार करणे आणि त्यांच्याद्वारे भागधारकांना कमाई, वाढ आणि आर्थिक कल्याणाच्या संधी आणणे"
        },
        mission: {
            HINDI: "मिशन",
            ENGLISH: "Mission",
            MARATHI: "मिशन"
        },
        trust: {
            HINDI: "विश्वास",
            ENGLISH: "TRUST",
            MARATHI: "विश्वास"
        },
        trustcontent: {
            HINDI: "हमारे संगठन की संरचना में 'विश्वास' समाहित है",
            ENGLISH: "We have ‘Trust’ embedded in the fabric of our Organization",
            MARATHI: "आम्ही आमच्या संस्थेच्या फॅब्रिकमध्ये 'ट्रस्ट' एम्बेड केला आहे"
        } ,
        Transparency: {
            HINDI: "पारदर्शिता",
            ENGLISH: "TRANSPARENCY",
            MARATHI: "पारदर्शकता"
        },
        Transparencyontent: {
            HINDI: "हम अपनी सभी संगठनात्मक प्रक्रियाओं में पारदर्शिता सुनिश्चित करते हैं।",
            ENGLISH: "We ensure Transparency in all our Organizational processes.",
            MARATHI: "आम्ही आमच्या सर्व संस्थात्मक प्रक्रियांमध्ये पारदर्शकता सुनिश्चित करतो."
        } ,
        together: {
            HINDI: "एकजुटता",
            ENGLISH: "TOGETHERNESS",
            MARATHI: "एकत्रीकरण "
        },
        togethercontent: {
            HINDI: "हर समय अपने हितधारकों के साथ एकजुटता ही हमारे  तालमेल और विकास का घटक है।",
            ENGLISH: "‘Standing Together’ with our Stakeholders at all times is our ingredient for Synergy and Growth.",
            MARATHI: "आमच्या स्टेकहोल्डर्ससोबत नेहमी 'स्टँडिंग टुगेदर' हे सिनर्जी आणि ग्रोथसाठी आमचे घटक आहे."
        },
        contact: {
            HINDI: "साझेदारी और एसोसिएशन संबंधी पूछताछ के लिए, कृपया हमसे यहां संपर्क करें",
            ENGLISH: "For Partnership and Association enquiries, Please contact us at",
            MARATHI: "भागीदारी आणि असोसिएशन चौकशीसाठी, कृपया आमच्याशी येथे संपर्क साधा"
        },
        contactmail: {
            HINDI: "या हमें support@csp.in पर लिखें",
            ENGLISH: "or write to us at support@csp.in",
            MARATHI: "किंवा support@csp.in वर आम्हाला लिहा"
        },
    },
   
}