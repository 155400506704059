import { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import axios from "axios";
import "./Auth.css";
import Checkimg from "./check.png";
import Alertimg from "./warning.png";

function Auth() {
  const [isValid, setIsValid] = useState(true);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [Loader, setLoader] = useState(true);
  const checkNull=()=>{
    try{
      let d = window.location.search;
      d = d.replace(/&amp;/g, "&");
      let c = new URLSearchParams(d);
      let status = c.get('status');
      let token = c.get('token');
      let is_new = c.get('is_new');
      let mobile = c.get('mobile');
      let name = c.get('name');
      const regexExp = /^[6-9]\d{9}$/gi;
      if(status==="null" || typeof status ==="undefined"|| (status !== "1" && status !== "0")){
        throw new Error('Invalid status!')
      }else if(token==="null" || typeof token ==="undefined" || token.length<30){
        throw new Error('Invalid token!')
      }else if(is_new==="null" || typeof is_new ==="undefined"|| (is_new !== "1" && is_new !== "0")){
        throw new Error('Invalid Is New!')
      }else if(mobile==="null" || typeof mobile==="undefined" || !regexExp.test(mobile)){
        throw new Error('Invalid Mobile!')
      }else if(name==="null" || typeof name==="undefined" || name.length <= 0){
        throw new Error('Invalid Name !')
      }
      setName(name);
      setIsValid(true);
      return true;
    }catch(exception){
      console.warn(exception);
      setError("Please Try Again");
    }
    setIsValid(false);
    return false
  }
  const callAuth=()=>{
    try{
      let d = window.location.search;
      d = d.replace(/&amp;/g, "&");
      let c = new URLSearchParams(d);
      let token = c.get('token');
      let mobile = c.get('mobile');
      let is_new=c.get('is_new');
      let data = {
        token: token,
        mobile: mobile,
      };
      let url = window.location.origin;
      axios.post(url + "/api/v1/setAuthtUserProfile", data).then((res) => {
              if (res.data.data.status) {
                localStorage.setItem("token", res.data.data.token);
                if(is_new==="0"){
                  window.location.replace(window.location.origin+"/csp/app");
                }else{
                  setLoader(false);
                }
              } else {
                setError(res.data.data.message);
              }
      }).catch((err) => {
        setLoader(false);
        setError("Please Try After Some Time");
      });
    }catch(exception){
      setError("Please Try After Some Time")
    }
  }
  useEffect(() => {
    if(checkNull()){
      callAuth();
    }
  }, []);
  return (
    <>
      <div>
        {error.length > 0 ? (
          <>
            <div>
              <div>
                <div className="container2 d-flex align-items-center justify-content-center ">
                  <div className="abc-card px-5 py-4">
                    <div className="d-flex">
                      <img className="Alertimg" src={Alertimg} alt="verified" />

                      <p>
                        {error === "Failed to register" ? (
                          <>Failed at this moment,Please Try After Sometime!</>
                        ) : (
                          <>{error}</>
                        )}
                      </p>
                    </div>

                    <Link
                      className="btn rounded-pill px-4 dash-btn text-white"
                      to="/Login"
                    >
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {isValid ? (
                <div>
                  <div className="container d-flex align-items-center justify-content-center ">
                    <div
                      className={
                        Loader
                          ? "abc-card2 px-5 py-4"
                          : "abc-card px-5 py-4"
                      }
                    >
                      <img
                        className="register-verified-icon"
                        src={Checkimg}
                        alt="verified"
                      />
                      <b class="py-1">Account Registered Successfully</b>
                      <p>Begin your Transformation journey now !</p>
                      <h5 class="pt-3 pb-1">Hii</h5>
                      <h4 class="pb-1">{name}</h4>
                      <p class="abc-para text-center">
                        we use basic information from your DigiLocker
                        <br />
                        Account to identify you and allow to CSP
                        <br />
                        Platform
                      </p>
                      <button class="btn rounded-pill px-4 dash-btn">
                        <a
                          href={window.location.origin + "/csp/app/"}
                          className="text-white"
                        >
                          {" "}
                          Go to Dashboard{" "}
                        </a>
                      </button>
                    </div>
                    {Loader ? (
                      <>
                        <div className="Spinner">
                          <div className="spinner-border " role="status"></div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default Auth;
