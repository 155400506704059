import React from 'react'
import './LatestBlog.css'
import CSPBlog from '../Assets/growing.jpg'
import { Link } from 'react-router-dom'

const LatestBlog = () => {
    return (
        <div className='latest-blog'>
            <div className='knowledge-main-container p-60'>
                <div className='row'>
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12 d-flex justify-content-center p-0 m-0'>
                        <Link to='/GrowingEntrepreneurialAspirations'>
                            <div className='latest-blog-card py-3 px-4 bg-white '>
                                <div className='latest-img py-1 d-flex align-items-center justify-content-center'>
                                    <div className='o_h_class'>
                                        <img className='' src={CSPBlog} alt='csp-latest-blog' />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-lg-5 col-md-12 col-sm-12 col-12 '>
                        <div className='blog-desc'>

                            {/* Small Devices */}
                            <h3 className='small-devices-blog-heading'><Link to='/GrowingEntrepreneurialAspirations'>
                                75th   Year Of Independence and Growing Entrepreneurial
                                Aspirations
                            </Link></h3>

                            {/* Large Devices */}
                            <h3 className='large-devices-blog-heading'><Link to='/GrowingEntrepreneurialAspirations'>
                                75th   Year Of Independence and Growing Entrepreneurial
                                Aspirations
                            </Link></h3>
                            <p>
                                As we celebrate our 75th year of Independence, we bow with reverence for
                                the countless sacrifices and contributions by our fellow countrymen in
                                shaping our country to its present position. We, Indians are truly blessed
                                with resilience and a testimony to this is our fight back from the impact
                                that the pandemic had on our lives both morally as well as economically.
                            </p>
                            <Link to='/GrowingEntrepreneurialAspirations'>
                                <button className='btn d-flex align-items-center py-2 px-3 mt-5'>Read More |
                                    <span className='blog-span'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="currentColor" class="bi bi-arrow-right transition-ease-in-out" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg>
                                    </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestBlog