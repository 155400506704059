import CspInvestmentHome from "./CspInvestmentHome"
import CspInvestmentServices from "./CspInvestmentServices/CspInvestmentServices."


const CspInvestment = () => {
    return (
        <div className="about">
            <CspInvestmentHome />
            <CspInvestmentServices />
        </div>
    )
}
export default CspInvestment