import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import hiddencharge from './img/airandhotel/at-no-hidden-charges.png'
import zerocharge from './img/airandhotel/at-zero-booking-fee.png'
import lowprice from './img/airandhotel/at-lowest-price.png'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { TravelServicePageData } from '../../../Data/Services/TravelServiceData';


const CspHotelBook = () => {




    const ServicesAeps = [
        {
            title: 'Hotel Booking',
            paragraph: 'Booking hotels online has streamlined the accommodation process, offering travelers a seamless and convenient way to find the perfect stay. CSP brings to you online hotel booking facility for your customers , now help your customers to provide a wide array of options, ranging from budget-friendly guesthouses to luxurious resorts, catering to every travelers preferences and budget. Narrow down on choices based on location, price, amenities, and reviews and facilitate customer convenience'
        },
    ];
    const hoteldetails = [
        {

            paragraph: 'Hotel websites not only provide details concerning the hotel but also a summary of the information about the region and its attractions.'
        },
        {

            paragraph: 'You will be able to explore pictures of the rooms, dining, swimming pool and other features of the hotel.'
        },
        {

            paragraph: 'Online reservation offers a faster booking process.'
        },
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.hotelheading)}
                </h1>
                <ul className="bottom-list">
                    {ServicesAeps.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    <p style={{ color: "#000", marginTop: 10 }}>{GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.hotelpara1)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='loginlink'>
                    <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.fordeatils)}</p></a>
                </div>

            </div>
        </div>
    );
};

export default CspHotelBook;
