import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FaqPageData } from '../../../Data/FaqData';


const DigitalGoldFaq = () => {


    const ServicesAeps = [
        {
            title: 'Train Booking',
            paragraph: 'Help your customers bypass queues for Ticket booking by bringing them the convenience of booking. We bring this to you in partnership with our IRCTC approved partner.'
        },
    ];

    const bottomListItems = [
        {
            title: 'What is the eligibility criteria for Digital Gold (Safe Gold)?',
            paragraph: 'Any resident Indian can enroll for Digital Gold (Safe Gold)..'
        },
        {
            title: 'What is the Purity of Gold offered under Digital Gold (Safe Gold)?',
            paragraph: 'Digital Gold (Safe Gold) offers 24 Karat Gold of 995 purity (99.5% pure).'
        },
        {
            title: 'What are the various tenures available under Digital Gold (Safe Gold)?',
            paragraph: 'Customer is not obligated to make any fixed or periodic payments unless enrolled in a Gold Savings Plan.'
        },
        {
            title: 'What is the minimum &amp; maximum amount for this plan?',
            paragraph: 'The minimum amount of purchase is Re. 1. There is no limit on the maximum amount as long as KYC requirements are complied with.'
        },
        {
            title: 'How can I get my holding statement?',
            paragraph: 'Please contact CSP'
        },
        {
            title: 'Can I have a joint account for this plan?',
            paragraph: 'No, you cannot have a joint account for Digital Gold (Safe Gold).'
        },
        {
            title: 'Is nomination mandatory for Digital Gold (Safe Gold)?',
            paragraph: 'Nomination norms will be followed as per the distributor’s standards for their product.'
        },
        {
            title: 'At what price is gold sold/ bought from customers?',
            paragraph: 'The Company will publish a live buy-sell quote for customers which will be derived from the wholesale bullion market plus a commission to cover operating costs. The actual purchase price paid by the customer will always be transparently published so the customer has a chance to compare with all other sources before entering into a transaction.'
        },
        {
            title: 'Is the gold associated with my accumulations kept in safe custody?',
            paragraph: 'Yes, the gold associated with the accumulations credited in your account is kept in safe custody with a Custodian in accordance with the terms of the offer mentioned in the product brochure. Only reputed firms such as Sequel Logistics and Brinks will be used as a custodian.'
        },
        {
            title: 'Who will insure the physical vault?',
            paragraph: 'Digital Gold (Safe Gold) has partnered with the global market leader in secure logistics and vault services-Brink’s, to store your gold. As part of their duty, the secure storage provider takes out a comprehensive insurance policy, which includes the cover on your gold. Over and above that, Digital Gold (Safe Gold) has insurance cover for the period where gold is in transit, when the gold leaves the vault. We review our risk & amp; insurance policies regularly, to ensure that we are covered for additional or specific risks.'
        },
        {
            title: 'Who will manage consumer complaints?',
            paragraph: 'CSP will provide the first level of customer support. The Company will be available to satisfy any issues/ questions that may be escalated. You may write to us at care@Digital Gold (Safe Gold).in or call our Customer Service team at the numbers listed on the platform.'
        },
        {
            title: 'Is there any regulator or Grievance redressal mechanism?',
            paragraph: 'Digital Gold (Safe Gold) is neither a Collective Investment Scheme nor a Deposit. Therefore we do not fall under the purview of SEBI or RBI. However, to ensure that your the customer interest is always prioritised, a Security Trustee has been appointed for Digital Gold (Safe Gold). For any grievances the customer can approach the Security Trustee.'
        },
        {
            title: 'What is the role of the “Security Trustee”?',
            paragraph: 'The Security Trustee is there to protect your interests, by acting on your behalf when it comes to matters concerning your gold. This includes periodic checks of the vault to ensure that there is always more than adequate inventory of gold and providing confirmations each time the gold leaves the vault, including for deliveries.'
        },
        {
            title: 'What will happen to the gold associated with accumulations in my account, in the unlikely event of the Company going into liquidation?',
            paragraph: 'The gold associated with accumulations in your account is separate from the other assets of the Company. An independent trustee appointed by the Company will act for and on behalf of the customers.The gold purchased by the Company associated with the accumulation in customer accounts is transferred to the Custodian appointed by the Company for the product. When the customer asks for  delivery, the requisite quantity of gold will be removed from the Custodian and delivered to the customer through a reputed courier service. Since the Security Trustee has a charge over the gold over other commercial creditors till the time of fulfillment and the gold is separate from the other assets of the Company, any unlikely adverse event happening to Company should not affect the gold associated with the accumulation in your account. Further, the title of the gold clearly rests with the customer and the physical gold stored with the Custodian on account of Digital Gold (Safe Gold) customers is not an asset of the Company in any way.'
        },
        {
            title: 'Which are the agencies which certify Gold in India?',
            paragraph: 'The Government of India has identified Bureau of Indian Standard as the sole agency in India to operate the BIS Hallmarking Scheme. The BIS Hallmarking Scheme has been aligned with International criteria on hallmarking (Vienna Convention 1972). A Hallmark, consists of five components i.e. BIS Mark, the Fineness number (corresponding to given carratage), Assaying and Hallmarking Centre&#39;s Mark, Jeweller&#39;s identification Mark and year of Marking denoted by a code letter and decided by BIS (e.g. code letter `A&#39; ) was approved by BIS for year 2000, `B&#39; being used for the year 2001 and `C&#39; for 2002 and &#39;J&#39; for 2008). The marking is done either using punches or laser marking machine. Some manufacturers also issue Assay Certification which can be obtained through Assay Offices. Assay offices are institutions set up to assay (test the purity of) precious metals, in order to protect consumers. All coins and jewellery offered SaeGold are BIS hallmarked.'
        },
        {
            title: 'Is there any lock-in period for this plan?',
            paragraph: 'You can buy and sell gold without any lock in- i.e. if you buy gold today, you can sell anytime after 24 hours has elapsed. You can decide the period and frequency you’d like to opt for when it comes to Gold savings plans, but we offer the flexibility of letting you pause the plan if it isn’t possible for you to make a payment on the due date.'
        },
        {
            title: 'What is the benchmark for the Price of Digital Gold (Safe Gold)? Can MCX be taken as a benchmark?',
            paragraph: 'There is no benchmark for comparing the price of Digital Gold (Safe Gold). MCX cannot be considered as a benchmark, since there are many differences between Digital Gold (Safe Gold) and MCX. MCX gives the prices of a futures contract which doesn’t give the landed prices by factoring in cost of logistics, insurance, safekeeping, etc. MCX also does not include pan India delivery, and based on the contract it gives delivery at only a few locations like Mumbai, Ahmedabad, Delhi, etc. Digital Gold (Safe Gold) gives delivery across the country, and includes the cost of storage, insurance, trustee fees and gives you the landed, completeprice of Gold. Also the gold price quoted in newspapers and on various websites is the wholesale price in multiples of 1kg of gold. Digital Gold (Safe Gold) price is valid for fractional grams and therefore is not directly comparable.'
        },
        {
            title: 'Who is Digital Gold India Private Limited?',
            paragraph: 'Digital Gold India Private Limited (Company) is an institutionally backed company, primarily owned by Invent Advisors Private Limited (Invent). The World Gold Council (India) Private Limited, as well as various other investor funds, hold minority stakes in the Company. Digital Gold (Safe Gold) is the retail brand of the Company.'
        },
        {
            title: 'Will the customer need to show any identity proof at the time of taking the physical gold coins?',
            paragraph: 'No, there is no identity proof required to be shown at the time of delivery.'
        },
        {
            title: 'Can someone else redeem the physical gold coins on my behalf?',
            paragraph: 'Only in case of death of the applicant, the legal heir will be able to collect the gold coins by showing the proof of identity of along with the original / notarized copy of the death certificate and proof that the person is the legal heir of the applicant.'
        },
        {
            title: 'What if the customer dies during the tenure of the plan?',
            paragraph: 'The legal heir of the customer can claim the customer’s account by following the process detailed above. Thereafter, they can access the customer’s account, including selling the gold balance or requesting for delivery of the gold.'
        },
        {
            title: 'How can one buy Gold on Digital Gold (Safe Gold)?',
            paragraph: 'Customers can buy any amount of gold as per his needs. The customer has complete freedom to seek physical delivery of gold or bars anytime and on as many occasions as (s)he wants. Since it is not physically possible to deliver a fractional quantity of gold, minimum quantity for which the delivery is proposed to be made has been kept as low as 0.5 gram. The gold on behalf of the customer would be purchased including GST as and when his/her clear funds are available. Gold in grams up to 4 decimal places shall be credited to the customer&#39;s account on receipt of funds.'
        },
        {
            title: 'Where can I find the invoice for each order?',
            paragraph: 'An invoice is sent to you by email/ SMS after each transaction. It is also available in the My Order’s section on the customer dashboard.'
        },
        {
            title: 'Where is the gold stored when you buy gold?',
            paragraph: 'The gold purchased on behalf of the customer shall be stored with a reputed custodian on a consolidated basis i.e. daily purchase across all the customers. An independent auditor/ trustee will confirm the balances with the custodian each quarter and reconcile the amounts with customer balances.'
        },
        {
            title: 'How do I sell my gold?',
            paragraph: 'Digital Gold (Safe Gold) provides a sell price quote on its website. Customers can choose to sell any amount starting with a minimum of Re. 1 to a maximum of the amount of gold that they own. Proceeds will be sent within 2 days to the customer&#39;s bank account after deducting any applicable transaction charges (which will be disclosed in advance). The sell quote may be temporarily unavailable in the unlikely event of a disruption in the bullion market.'
        },
        {
            title: 'Where do I get the proceeds when I sell my gold?',
            paragraph: 'Customers can add their account details where they would be receiving the proceeds post the sale of their gold.'
        },
        {
            title: 'Is it possible to store my withdrawn gold coins in safe custody and have them delivered later?',
            paragraph: 'Once you place a delivery request, you have to accept the delivery made to you. It cannot be made at a later date.'
        },
        {
            title: 'Can I change my delivery address?',
            paragraph: 'Yes, you can opt to have your gold delivered at an address other than that mentioned at the time of registration. However, this may be subject to verification of address change, as per the norms established by the distributor.'
        },
        {
            title: 'Where is delivery of Gold available?',
            paragraph: 'Digital Gold (Safe Gold) delivers across India, through partnerships with secure logistics providers. We maintain a list of serviceable pin codes, and prior to making any purchase you will be informed whether you pin code is serviceable for delivery. Depending on the Distributor platform however, you can still choose to have your gold delivered to an address that is different from the one entered at the time of registration. This may be subject to verification by our Partners.'
        },
        {
            title: 'Are there any charges for delivery of gold?',
            paragraph: 'Yes, you are required to pay a minting charge at the time of requesting for delivery. We deliver your goldto you in the form of BIS hallmarked coins, in tamper evident packaging, using secure logistics providers. The charge covers the cost of minting, packaging, insurance, assay certification and delivery to you.'
        },
        {
            title: 'How do I send gold or gift gold?',
            paragraph: 'You can gift gold by clicking on the Gift option on the dashboard after logging in. You will need to enterthe recipient’s mobile number and amount of gold you wish to gift, along with any message or stickeryou’d like to add for the occasion. If you haven’t purchased gold yet, you’ll need to buy some gold beforeyou choose to make your first gift.'
        },
        {
            title: 'How is the rate calculated for gifting gold?',
            paragraph: 'Since you are gifting based on the gold balance you hold, you will be gifting in grams, and hence there is no “rate” applicable.'
        },
        {
            title: 'How does the recipient get the gold?',
            paragraph: 'The recipient of the gold will be sent a sms with a link to redeem the gold during a stipulated time period. The recipient can login with his Digital Gold (Safe Gold) account and claim the gold within that time period. You can also send a link to them through Whatsapp or any other messaging platform, which will lead them to their gift.'
        },
        {
            title: 'What happens if the recipient doesn’t claim their gold?',
            paragraph: 'If the recipient does not claim their gold within the time period,then we will send them a reminder. If, even after this reminder, their gold is not redeemed, the gifted gold will be returned to you, and you will be notified..'
        },
        {
            title: 'Can I send gold to a recipient who does not have a Digital Gold (Safe Gold) account?',
            paragraph: 'Yes, you can still send them gold. In order to claim their gift, they will be sent a link to set up their Digital Gold (Safe Gold) account. Once they’ve completed the setup process, their gift will reflect in their gold balance..'
        },
        {
            title: 'Is there any way to track the gold sent and received?',
            paragraph: 'You can track the gold sent or received from My Gift link on the Gift tab on the dashboard. You can also send reminders to the recipients to receive their gold through this link and check the expiry date within which their gold gift will remain active..'
        },
        {
            title: 'What is a Gold Savings Plan?', paragraph: 'A Gold Savings Plan is a systematic savings tool that allows customers to accumulate gold by choosing a fixed instalment amount on a weekly/monthly basis for a selected duration. Customers can accumulate gold and later use it to exchange gold for jewellery on Digital Gold (Safe Gold)..'
        },
        {
            title: 'Can you choose multiple Gold Savings Plans?',
            paragraph: 'Yes, you can choose more than 1 Gold Savings Plan by adding individual SIP details for each..'
        },

        {
            title: 'Can you cancel a Gold Savings Plan?',
            paragraph: 'Yes, you can cancel a Gold Savings Plan, post which you will not be charged for the next instalment. If you want to temporarily pause the Plan, you can also choose to do that..'
        },

        {
            title: 'Can you make changes to an existing Gold Savings Plan?',
            paragraph: 'Yes, you can make changes to your Gold Savings Plan in two ways – either increase the instalment amount or increase the duration of the plan. However, you cannot decrease the instalment amount, or change the frequency of the instalment..'
        },

        {
            title: 'Is there a minimum amount for the instalment one can make for a Gold Savings Plan?',
            paragraph: 'You can choose a minimum amount of Rs 100 or higher for the Gold Savings Plan..'
        },






    ];


    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(FaqPageData?.FAQHEADING?.faqheading)}
                </h1>
                <ul className="bottom-list">
                    {FaqPageData.FAQHEADING.bbpsque.map((sec, i) => (
                        <li >
                            <div className='d-flex  gap-2'>
                                <div className='mt-3'>
                                    <img src={dot} style={{ width: 17 }}></img>
                                </div>
                                <div className='py-2'>
                                    <p><strong>{GetTranlatedValue(sec.title)}</strong></p>
                                    <p>{GetTranlatedValue(sec.subtitle)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>


            </div>
        </div>
    );
};

export default DigitalGoldFaq;
