import React from 'react'
import PaymentHome from './PaymentHome/PaymentHome'
import WhatisPayment from './WhatisPayment/WhatisPayment'
import PaymentReq from './PaymentReq/PaymentReq'
import PaymentSegment from './PaymentSegment/PaymentSegment'

const NewPayment = () => {
    return (
        <div className='about'>
            <PaymentHome />
            <WhatisPayment />
            <PaymentReq/>
            <PaymentSegment/>
        </div>
    )
}

export default NewPayment