import aeps from '../../Components/Landing/FifthSection/Images/Ticket.svg'
import bbps from '../../Components/Landing/FifthSection/Images/BBPS.svg'
import digitalgold from '../../Components/Landing/FifthSection/Images/GigitalGold.svg'
import digitallending from '../../Components/Landing/FifthSection/Images/Lending.svg'
import onlineticket from '../../Components/Landing/FifthSection/Images/Ticket.svg'
import ShieldCard from '../../Components/Landing/WhyCSP/dashboard.png'
import SimpleCard from '../../Components/Landing/WhyCSP/shield-card.png'
import SmartCard from '../../Components/Landing/WhyCSP/smart-bulb.png'

export const HomePageData = {
    FIRSTSECTION: {
        mainheading: {
            HINDI: "एक प्लेटफ़ॉर्म, एकाधिक सेवाएँ",
            ENGLISH: "Single Platform Multiple Services",
            MARATHI: "एक प्लॅटफॉर्म एकाधिक सेवा"
        },
        mainheading1: {
            HINDI: "सीएसपी आपके लिए एक ही प्लेटफॉर्म पर कमाई के कई अवसर लाता है",
            ENGLISH: "CSP brings to you Multiple Earning Opportunities on a Single Platform",
            MARATHI: "CSP तुमच्यासाठी एकाच प्लॅटफॉर्मवर कमाईच्या अनेक संधी आणते"
        },
        getstarted: {
            HINDI: "शुरुवात कीजिये ",
            ENGLISH: "Get Started",
            MARATHI: "सुरु करूया"
        },
        scrolldown: {
            HINDI: "नीचे स्क्रॉल करें ",
            ENGLISH: "Scroll Down",
            MARATHI: "खाली सरकवा"
        },
        homelist: [
            {
                title: {
                    HINDI: "न्यूनतम निवेश",
                    ENGLISH: "Zero Subscription Fees",
                    MARATHI: "किमान गुंतवणूक"
                },
                subtitle: {
                    HINDI: "3 चरणों में पंजीकरण: त्वरितआईडी एक्टिवेशन, आसान इंटरफेस, सिंगल  साइन इन",
                    ENGLISH: "Registration in 3 steps: Instant Activation, Easy Interface, Single Sign in",
                    MARATHI: "3 चरणांमध्ये नोंदणी: झटपट सक्रियकरण, सुलभ इंटरफेस, सिंगल साइन इन"
                }
            },
            {
                title: {
                    HINDI: "ग्राहक केंद्रित उपयोगिता सेवाएँ",
                    ENGLISH: "Customer Centric Utility Services",
                    MARATHI: "ग्राहक केंद्रित उपयुक्तता सेवा "
                },
                subtitle: {
                    HINDI: "सुरक्षित डेटा विनिमय तंत्र, प्राधिकरण और प्रमाणीकरण प्रक्रिया",
                    ENGLISH: "Secure Data Exchange Mechanism, Authorization and Authentication Process",
                    MARATHI: "सुरक्षित डेटा एक्सचेंज यंत्रणा, अधिकृतता आणि प्रमाणीकरण प्रक्रिया"
                }
            },
            {
                title: {
                    HINDI: "आसान सेटअप और संचालन प्रक्रिया",
                    ENGLISH: "Easy Set up And Operation Process",
                    MARATHI: "सुलभ सेटअप आणि ऑपरेशन प्रक्रिया"
                },
                subtitle: {
                    HINDI: "सिंगल वॉलेट, इंस्टेंट सेटलमेंट, वॉलेट लोड और कमीशन, एनालिटिकल डैशबोर्ड",
                    ENGLISH: "Single wallet, Instant Settlement, Wallet load and Commission, Analytical Dashboard",
                    MARATHI: "सिंगल वॉलेट, इन्स्टंट सेटलमेंट, वॉलेट लोड आणि कमिशन, ॲनालिटिकल डॅशबोर्ड"
                }
            },
        ],
    },
    OFFERINGSECTION: {
        offerparagraph: {
            HINDI: "CSP आपके के लिए लाया है कुछ ऐसे ऑफरिंग्स जो हमारे दैनिक उपयोगिता का हिस्सा हैं। मनी ट्रांसफर, कॅश डिपाजिट, कॅश विथड्रावल, बिल भुगतान, रेल और बस टिकटिंग, डिजिटल गोल्ड, पर्सनल और बिजनेस लोन जैसी सेवाओं की उपलब्धता, आपको ग्राहक वर्ग की एक विस्तृत श्रृंखला की सेवा करने, नए ग्राहकों का अधिग्रहण करने, ग्राहक भागीदारी बढ़ाने, प्रतिधारण और क्रॉस सेल के अवसरों को बढ़ाने में सक्षम बनाता है । सबसे अहम्, CSP  आपके पास ले कर आता है एक अवसर,अपना कस्टमर सर्विस पॉइंट स्थापित करने का।  तो आइए ,जुड़िये हमसे और शुरुवात करें, वृद्धि की, अपने कस्टमर्स में और अपने आय में !",
            ENGLISH: "CSP brings to you various offerings which form a part of daily utility. Powered by services like Money Transfer, Cash Withdrawal and Deposit, Bill Payments, Rail and Bus ticketing, Digital Gold, Personal and Business Loan etc. CSP empowers you to serve a wide range of customer segment, acquire new customers, enhance customer engagement, retention and cross sell avenues. Above all, it helps you start your own Customer Service Point. So, Why Wait? Source more, Serve more, Earn more!",
            MARATHI: "CSP तुमच्यासाठी विविध ऑफर आणते जे दैनंदिन उपयुक्ततेचा एक भाग बनतात. मनी ट्रान्सफर, कॅश विथड्रॉवल आणि कॅश डिपॉझिट, बिल पेमेंट्स, रेल्वे आणि बस तिकीट, डिजिटल गोल्ड, वयक्तिक आणि बिझनेस लोन इ. यांसारख्या सेवांद्वारे समर्थित. CSP तुम्हाला ग्राहक विभागाच्या विस्तृत श्रेणीत सेवा देण्यासाठी, नवीन ग्राहक मिळवण्यासाठी, ग्राहकांची प्रतिबद्धता वाढवण्यासाठी, टिकवून ठेवण्यासाठी सक्षम करते. आणि क्रॉस विक्रीचे मार्ग. सर्वात महत्त्वाचे म्हणजे, हे तुम्हाला तुमचा स्वतःचा ग्राहक सेवा पॉइंट सुरू करण्यात मदत करते. तर, प्रतीक्षा का? अधिक स्रोत, अधिक सेवा, अधिक कमवा!"
        }
    },
    TENTHSECTION: {
        doyouwantheading: {
            HINDI: "क्या आप चाहते हैं?",
            ENGLISH: "Do You Want To?",
            MARATHI: "आपण हे करू इच्छिता का?"
        },
        doyouwant: [
            {
                HINDI: "अपने समय  के अनुसार काम करना",
                ENGLISH: "Work Flexitime",
                MARATHI: "तुमच्या स्वतःच्या अटींवर काम करा"
            },
            {
                HINDI: "अपनी पसंद के स्थान से काम करना",
                ENGLISH: "Work from Location of your Choice",
                MARATHI: "तुमच्या आवडीच्या स्थानावरून काम करा"
            },
            {
                HINDI: "अपने आस पड़ोस को अपना बिजनेस हब बनाना",
                ENGLISH: "Make your Locality your Business Hub",
                MARATHI: "तुमचा परिसर तुमचे बिझनेस हब बनवा"
            },
            {
                HINDI: "सतत आय प्राप्त करना ",
                ENGLISH: "Get a Sustained Income",
                MARATHI: "शाश्वत उत्पन्न मिळवा"
            },
            {
                HINDI: "कम निवेश पर अपने व्यवसाय की स्थापना करना",
                ENGLISH: "Set up your Own Business at No or Low Investment",
                MARATHI: "कमीत कमी गुंतवणुकीत तुमचा स्वतःचा व्यवसाय सुरू करा"
            }
        ],
        ifyes: {
            HINDI: "अगर आपका जवाब ‘हां’ है तो हम आपके के लिए लाये हैं, आत्मनिर्भर बनने का एक प्रभावी विकल्प, करिये हमारे साथ भागीदारी और शुरुवात करिये अपने CSP का ।",
            ENGLISH: "If your answer is ‘YES’ then we have a Proposition to help you Be ‘Your Own Boss’! Partner with us and be a part of this Brilliant Opportunity.",
            MARATHI: "जर तुमचे उत्तर 'होय' असेल तर आमच्याकडे तुम्हाला 'तुमचा स्वतःचा बॉस' बनण्यास मदत करण्याचा प्रस्ताव आहे! आमच्यासोबत भागीदारी करा आणि या चमकदार संधीचा एक भाग व्हा."
        },
        becomeour: {
            HINDI: "बनिए हमारे",
            ENGLISH: "Become our ",
            MARATHI: "आमचे ग्रहक"
        },
        grahakpratinidhi: {
            HINDI: "ग्राहक प्रतिनिधि!",
            ENGLISH: "Grahak Pratinidhi",
            MARATHI: "प्रतिनिधी व्हा."
        },
        joinnow: {
            HINDI: "हमसे जुड़ें",
            ENGLISH: "Join Now",
            MARATHI: "जॉईन करा "
        },

        boxlist: [
            {
                HINDI: "ग्राहक खंड की एक विस्तृत श्रृंखला के लिए ऑफरिंग",
                ENGLISH: "Offerings to Suit a Wide Range of Customer Segment",
                MARATHI: "ग्राहक विभागाच्या विस्तृत श्रेणीसाठी ऑफर"
            },
            {
                HINDI: "ग्राहकों की दैनिक उपयोगिता जरूरतों को पूरा करने वाली ऑफरिंग",
                ENGLISH: "Offerings that Serve the Daily Utility Needs of Customers",
                MARATHI: "ग्राहकांच्या दैनंदिन उपयुक्तता गरजा पूर्ण करणाऱ्या ऑफर"
            },
            {
                HINDI: "त्वरित लेनदेन सुनिश्चित करने के लिए नवीनतम टेक्नोलॉजी द्वारा निर्मित",
                ENGLISH: "Built on Latest Technology to Ensure Speedy Transactions",
                MARATHI: "जलद व्यवहार सुनिश्चित करण्यासाठी नवीनतम तंत्रज्ञानावर तयार केलेले"
            },
            {
                HINDI: "न्यूनतम निवेश और परिचालन लागत ",
                ENGLISH: "Minimal Investment and Operational Cost",
                MARATHI: "किमान गुंतवणूक आणि ऑपरेशनल खर्च"
            },
            {
                HINDI: "अपने व्यवसाय को स्थापित करने में मदद करने के लिए हैंडहोल्डिंग सपोर्ट",
                ENGLISH: "Learning and Handholding Support to Help set Your Business",
                MARATHI: "तुमचा व्यवसाय सेट करण्यात मदत करण्यासाठी शिक्षण आणि हँडहोल्डिंग समर्थन"
            },
            {
                HINDI: "प्रतिस्पर्धी कमीशन और शीघ्र भुगतान",
                ENGLISH: "Competitive and Prompt Payout",
                MARATHI: "स्पर्धात्मक आणि त्वरित पेआउट"
            }
        ],
    },


    FORTHSECTION: {
        forthseactionheading: {
            HINDI: "पायें एक व्यवसायिक बढ़त,जब साथ हो ",
            ENGLISH: "Get a business edge with",
            MARATHI: " व्यवसायात प्रगती करा जेव्हा तुमच्यासोबत असेल "
        },

        forthseactiontext: {
            HINDI: "ऐसी सुविधाएँ जो व्यवसाय करने में आसानी और इसे बढ़ाने में करें  मदद !",
            ENGLISH: "Features that help Ease of Doing Business and Scaling it up",
            MARATHI: "आपण हे करू इच्छिता का?"
        },
        benefits: [
            {
                img: "https://csp.in/static/media/access.c5cff2c331042e14063b.png",
                title: {
                    HINDI: " इंस्टेंट एक्सेस",
                    ENGLISH: "Instant Access",
                    MARATHI: "त्वरीत प्रवेश"
                },
                subtitle: {
                    HINDI: "आसान ऑनबोर्डिंग",
                    ENGLISH: "Easy Onboarding",
                    MARATHI: "सोपे ऑनबोर्डिंग"
                }
            },
            {
                img: "https://csp.in/static/media/accountant.ac8d7fd14c667492be7c.png",
                title: {
                    HINDI: "इंस्टेंट ओप्पोर्तुनिटी",
                    ENGLISH: "Instant Opportunity",
                    MARATHI: "त्वरीत संधी"
                },
                subtitle: {
                    HINDI: "आपका पड़ोस आपका बिज़नेस हब",
                    ENGLISH: "Serve your Own Locality",
                    MARATHI: "आपल्या स्वतःच्या परिसराची सेवा करा"
                }
            },
            {
                img: "https://csp.in/static/media/support.50b450811c4070e1db95.png",
                title: {
                    HINDI: "इंस्टेंट सपोर्ट",
                    ENGLISH: "Instant Support",
                    MARATHI: "त्वरीत आधार"
                },
                subtitle: {
                    HINDI: "डैशबोर्ड, चैट, कनेक्ट",
                    ENGLISH: "Dashboard, Chat, Connect",
                    MARATHI: "डॅशबोर्ड, चॅट, कनेक्ट करा"
                }
            },
            {
                img: "https://csp.in/static/media/rupee.787c4e9d982be9867472.png",
                title: {
                    HINDI: "इंस्टेंट इनकम",
                    ENGLISH: "Instant Income",
                    MARATHI: "त्वरीत उत्पन्न"
                },
                subtitle: {
                    HINDI: "रियल टाइम कमीशन*",
                    ENGLISH: "Real time Commission*",
                    MARATHI: "रिअल टाइम कमिशन*"
                }
            },
        ],
    },

    ROESECTION: {
        roeheading: {
            HINDI: "अपने प्रयासों पर प्रतिफल (आरओई) को अधिकतम करें: उच्च विकास क्षमता वाले व्यावसायिक क्षेत्रों में अपने प्रयासों का निवेश करें",
            ENGLISH: "Maximize the Return on Your Efforts (ROE) : Invest yourself in Avenues with High Growth Potential",
            MARATHI: "तुमच्या प्रयत्नांवर जास्तीत जास्त परतावा मिळवा (ROE): उच्च वाढीच्या संभाव्यतेसह स्वतःची गुंतवणूक करा."
        },
        roetext: {
            HINDI: "भारत दुनिया की तीसरी सबसे बड़ी अर्थव्यवस्था बनने की ओर अग्रसर है। ग्रामीण और अर्ध शहरी भारत की कुल आबादी का 65 प्रतिशत और राष्ट्रीय जीडीपी का 47 प्रतिशत हिस्सा है।आपके के लिए ले आया है कुछ ऐसे ऑफरिंग्स जो इन भौगोलिक छेत्र के कस्टमर्स की जरूरतों को पूरा करने में कारगर साबित होंगे।",
            ENGLISH: "As India aspires to become the 3rd largest economy in the world. Rural and Semi Urban India constituting of 65% of the total population and 47% of the National GDP is all set to play a pivotal role. CSP brings to you offerings from avenues which are poised for a significant growth in this geography.",
            MARATHI: "भारत जगातील तिसरी सर्वात मोठी अर्थव्यवस्था बनण्याची आकांक्षा आहे. एकूण लोकसंख्येच्या 65% आणि राष्ट्रीय GDP च्या 47% असलेला ग्रामीण आणि अर्धशहरी भारत एक निर्णायक भूमिका बजावण्यासाठी सज्ज आहे. या भूगोलातील लक्षणीय वाढीसाठी तयार असलेल्या मार्गांवरून CSP तुमच्यासाठी ऑफर आणते."
        },

        roegrowth: [
            {
                img: aeps,
                title: {
                    HINDI: "AePS and DMT",
                    ENGLISH: "AePS and DMT",
                    MARATHI: "AePS and DMT"
                },
                subtitle: {
                    HINDI: "अंतिम छोर (लास्ट मील ) तक बैंकिंग सुविधा उपलब्ध कराने की योजना में 80% से अधिक ग्रामीण परिवारों तक पहुंचने की क्षमता है",
                    ENGLISH: "Last mile Banking avenue, has a potential to reach to over 80% of rural households",
                    MARATHI: "लास्ट माईल बँकिंग मार्ग, 80% पेक्षा जास्त ग्रामीण कुटुंबांपर्यंत पोहोचण्याची क्षमता आहे"
                }
            },
            {
                img: bbps,
                title: {
                    HINDI: "BBPS",
                    ENGLISH: "BBPS",
                    MARATHI: "BBPS"
                },
                subtitle: {
                    HINDI: "2027 तक कुल लेनदेन बढ़कर 5.4 बिलियन रुपये तक पहुंचने का अनुमान है",
                    ENGLISH: "Overall Transactions projected to grow to INR 5.4 Billion by 2027",
                    MARATHI: "2027 पर्यंत एकूण व्यवहार 5.4 अब्ज रुपयांपर्यंत वाढण्याचा अंदाज आह"
                }
            },
            {
                img: digitalgold,
                title: {
                    HINDI: "Digital Gold",
                    ENGLISH: "Digital Gold",
                    MARATHI: "Digital Gold"
                },
                subtitle: {
                    HINDI: "2025 तक 10 हजार करोड़ रुपये का बाजार अवसर बनने का अनुमान",
                    ENGLISH: "Projected to become INR 10k Crore market opportunity by 2025",
                    MARATHI: "2025 पर्यंत INR 10k कोटी मार्केट संधी बनण्याचा अंदाज आहे"
                }
            },
            {
                img: digitallending,
                title: {
                    HINDI: "Digital Lending",
                    ENGLISH: "Digital Lending",
                    MARATHI: "Digital Lending"
                },
                subtitle: {
                    HINDI: "2026 तक 47.4 लाख करोड़ रुपये का वितरण अनुमानित",
                    ENGLISH: "Disbursement projected at INR 47.4 Lakh crore by 2026",
                    MARATHI: "2026 पर्यंत INR 47.4 लाख कोटी वितरणाचा अंदाज आहे"
                }
            },
            {
                img: onlineticket,
                title: {
                    HINDI: "Online Ticketing",
                    ENGLISH: "Online Ticketing",
                    MARATHI: "Online Ticketing"
                },
                subtitle: {
                    HINDI: "80% रेल टिकट ऑनलाइन बुक होते हैं, गैर-मेट्रो क्षेत्रों में 67% ऑनलाइन बस टिकट बुकिंग होती है",
                    ENGLISH: "80% of Rail tickets are booked online, Non metro regions contribute to 67% online Bus ticket booking",
                    MARATHI: "80% रेल्वे तिकिटे ऑनलाइन बुक केली जातात, बिगर मेट्रो प्रदेश 67% ऑनलाइन बस तिकीट बुकिंगमध्ये योगदान देतात"
                }
            },
        ],
        offering: {
            HINDI: "ऑफरिंग्स जो सुनिश्चित करें आपकी व्यावसायिक वृद्धि !",
            ENGLISH: "Offerings that Ensure your Business Growth",
            MARATHI: "तुमच्या व्यवसायाची वाढ सुनिश्चित करणारी ऑफेरिंग्स !"
        },
    },

    SEVENTHSECTION: {
        seventhheading: {
            HINDI: "अपने आप को इस शानदार विकास यात्रा का हिस्सा बनाये।",
            ENGLISH: "Make Yourself a Part of an Amazing Growth Journey",
            MARATHI: "स्वतःला एका आश्चर्यकारक वाढीच्या प्रवासाचा एक भाग बनवा"
        },
        seventhtext: {
            HINDI: "सीएसपी प्‍लेटफॉर्म की विशेषता यह है कि इसे सिंपल,सिक्योर और स्मार्ट बनाया गया है।इसका  इंटरफ़ेस बेहद ही  सरल है और यह आपके ट्रांसक्शन्स को सिक्योर रखने में तकनिकी रूप से सक्षम है. आपको एक निर्बाध्य अनुभव प्रदान करने के लिए इसको नवीनतम टेक्नोलॉजी द्वारा निर्मित किया गया है।  ",
            ENGLISH: "Uniqueness of the CSP Platform is that it has been designed to be ‘Simple’, ‘Secure’ and ‘Smart’. It has a Simple Interface and is built on Latest Technology to provide a Speedy, Seamless experience while keeping your Transactions Secure",
            MARATHI: "CSP प्लॅटफॉर्मचे वैशिष्ट्य म्हणजे ते 'साधे', 'सुरक्षित' आणि 'स्मार्ट' असे डिझाइन केले गेले आहे. यात एक साधा इंटरफेस आहे आणि तुमचे व्यवहार सुरक्षित ठेवताना एक वेगवान, अखंड अनुभव देण्यासाठी नवीनतम तंत्रज्ञानावर तयार केले आहे."
        }
    },

    NINTHSECTION: {
        simplesecuresmart: [
            {
                img: ShieldCard,
                title: {
                    HINDI: "Simple",
                    ENGLISH: "Simple",
                    MARATHI: "Simple"
                },
                subtitle: {
                    HINDI: "3 चरणों में पंजीकरण: त्वरितआईडी एक्टिवेशन, आसान इंटरफेस, सिंगल  साइन इन",
                    ENGLISH: "Registration in 3 steps: Instant Activation, Easy Interface, Single Sign in",
                    MARATHI: "3 चरणांमध्ये नोंदणी: झटपट सक्रियकरण, सुलभ इंटरफेस, सिंगल साइन इन"
                }
            },
            {
                img: SimpleCard,
                title: {
                    HINDI: "Secure",
                    ENGLISH: "Secure",
                    MARATHI: "Secure"
                },
                subtitle: {
                    HINDI: "सुरक्षित डेटा विनिमय तंत्र, प्राधिकरण और प्रमाणीकरण प्रक्रिया",
                    ENGLISH: "Secure Data Exchange Mechanism, Authorization and Authentication Process",
                    MARATHI: "सुरक्षित डेटा एक्सचेंज यंत्रणा, अधिकृतता आणि प्रमाणीकरण प्रक्रिया"
                }
            },
            {
                img: SmartCard,
                title: {
                    HINDI: "Smart",
                    ENGLISH: "Smart",
                    MARATHI: "Smart"
                },
                subtitle: {
                    HINDI: "सिंगल वॉलेट, इंस्टेंट सेटलमेंट, वॉलेट लोड और कमीशन, एनालिटिकल डैशबोर्ड",
                    ENGLISH: "Single wallet, Instant Settlement, Wallet load and Commission, Analytical Dashboard",
                    MARATHI: "सिंगल वॉलेट, इन्स्टंट सेटलमेंट, वॉलेट लोड आणि कमिशन, ॲनालिटिकल डॅशबोर्ड"
                }
            },
        ],
    }
}