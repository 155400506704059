export const egovernanceServicePageData = {
    EGOVERNANCESERVICES: {
        egovernanceheading: {
            HINDI: "ई-गवर्नेंस सेवाएँ",
            ENGLISH: "E-governance Services",
            MARATHI: "ई-गव्हर्नन्स सेवा"
        },
        egovernanceheading2: {
            HINDI: "स्कोप और ऑफरिंग",
            ENGLISH: "Scope And Offering",
            MARATHI: "व्याप्ती आणि ऑफरिंग"
        },
        egovernanceheadingpara: [
            {
                title: {
                    HINDI: "ग्राहक सुविधा को अपने मूल उद्देश्य के अनुरूप, हमारे प्लेटफॉर्म पर विभिन्न सरकारी योजनाओं और नागरिक केंद्रित सेवाओं से संबंधित जानकारी उपलब्ध है। यह जानकारी विभिन्न सरकारी वेबसाइटों पर उपलब्ध जानकारी से एकत्रित की गई है। हमारे ग्राहकों को और अधिक सुविधा प्रदान करने के लिए, उपलब्ध जानकारी को उनकी आवश्यकता के अनुसार विभिन्न मापदंडों के आधार पर क्रमबद्ध किया जा सकता है। हमारे ग्राहक प्रतिनिधि इस प्रक्रिया में हमारे ग्राहकों की सहायता करेंगे।",
                    ENGLISH: "In line with our objective of customer convenience as our core, We have on our platform information related to various government schemes and citizen centric services. This information has been pooled from the information available on various government websites . To further facilitate our customers ,the available information can further be sorted on basis of various parameters as per their requirement. Our Grahak Pratinidhi’s would be assisting our customers in this process.",
                    MARATHI: "ग्राहकांच्या सोयी हाच आमचा मूळ उद्देश आहे या अनुषंगाने, आमच्या प्लॅटफॉर्मवर विविध सरकारी योजना आणि नागरिक केंद्रित सेवांशी संबंधित माहिती आहे. विविध सरकारी संकेतस्थळांवर उपलब्ध माहितीवरून ही माहिती एकत्रित करण्यात आली आहे. आमच्या ग्राहकांना अधिक सुविधा देण्यासाठी, उपलब्ध माहितीची त्यांच्या गरजेनुसार विविध पॅरामीटर्सच्या आधारे क्रमवारी लावली जाऊ शकते. आमची ग्राहक प्रतिनिधी आमच्या ग्राहकांना या प्रक्रियेत मदत करेल."
                },
                title1: {

                    HINDI: " इस जानकारी की उपलब्धता और विभिन्न नागरिक केंद्रित सेवाओं के लिंक हमारे ग्राहक प्रतिनिधि के लिए ग्राहक जुड़ाव और सेवा के लिए एक बड़ी गुंजाइश पैदा करते हैं।",
                    ENGLISH: "Availability of this information and links to various citizen centric services creates a huge scope for customer engagement and service for our Grahak Pratinidhi.",
                    MARATHI: "या माहितीची उपलब्धता आणि विविध नागरिक केंद्रित सेवांच्या लिंक्समुळे आमच्या ग्रहकप्रतिनिधीसाठी ग्राहक संलग्नता आणि सेवेसाठी मोठा वाव निर्माण होतो."
                },
                title2: {

                    HINDI: "कृपया ध्यान दें कि हम विभिन्न सरकारी वेबसाइटों पर उपलब्ध जानकारी को एकत्रित कर रहे हैं तथा उसे ग्राहकों को बिना किसी परिवर्तन के उसी रूप में उपलब्ध करा रहे हैं, जैसा कि संबंधित वेबसाइटों पर उपलब्ध है तथा इसका एकमात्र उद्देश्य ग्राहक सेवा को सुविधाजनक बनाना है। यह एक गैर-प्रभार्य सेवा है.",
                    ENGLISH: "Please note that we are only pooling the information available at various government websites and making it available to customers strictly as they appear in the respective websites without any alteration whatsoever and with a sole purpose of facilitating the customer service. This is a non- chargeable service.",
                    MARATHI: "कृपया लक्षात घ्या की आम्ही फक्त विविध सरकारी वेबसाइट्सवर उपलब्ध असलेली माहिती एकत्र करत आहोत आणि ग्राहकांना ती उपलब्ध करून देत आहोत कारण ती संबंधित वेबसाइट्समध्ये कोणताही बदल न करता आणि ग्राहक सेवेची सुविधा करण्याच्या एकमेव उद्देशाने दिसत आहे. ही एक विनाशुल्क सेवा आहे."
                }
            }
        ],
    }
}