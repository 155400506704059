import React from 'react'
import InvestmentHome from './InvestmentHome/InvestmentHome'
import WhatisInvestment from './WhatisInvestment/WhatisInvestment'
import InvestmentReq from './InvestmentReq/InvestmentReq'
import InvestmentSegment from './InvestmentSegment/InvestmentSegment'

const NewInvestment = () => {
    return (
        <div className='about'>
            <InvestmentHome />
            <WhatisInvestment />
            <InvestmentReq />
            <InvestmentSegment />
        </div>
    )
}

export default NewInvestment