import React, { useEffect } from 'react'
import './Goverment.css'
import GovermentImg from '../Assets/government.jpg'
import Govermentsub from './Govermentsub'
import { useLocation } from 'react-router-dom';

const Goverment = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash !== null) {
            let a = location.hash.toString().substr(1, location.hash.length);
            let dos = document.getElementsByClassName(a);
            if (dos.length > 0) {
                dos[0].click();
            }
        }
    }, [location]);

    return (
        <>
         <a href='Services#goverment' className={"goverment"} style={{
                textIndent:'100%',
                whiteSpace:'nowrap',
                visibility:'hidden'
            }}>e-Governance</a>
            <div className='goverment-container' id='goverment'>
                <div className='goverment-main-container'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-cente align-items-center p-0'>
                            <div className='goverment-head'>
                                <h1>
                                e-Governance
                                </h1>
                                <h5>
                                e-Governance Service- Build strong bonds with customers by bringing to them various  online citizen centric services offered by Government.
                                </h5>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center services-side-img'>
                            <div className='text-center'>
                                <img className='w-100' src={GovermentImg} alt='goverment' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Govermentsub />
            </div>
        </>
    )
}

export default Goverment