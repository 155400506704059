import React from 'react'
import './SolutionsHome.css'
import SolutionImg from '../Assets/solution-home-new-img.png'
import Checked from './checked.png'

const SolutionsHome = () => {
    return (
        <div className='solution-home-container'>
            <div className='solution-home-main-container'>
                <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                        <div className=''>
                            <h2 className='text-white small-device-solution-home'>Transform Your  Future with Innovative <span> Solutions</span></h2>
                            <h2 className='text-white large-device-solution-home'>Transform Your  Future with <br />Innovative <span> Solutions</span></h2>
                            <div className='py-3 solution-list-check'>
                                <p className='d-flex'>
                                    <img className='h-50 mt-1' src={Checked} alt='checked' />The point of an innovative solution is to provide a new and creative approach to solving a problem or addressing a need that has not been effectively addressed by traditional methods or solutions.
                                </p>
                                <p className='d-flex'>
                                    <img className='h-50 mt-1' src={Checked} alt='checked' />Innovative solutions are often developed by leveraging new technologies, processes, or ideas, and they can help individuals and organizations achieve their goals more efficiently and effectively.
                                </p>
                                {/* <p className='d-flex align-items-center'>
                                    <img src={Checked} alt='checked' /> Verify & onboard customers with AI-powered VCIP & VIPV
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='d-flex justify-content-center'>
                            <img className='solution-home-img' src={SolutionImg} alt='Solution img' />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                Document Automation Software For Finance
            </div> */}
        </div>
    )
}

export default SolutionsHome 