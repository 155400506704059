import React from 'react';
import './BankingSegment.css';

const BankingSegment = () => {
    // Sample data for two segments
    const segments = [
        {
            image: 'https://signdesk.com/images/linkIt/Finance_Industry.png',
            title: 'Direct Money Transfer',
            text: ' Direct Money Transfer (DMT) is a unique product that can be used to send money instantly to any Bank’s account holder within India. Through this product any Indian citizen can walk in with cash-in-hand to any nearest Customer Service Point (CSP), fill in few details and have the money transferred to loved ones, anywhere in the country.'
        },
        {
            image: 'https://signdesk.com/images/linkIt/Entertainment_Sector.png',
            title: ' QR ATM',
            text: 'UPI-ATM is an Interoperable Cardless Cash Withdrawal (ICCW) service that facilitates participating banks customers who are live on UPI, to withdraw cash from any participating banks ATMs (enabled for UPI-ATM) without using their card.'
        }
    ];
    const segments2 = [
        {
            image: 'https://signdesk.com/images/linkIt/Insurance_Services.png',
            title: 'Lorem ipsum dolor',
            text: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            image: 'https://signdesk.com/images/linkIt/Ed-Tech_Firms.png',
            title: ' Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            image: 'https://signdesk.com/images/linkIt/Medical_Industry.png',
            title: 'Lorem ipsum dolor',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
    ];

    return (
        <div className="main-bankingsegment">
            <div className='container'>
                <h1><span>Lorem ipsum </span>dolor sit amet, consectetur</h1>
                <div className='bankingsegment-content'>
                    <div className='row'>
                        {segments.map((segment, index) => (
                            <div key={index} className='col-lg-6 col-md-6 col-sm-12 py-3'>
                                <div className='bankingsegment-1st' >
                                    <img src={segment.image} alt={`Segment ${index + 1}`} />
                                    <h2>{segment.title}</h2>
                                    <p>{segment.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='bankingsegment-content'>
                <div className='row'>
                    {segments2.map((segments2, index) => (
                        <div key={index} className='col-lg-4 col-md-4 col-sm-12 py-3'>
                            <div className='bankingsegment-1st' >
                                <img src={segments2.image} alt={`Segment ${index + 1}`} />
                                <h2>{segments2.title}</h2>
                                <p>{segments2.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </div >
    );
};

export default BankingSegment;
