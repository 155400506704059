import React, { useState, useEffect } from 'react';
import '../../Banking/CspBankingServices/CspBankingServices.css';
import { GetTranlatedValue } from '../../../Data/LanguageContext.js';
import { TravelServicePageData } from '../../../Data/Services/TravelServiceData.jsx';

const services = [
  { "title": "Train Ticket Booking", "content": require('./CspTrainBook.js').default },
  { "title": "Bus Ticket Booking", "content": require('./CspBusbook.js').default },
  { "title": "Flight Ticket Booking", "content": require('./CspAitbook.js').default },
  { "title": "hotel Booking", "content": require('./CspHotelBooking.js').default },
];
const data2 = [
  {
    title: '67% of online bus ticket bookings were done from non-metro regions and Tier 2 & 3 cities across the country. This is as per the India track report by redbus. The figures are indicative of a paradigm shift in consumer behavior and their increasing reliance on digital platforms primarily because of the ease and convenience. Similarly, a recent communications by the railway ministry indicates that 80% of the train tickets are purchased online. Apart from catering to the necessary requirements of travelling, online train, bus and air ticketing coupled with online hotel booking presents a huge scope considering the impact of the exponentially growing domestic tourism across urban and rural geographies'
  },
  {
    title: 'Strategically, as a Grahak Pratinidhi of CSP, this is a key enabler as it ensures regulat footfall of customers to their counters and helps them provide to their customers with convience and choice in terms of pricing'
  },
];
const CspTravelServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}>
            {GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.travelheading2)}
          </h2>
          <div className='aeps-all-content'>
            {TravelServicePageData.TRAVELSERVICES.travelservicepara.map((item, i) => (
              <div key={i}>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title1)}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CspTravelServices;
