import React, { useEffect } from 'react'
import './Travel.css'
import TravelImg from '../Assets/travels.jpg'
import Travelsub from './Travelsub'
import { useLocation } from 'react-router-dom';


const Travel = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash !== null) {
            let a = location.hash.toString().substr(1, location.hash.length);
            let dos = document.getElementsByClassName(a);
            if (dos.length > 0) {
                dos[0].click();
            }
        }
    }, [location]);
    return (
        <>
            <div className='travel-container' id='ticketingservices'>
                <div className='travel-main-container'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-cente align-items-center'>
                            <div className='pay-head'>
                                <h1>
                                    Ticketing
                                </h1>
                                <h5>
                                Ticketing Services- Help your customers in their travel by helping them With Digital Ticketing Service.

                                </h5>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center services-side-img'>
                            <div className='text-center'>
                                <img className='w-100' src={TravelImg} alt='travel' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Travelsub />
            </div>
            <a href='Services#travel' className={"travel"} style={{
                textIndent:'100%',
                whiteSpace:'nowrap',
                visibility:'hidden'
            }}>Travels</a>
        </>
    )
}

export default Travel