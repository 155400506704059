import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import rating from './img/bustravel/bb-bus-ratings.png'
import livetrack from './img/bustravel/bb-live-tracking.png'
import reststop from './img/bustravel/bb-rest-stops.png'
import travelexp from './img/bustravel/bb-travel-experience.png'
import forwomen from './img/bustravel/bb-seat-reservation.png'
import lowprice from './img/bustravel/bb-lowest-price.png'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { TravelServicePageData } from '../../../Data/Services/TravelServiceData';


const CspBusBook = () => {
    const data = [
        {
            imageUrl: rating,
            title: 'Book Bus Based on Ratings'
        },
        {
            imageUrl: livetrack,
            title: 'Share Live Tracking with Family'
        },
        {
            imageUrl: reststop,
            title: 'Know your Rest Stops'
        },
        {
            imageUrl: travelexp,
            title: 'Share Your Travel Experience'
        },
        {
            imageUrl: forwomen,
            title: 'Reserve Seats for Women'
        },
        {
            imageUrl: lowprice,
            title: 'Lowest Price'
        },
    ];





    const ServicesAeps = [
        {
            title: 'CSP brings to you the benifits of online Bus Tickiting throught redbus',
            paragraph: 'Booking bus tickets online in India has revolutionized the travel industry, offering unparalleled convenience and efficiency to millions of passengers nationwide. User-friendly platform ensure effortless browsing through a myriad of routes, schedules, and fares, selecting the most suitable options with just a few clicks. With secure payment gateways and instant confirmation, the hassle of standing in long queues or dealing with last-minute availability issues is a thing of the past. Moreover, online booking often provides additional benefits such as discounts, cashback offers, and seamless rescheduling options, enhancing the overall experience for passengers. Whether planning a short intercity journey or a long-distance adventure, the convenience of online bus ticket booking has become an indispensable aspect of modern travel in India.'
        },
        {
            title: 'CSP brings to you the benifits of online Bus Tickiting throught redbus',
            paragraph: 'CSP brings to you convenience of Online Bus booking through redbus which is which is India’s largest brand for online bus ticket booking with 3500+ bus operators to choose from.'
        },
    ];
    const features = [
        {
            title: 'Check the availability of your bus tickets online',
        },
        {
            title: 'Wide choice of bus seats, bus types & operators.',
        },
        {
            title: 'Hassle-free bus booking from anywhere & anytime',
        },
        {
            title: 'Customer Care assistance',
        },
        {
            title: 'Attractive Commissions on Each Booking',
        },
        {
            title: 'Additional Insurance Service for each Bus Booking',
        },

    ];
    const benefits = [
        {
            title: 'Online bus ticket booking keeps you away from the long queues of the offline ticket counters.',
        },
        {
            title: 'Online bookings in real time.',
        },
        {
            title: 'Popular Pilgrimage & Tourism Destinations',
        },
        {
            title: 'Easier & Faster Payments',
        },
        {
            title: 'Booking available 24*7',
        },
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.bustravelheading)}
                </h1>
                <ul className="bottom-list">
                    <li>
                        <div className='d-flex gap-2 pb-2'>
                            {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                            <div>
                                <p style={{ color: "#000" }}>{GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.bustravelpara1)}</p>
                                <p style={{ color: "#000", marginTop: 10 }}>{GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.bustravelpara2)}</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className='loginlink'>
                    <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(TravelServicePageData?.TRAVELSERVICES?.fordeatils)}</p></a>
                </div>

            </div>
        </div>
    );
};

export default CspBusBook;
