import FaqHome from "./FaqHome"
import FaqQue from "./FaqQue/FaqQue"

const Faq = () =>{
    return(
        <div className="about">
          <FaqHome/>
          <FaqQue/>
        </div>
    )
}
export default Faq