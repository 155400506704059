import React from 'react';
import './InnerServices.css';
import dot from './dot-circle-svgrepo-com.svg'

import fundtransfter from '../../img/moneytransfer/dmt-money-transfer.png'
import neardoor from '../../img/moneytransfer/dmt-doorstep.png'
import costeffective from '../../img/moneytransfer/dmt-cost-effective.png'
import safetransfer from '../../img/moneytransfer/dmt-safe-secure.png'
import { GetTranlatedValue } from '../../../../Data/LanguageContext';
import { BankingServicePageData } from '../../../../Data/Services/BankingServiceData';

const MoneyTransfer = () => {
    const data = [
        {
            imageUrl: fundtransfter,
            title: 'Fund Transfer to all PSUs & Private Banks'
        },
        {
            imageUrl: neardoor,
            title: 'Near Doorstep Remittance Facility'
        },
        {
            imageUrl: costeffective,
            title: 'Cost Effective'
        },
        {
            imageUrl: safetransfer,
            title: 'Safe and Secure Transaction'
        },
        // {
        //     imageUrl: 'https://mobisafar.com/assets/images/dmt-free-sms-alerts.png',
        //     title: 'Free SMS Alerts on Every Transaction'
        // },
    ];

    const data2 = [
        {
            title: 'Direct Money Transfer (DMT) is a unique product  that can be used to send money instantly to any  Bank’s account holder within India. Through this  product any Indian citizen can walk in with cash-in- hand to any nearest Customer Service Point (CSP), fill in few details and have the money transferred to there known ones, anywhere in the country'
        },

    ];

    const bottomListItems = [
        'IIN (Identifying the Bank to which the customer is associated)',
        'Aadhaar Number',
        'Fingerprint captured during their enrollment'
    ];

    return (
        <div>
            <div className="aeps-content">
                <h2 style={{ textAlign: "left", marginBottom: 40 }}>Domestic Money Transfer (DMT)</h2>
                <div className='row align-items-center'>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-3 col-6">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>
                <div className='aeps-all-content'>

                    <div>
                        <h4 style={{ color: "#000" }}>{GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.moneytransfercontent)}</h4>
                    </div>
                </div>
                <h3 style={{ color: "#000", textAlign: "left", fontSize: 16 }}>{GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.moneytransferoffer)}</h3>

                <ul className="bottom-list">
                    {BankingServicePageData.BANKINGSERVICES.moneytransferofferlist.map((sec, i) => (
                        <li>
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <img src={dot} style={{ width: 17 }}></img>
                                </div>
                                <div>
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>

                <div className='loginlink'>
                    <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.fordeatils)}</p></a>
                </div>
            </div>
        </div>
    );
};

export default MoneyTransfer;
