import './Navbar.css';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import Logo from './Assets/Csp_Logo.png';
import BankingIcon from './Assets/bankingicon.svg';
import Payment from './Assets/payment1.svg';
import Finance from './Assets/financial1.svg';
import Investment from './Assets/investment1.svg';
import Travel from './Assets/travel1.svg';
import Egov from './Assets/E-govIcon.svg';
import blog from './Assets/blog.svg';
import faq from './Assets/faq.svg';
import { useState } from 'react';
import { GetTranlatedValue, LANGUAGES } from '../Data/LanguageContext';
import { NavbarPageData } from '../Data/NavbarData';
const Navbar = () => {
    const [selectedlang, setSelectedLang] = useState(localStorage.getItem("language"));
    const route = useNavigate();
    const ToTop = () => {
        window.scrollTo(0, 0);
    }
    const serviceDropdown = [
        { title: 'Banking', subtitle: 'Service your Locality', icon: BankingIcon, uri: '/banking' },
        { title: 'Payment', subtitle: 'Utility and Bill Payments', icon: Payment, uri: '/payment' },
        { title: 'Financial', subtitle: 'Multiple Loan Options', icon: Finance, uri: '/financial' },
        { title: 'Investment', subtitle: 'Prudent Saving Options', icon: Investment, uri: '/investment' },
        { title: 'Travel', subtitle: 'Hassle free Travel', icon: Travel, uri: '/travel' },
        { title: ' E-governance', subtitle: 'Hassle free Travel', icon: Travel, uri: '/egovernance' },
    ];
    const ResourcesDropdown = [
        { title: 'Blog', subtitle: 'Publish articles online.', icon: blog, uri: '/Resources' },
        { title: 'FAQ', subtitle: 'Clear Your Douts', icon: faq, uri: '/faq' },
    ];
    const selectLanguage = (lang) => {
        localStorage.setItem("language", lang);
        setSelectedLang(lang);
        route(0);
    }
    return (
        <div className='navbar-container' id="TOPONE">
            <nav className="navbar navbar-expand-lg shadow-sm fixed-top">
                <div className="container">
                    <Link className="navbar-brand align-items-center" to="/" onClick={() => ToTop()}>
                        <img src={Logo} alt="csp" className='logo-img' style={{ height: '28px', width: '75px' }} />
                        <div className='d-flex brand-span-div'>
                            <span className='brand-span'>Simple</span>
                            <span className='brand-span'>Secure</span>
                            <span className='brand-span-last'>Smart</span>
                        </div>
                    </Link>
                    <button className="navbar-toggler show" type="button" data-bs-toggle="collapse" data-bs-target={"#navBar"} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse"} id={"navBar"}>
                        <ul className="navbar-nav ms-auto" id='navLinks'>
                            <li className="nav-item home-nav-item active">
                                <Link className="nav-link" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item service-nav-item dropdown">
                                <Link className="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.services)}
                                </Link>
                                <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown" style={{ width: 300 }}>
                                    {serviceDropdown.map((drop) => (
                                        <li className='py-1' key={drop.title}>
                                            <Link className="dropdown-item white-space-unset d-flex align-items-center" to={drop.uri}>
                                                <div className="d-flex align-items-start me-3">
                                                    <div>
                                                        <img className="" src={drop.icon} alt={drop.title} />
                                                    </div>
                                                </div>
                                                <span className="align-middle">
                                                    <h5 className="fw-500 mb-1" style={{ fontSize: 17, color: '#212529' }}>{drop.title}</h5>
                                                    <p className="mb-0 d-block text-wrap" style={{ fontSize: 12, color: '#212529', lineHeight: 1.3 }}>{drop.subtitle}</p>
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/About" onClick={() => ToTop()}>
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.aboutus)}
                                </Link>
                            </li>
                            <li className="nav-item service-nav-item dropdown">
                                <Link className="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.resources)}
                                </Link>
                                <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown" style={{ width: 300 }}>
                                    {ResourcesDropdown.map((Resourcesdrop) => (
                                        <li key={Resourcesdrop.title}>
                                            <Link className="dropdown-item white-space-unset d-flex align-items-center" to={Resourcesdrop.uri}>
                                                <div className="d-flex align-items-start me-3">
                                                    <div>
                                                        <img className="" src={Resourcesdrop.icon} alt={Resourcesdrop.title} />
                                                    </div>
                                                </div>
                                                <span className="align-middle">
                                                    <h5 className="fw-500 mb-1" style={{ fontSize: 17, color: '#212529' }}>{Resourcesdrop.title}</h5>
                                                    <p className="mb-0 d-block text-wrap" style={{ fontSize: 12, color: '#212529', lineHeight: 1.3 }}>{Resourcesdrop.subtitle}</p>
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ContactUs" onClick={() => ToTop()}>
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.contact)}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={`${window.location.origin}/version2/auth/login`}>
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.login)}
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.Language)}
                                </a>
                                <ul className="dropdown-menu p-0" aria-labelledby="languageDropdown">
                                    <li onClick={() => selectLanguage(LANGUAGES.ENGLISH)}><a className="dropdown-item" >English</a></li>
                                    <li onClick={() => selectLanguage(LANGUAGES.HINDI)}><a className="dropdown-item">हिन्दी</a></li>
                                    <li onClick={() => selectLanguage(LANGUAGES.MARATHI)}><a className="dropdown-item">मराठी</a></li>
                                </ul>
                            </li>
                        </ul>
                        <div className="d-flex mobile-view-btns">
                            <a href={`${window.location.origin}/version2/auth/registration`} className="btn log-btn rounded-0">
                                {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.joinnow)}
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
