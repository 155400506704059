import React, { useEffect, useState } from 'react';
import { CustomSvg } from '../../../svg';
import { GetTranlatedValue } from '../../Data/LanguageContext';
import { HomePageData } from '../../Data/HomePageData';

export const TenthSection = () => {
    const boxList = [
        "Offerings to suit a wide range of Customer Segment",
        "Offerings that serve the daily Utility needs of Customers",
        "Built on Latest Technology to ensure Speedy Transactions",
        "Minimal Investment and Operational cost",
        "Learning and Handholding support to help set your Business",
        "Competitive and Prompt Payout",
    ];

    const qList = [
        "Work on your Own Terms",
        "Work from Location of your Choice",
        "Make your Locality your Business Hub",
        "Get a Sustained Income",
        "Set up your Own Business at No or Low Investment"
    ];

    const sequence = [0, 1, 2, 5, 4, 3];
    const [bgColorIndex, setBgColorIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setBgColorIndex(prevIndex => (prevIndex + 1) % sequence.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [sequence.length]);

    const getLastWord = (str) => {
        const words = str.split(" ");
        return words[words.length - 1];
    };

    return (
        <div style={{ background: 'linear-gradient(to right, white 50%, #efefef 50%)', paddingTop: 40, paddingBottom: 100 }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-center tenth-section-content'>
                        <h2 className="fw-bold mb-3" style={{ color: '#16b287', fontSize: 26 }}>
                            {GetTranlatedValue(HomePageData?.TENTHSECTION?.doyouwantheading)}
                        </h2>
                        <ul className='p-0' style={{ listStyleType: 'none' }}>
                            {HomePageData.TENTHSECTION.doyouwant.map((li, i) => (
                                <li key={i} className='d-flex align-items-start mt-3' style={{ color: '#212529', gap: 20 }}>
                                    <div style={{ marginTop: 1 }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>
                                    </div>
                                    <p className='m-0' style={{ fontWeight: 500, lineHeight: 1.4, }}>{GetTranlatedValue(li)}</p>
                                </li>
                            ))}
                        </ul>
                        <p style={{ textAlign: 'justify' }}>
                            {GetTranlatedValue(HomePageData?.TENTHSECTION?.ifyes)}
                        </p>

                        <strong className=''><p className='dotouwant '> {GetTranlatedValue(HomePageData?.TENTHSECTION?.becomeour)} <span style={{ color: "#16B287" }}> {GetTranlatedValue(HomePageData?.TENTHSECTION?.grahakpratinidhi)}</span></p></strong>
                        <div className='join-now-home'>
                            <a href={`${window.location.origin}/version2/auth/registration`} className="mt-3 btn shadow-none text-white rounded-0 " style={{ width: 130, backgroundColor: '#16b287' }}>
                                {GetTranlatedValue(HomePageData?.TENTHSECTION?.joinnow)}
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12 d-flex justify-content-end'>
                        <div className='py-3'>
                            <div className='row'>
                            {HomePageData.TENTHSECTION.boxlist.map((li, i) => (
                                    <div key={i} className='col-lg-4 col-md-4 col-sm-4' style={{ padding: 12 }}>
                                        <div
                                            className='position-relative d-flex align-items-center justify-content-center h-100 w-100 p-4 rounded-3'
                                            style={{ backgroundColor: i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9', boxShadow: '0px 3px 6px #00000029', minHeight: 200, transition: 'all 1s linear' }}
                                        >
                                            <p className='m-0' style={{ color: i === sequence[bgColorIndex] ? '#fff' : '#2c5363' }}>{GetTranlatedValue(li)}</p>
                                            <div className='position-absolute rounded-circle d-flex align-items-center justify-content-center' style={{
                                                height: 40,
                                                width: 40,
                                                backgroundColor: i === sequence[bgColorIndex] ? '#f9f9f9' : '#2c5363',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                transition: 'all 1s linear',
                                                top: 180
                                            }}>
                                                {
                                                    i === 0 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                }
                                                {
                                                    i === 1 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                }
                                                {
                                                    i === 2 && <CustomSvg.DownArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                }
                                                {
                                                    i === 3 && <CustomSvg.Thumb color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                }
                                                {
                                                    i === 4 && <CustomSvg.LeftArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                }
                                                {
                                                    i === 5 && <CustomSvg.LeftArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#fff'} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
