export const FaqPageData = {
    FAQHEADING: {
        faqheading: {
            HINDI: "Frequently Asked Questions",
            ENGLISH: "Frequently Asked Questions",
            MARATHI: " Frequently Asked Questions"
        },

        aepsque: [
            {
                title: {
                    HINDI: "आधार क्या है?",
                    ENGLISH: "What is Aadhaar?",
                    MARATHI: " आधार म्हणजे काय?"
                },
                subtitle: {
                    HINDI: "आधार एक अद्वितीय संख्या है जो भारतीय विशिष्ट पहचान प्राधिकरण (यूआईडीएआई) द्वारा भारत के निवासियों को सौंपी जाती है।",
                    ENGLISH: "Aadhaar is an unique number that is assigned to residents of India by the Unique Identification Authority of India (UIDAI).",
                    MARATHI: "आधार हा भारतीय विशिष्ट ओळख प्राधिकरण (UIDAI) द्वारे भारतातील रहिवाशांना नियुक्त केलेला एक अद्वितीय क्रमांक आहे."
                }
            },
            {
                title: {
                    HINDI: "आधार इनेबल्ड पेमेंट सिस्टम्स क्या है?",
                    ENGLISH: "What is Aadhaar Enabled Payment System?",
                    MARATHI: " आधार सक्षम पेमेंट सिस्टम म्हणजे काय?"
                },
                subtitle: {
                    HINDI: "आधार इनेबल्ड पेमेंट सिस्टम्स एक भुगतान सेवा है जो एक बैंक ग्राहक को अपने संबंधित आधार सक्षम बैंक खाते तक पहुंचने और किसी भी बैंकिंग करेस्पोंडेंट्स अथवा CSP के ग्राहक प्रतिनिधी    के माध्यम से शेष राशि की जांच, नकद जमा, नकद निकासी, प्रेषण जैसे बुनियादी बैंकिंग लेनदेन करने के लिए अपनी पहचान के रूप में आधार का उपयोग करने का अधिकार देती है। काउंटर (NPCI के अनुसार)",
                    ENGLISH: "Aadhaar enabled Payment System is easy to use, safe and secure payment platform to avail benefits by using Aadhaar number & biometrics.Aadhaar enabled Payment System is based on the demographic and biometric/iris information of an individual, it eliminates the threat of any fraud and non-genuine activity.Aadhaar enabled Payment System facilitates inter-operability across banks in a safe and secured mannerReaching the unreached - The model enable banks to extend financial services to the unreached clients beyond their branch network as beneficiaries of the BCs aremostlylocated at unbanked and underbanked areas.",
                    MARATHI: "आधार सक्षम पेमेंट सिस्टम ही एक पेमेंट सेवा आहे जी बँक ग्राहकांना त्यांच्या संबंधित आधार सक्षम बँक खात्यात प्रवेश करण्यास सक्षम करते आणि कोणत्याही बँकिंग करस्पॉन्डंट किंवा CSP च्या ग्राहक वार्ताहराद्वारे बॅलन्स चौकशी, रोख ठेवी, पैसे काढणे, पैसे पाठवणे यासारख्या मूलभूत बँकिंग क्रिया करतात तुम्हाला व्यवहार करण्यासाठी तुमची ओळख म्हणून आधार वापरण्याचा अधिकार आहे. काउंटर (NPCI नुसार)"
                }
            },
            {
                title: {
                    HINDI: "किसी भी सीएसपी पर AePS के तहत कौन सी सेवाएं उपलब्ध हैं?",
                    ENGLISH: "What are the services available under AePS at any CSP?",
                    MARATHI: "कोणत्याही CSP वर AePS अंतर्गत कोणत्या सेवा उपलब्ध आहेत?"
                },
                subtitle: {
                    HINDI: "सीएसपी वर्तमान में AePS के माध्यम से निम्नलिखित सेवाएं प्रदान करता है, बैलेंस पूछताछ, आधार से आधार फंड ट्रांसफर, नकद निकासी, नकद जमा,AePS का उपयोग कौन कर सकता है?, वैध आधार कार्ड और बैंक खाता रखने वाले सभी भारतीय निवासी इस भुगतान सेवा का उपयोग कर सकते हैं।",
                    ENGLISH: "Balance Enquiry, Aadhaar to Aadhaar Fund Transfer,Cash Withdrawal ,Cash Deposit",
                    MARATHI: "CSP सध्या AePS द्वारे खालील सेवा प्रदान करते,शिल्लक चौकशी,आधार ते आधार निधी हस्तांतरण,रोख पैसे काढणे,रोख ठेव,AePS कोण वापरू शकतो?,वैध आधार कार्ड आणि बँक खाते असलेले सर्व भारतीय रहिवासी ही पेमेंट सेवा वापरू शकतात."
                }
            },
            {
                title: {
                    HINDI: "आधार क्या है?",
                    ENGLISH: "What are the Benefits of AePS services?",
                    MARATHI: " आधार म्हणजे काय?"
                },
                subtitle: {
                    HINDI: "आधार इनेबल्ड पेमेंट सिस्टम्स एक भुगतान सेवा है जो एक बैंक ग्राहक को अपने संबंधित आधार सक्षम बैंक खाते तक पहुंचने और किसी भी बैंकिंग करेस्पोंडेंट्स अथवा CSP के ग्राहक प्रतिनिधी    के माध्यम से शेष राशि की जांच, नकद जमा, नकद निकासी, प्रेषण जैसे बुनियादी बैंकिंग लेनदेन करने के लिए अपनी पहचान के रूप में आधार का उपयोग करने का अधिकार देती है। काउंटर (NPCI के अनुसार)",
                    ENGLISH: "Aadhaar enabled Payment System is easy to use, safe and secure payment platform to avail benefits by using Aadhaar number & biometrics.Aadhaar enabled Payment System is based on the demographic and biometric/iris information of an individual, it eliminates the threat of any fraud and non-genuine activity.Aadhaar enabled Payment System facilitates inter-operability across banks in a safe and secured mannerReaching the unreached - The model enable banks to extend financial services to the unreached clients beyond their branch network as beneficiaries of the BCs are mostly located at unbanked and underbanked areas.",
                    MARATHI: "आधार क्रमांक आणि बायोमेट्रिक्स वापरून लाभ मिळवण्यासाठी आधार सक्षम पेमेंट सिस्टम हे वापरण्यास सोपे, सुरक्षित पेमेंट प्लॅटफॉर्म आहे.आधार सक्षम पेमेंट सिस्टम व्यक्तीच्या लोकसंख्याशास्त्रीय आणि बायोमेट्रिक/IRIS माहितीवर आधारित आहेत, ज्यामुळे कोणत्याही फसवणूक आणि गैर-अस्सल क्रियाकलापांचा धोका दूर होतो.आधार सक्षम पेमेंट सिस्टम आंतर-बँक व्यवहार सुरक्षित पद्धतीने सुलभ करतातन पोहोचलेल्या लोकांपर्यंत पोहोचणे - हे मॉडेल बँकांना त्यांच्या शाखा नेटवर्कच्या पलीकडे आर्थिक सेवांचा विस्तार करण्यास सक्षम करते, कारण बँकिंग करस्पॉन्डंट्स किंवा सीएसपी कस्टमर कॉरस्पॉन्डंट्सचे लाभार्थी बहुतेक सोयीस्कर भागात वसलेले असतात."
                }
            },
            {
                title: {
                    HINDI: "AePS का उपयोग कौन कर सकता है?",
                    ENGLISH: "Who can use AEPS?",
                    MARATHI: "AePS कोण वापरू शकतो?"
                },
                subtitle: {
                    HINDI: "वैध आधार कार्ड और बैंक खाता रखने वाले सभी भारतीय निवासी इस भुगतान सेवा का उपयोग कर सकते हैं।",
                    ENGLISH: "All Indian residents holding a valid Aadhaar card and a bank account can use this payments service.",
                    MARATHI: "वैध आधार कार्ड आणि बँक खाते असलेले सर्व भारतीय रहिवासी ही पेमेंट सेवा वापरू शकतात."
                }
            },
            {
                title: {
                    HINDI: "कोई उपयोगकर्ता इस सेवा को कैसे सक्षम कर सकता है?",
                    ENGLISH: "How can a user enable this service?",
                    MARATHI: " वापरकर्ता ही सेवा कशी सक्षम करू शकतो?"
                },
                subtitle: {
                    HINDI: "• AEPS का उपयोग शुरू करने के लिए सबसे पहले अपने बैंक खाते को अपने आधार नंबर से लिंक करें। फिर, अपने बैंक से संपर्क करें और उन्हें बताएं कि आप AePS का उपयोग शुरू करना चाहते हैं।",
                    ENGLISH: "To start using AEPS, first link your bank account with your Aadhaar number. Then, contact your bank and let them know that you want to start using AEPS.",
                    MARATHI: "AEPS वापरणे सुरू करण्यासाठी, प्रथम तुमचे बँक खाते तुमच्या आधार क्रमांकाशी लिंक करा. त्यानंतर, तुमच्या बँकेशी संपर्क साधा आणि त्यांना सांगा की तुम्हाला AePS वापरणे सुरू करायचे आहे."
                }
            },
            {
                title: {
                    HINDI: "AePS का उपयोग करने के लिए क्या आवश्यकताएँ हैं?",
                    ENGLISH: "What are the requirements for using AePS?",
                    MARATHI: "AePS वापरण्यासाठी कोणत्या आवश्यकता आहेत?"
                },
                subtitle: {
                    HINDI: "AePS सुविधा का उपयोग करने के लिए निम्नलिखित आवश्यक है:, आधार कार्ड बैंक खाते से लिंक होना चाहिए, आधार नंबर , आधार कार्ड धारक का बायोमेट्रिक",
                    ENGLISH: "To use the AePS facility, the following is required: Aadhaar card should be linked with the bank account Aadhaar number Fingerprint biometric of the Aadhaar card holder",
                    MARATHI: "AePS वैशिष्ट्य वापरण्यासाठी खालील गोष्टी आवश्यक आहेत:, आधार कार्ड बँक खात्याशी जोडलेले असावे, आधार क्रमांक, आधार कार्ड धारकाचे फिंगरप्रिंट बायोमेट्रिक"
                }
            },
            {
                title: {
                    HINDI: "क्या भुगतानकर्ता/लाभार्थी के पास धन प्राप्त करने के लिए AePS भी होना चाहिए?",
                    ENGLISH: "Should the payee/beneficiary also have AEPS to receive funds?",
                    MARATHI: " पैसे मिळवण्यासाठी पैसे देणाऱ्या/लाभार्थीकडेही AePS असणे आवश्यक आहे का?"
                },
                subtitle: {
                    HINDI: "यह आवश्यक नहीं है कि धन प्राप्त करने के लिए भुगतानकर्ता या लाभार्थी के पास AePS हो क्या आपके आधार कार्ड से एक से अधिक बैंक खाते को लिंक करना संभव है? ऐसा ही संभव है",
                    ENGLISH: "It is not necessary that the payee or beneficiary has AePS to receive funds",
                    MARATHI: "निधी प्राप्त करण्यासाठी देयक किंवा लाभार्थीकडे AePS असणे आवश्यक नाही तुमच्या आधार कार्डशी एकापेक्षा जास्त बँक खाती लिंक करणे शक्य आहे का? हे शक्य आहे"
                }
            },
            {
                title: {
                    HINDI: "क्या बिना आधार कार्ड वाले लोग इस सेवा का उपयोग कर सकते हैं?",
                    ENGLISH: "Can those without Aadhaar cards use this service?",
                    MARATHI: " आधार कार्ड नसलेले लोक ही सेवा वापरू शकतात? "
                },
                subtitle: {
                    HINDI: "इस सेवा का लाभ उठाने के लिए आधार कार्ड एक अनिवार्य आवश्यकता है ",
                    ENGLISH: "To avail this service Aadhar card is a mandatory requirement",
                    MARATHI: "या सेवेचा लाभ घेण्यासाठी आधार कार्ड अनिवार्य आहे ."
                }
            },
            {
                title: {
                    HINDI: "क्या AEPS के लिए बैंक खाता आधार से लिंक होना चाहिए?",
                    ENGLISH: "Should the bank account be linked to Aadhar for AEPS?",
                    MARATHI: "AEPS साठी बँक खाते आधारशी लिंक करावे का?"
                },
                subtitle: {
                    HINDI: "• बैंक खाते को आपके आधार से लिंक करना होगा।",
                    ENGLISH: "The bank account needs to be linked with your Aadhaar.",
                    MARATHI: "बँक खाते तुमच्या आधारशी लिंक करणे आवश्यक आहे."
                }
            },
            {
                title: {
                    HINDI: "यदि कोई ग्राहक गलत आधार नंबर दर्ज करता है या ऐसा बैंक चुनता है जहां उसका खाता नहीं है तो क्या होगा?",
                    ENGLISH: "What happens if a customer enters an incorrect Aadhaar Number or chooses a Bank where he doesn’t have an account?",
                    MARATHI: "जर एखाद्या ग्राहकाने चुकीचा आधार क्रमांक टाकला किंवा त्याचे खाते नसलेली बँक निवडली तर?"
                },
                subtitle: {
                    HINDI: "यदि ग्राहक गलत आधार नंबर दर्ज करता है/गलत बैंक का चयन करता है जहां उसके पास आधार से जुड़ा बैंक खाता नहीं है, तो उचित प्रतिक्रिया संदेश के साथ लेनदेन अस्वीकार कर दिया जाएगा। इसके अलावा, चूंकि एक ग्राहक अपने आधार को कई बैंकों से लिंक कर सकता है, इसलिए ग्राहक को सही बैंक का चयन करना चाहिए, जहां से वह लेनदेन करना चाहता है। यदि ग्राहक के चयनित बैंक में एक से अधिक खाते हैं तो केवल प्राथमिक खाते से ही डेबिट किया जाएगा और ग्राहक लेनदेन के समय बैंक खाते का चयन नहीं कर सकता है।",
                    ENGLISH: "If the customer enters incorrect Aadhaar number/ selects an incorrect bank where he/ she does not have an Aadhaar linked bank account, the transaction will decline with an appropriate response message. Further, as a customer may link his/ her Aadhaar with multiple banks, customer should select the correct bank, from where he wishes to perform transaction. In case, customer has more than one account with the selected bank then only the primary account will be debited and customer cannot make a selection of bank account at the time of transaction.",
                    MARATHI: "जर ग्राहकाने चुकीचा आधार क्रमांक टाकला/ चुकीची बँक निवडली जेथे त्याचे आधार लिंक केलेले बँक खाते नसेल, तर योग्य प्रतिसाद संदेशासह व्यवहार नाकारला जाईल. शिवाय, ग्राहक त्याचे आधार अनेक बँकांशी लिंक करू शकत असल्याने, ग्राहकाने योग्य बँक निवडणे आवश्यक आहे जिथून त्याला व्यवहार करायचे आहेत. जर ग्राहकाची निवडलेल्या बँकेत एकापेक्षा जास्त खाती असतील तर फक्त प्राथमिक खाते डेबिट केले जाईल आणि ग्राहक व्यवहाराच्या वेळी बँक खाते निवडू शकत नाही."
                }
            },
            {
                title: {
                    HINDI: "क्या ग्राहक को AEPS के माध्यम से लेनदेन करने में सक्षम होने के लिए विशेष रूप से अपने बैंक के साथ पंजीकरण करने की आवश्यकता है?",
                    ENGLISH: "Does the customer specifically need to register with his Bank to be able to transact through AEPS?",
                    MARATHI: " AEPS द्वारे व्यवहार करण्यास सक्षम होण्यासाठी ग्राहकाने विशेषतः त्याच्या बँकेत नोंदणी करणे आवश्यक    आहे का?"
                },
                subtitle: {
                    HINDI: "आदर्श रूप से नहीं, लेकिन आधार संख्या को बैंक खाते से जोड़ना एक पूर्व-आवश्यकता है। हालाँकि, पंजीकरण प्रक्रिया सेवा प्रदान करने वाले जारीकर्ता बैंक द्वारा निर्धारित प्रक्रियाओं के अनुसार होगी। कृपया उस बैंक से जांच लें जहां आपका आधार से जुड़ा बैंक खाता है।",
                    ENGLISH: "Ideally No, but linking Aadhaar Number with Bank account is a pre-requisite. However, registration process shall be as per the procedures laid down by the Issuer bank providing the service. Please check from the bank where you hold the Aadhaar linked bank account.",
                    MARATHI: "आदर्शपणे नाही, परंतु आधार क्रमांक बँक खात्याशी लिंक करणे ही पूर्व-आवश्यकता आहे. तथापि, नोंदणी प्रक्रिया सेवा प्रदान करणाऱ्या बँकेने घालून दिलेल्या प्रक्रियेनुसार होईल. कृपया तुमचे आधार लिंक केलेले बँक खाते असलेल्या बँकेत तपासा."
                }
            },
            {
                title: {
                    HINDI: "जारीकर्ता बैंक कौन है?",
                    ENGLISH: "Who is an Issuer Bank?",
                    MARATHI: " जारी करणारी बँक कोण आहे?"
                },
                subtitle: {
                    HINDI: " जारीकर्ता वह बैंक है जिसमें उपयोगकर्ता अपना खाता रखता है और AEPS लेनदेन करने के लिए आधार को मैप किया जाता है।",
                    ENGLISH: "Issuer is the bank in which the user hold his/her account and Aadhaar is mapped for doing AEPS Transactions.",
                    MARATHI: "जारीकर्ता ही बँक आहे ज्यामध्ये वापरकर्ता त्याचे खाते राखतो आणि AEPS व्यवहार करण्यासाठी आधार मॅप केला जातो."
                }
            },
            {
                title: {
                    HINDI: "अधिग्रहणकर्ता बैंक कौन है?",
                    ENGLISH: "Who is an acquirer Bank?",
                    MARATHI: "अधिग्रहण करणारी बँक कोण आहे?"
                },
                subtitle: {
                    HINDI: " जिस बैंक ने लेन-देन हासिल किया है या जिस बैंक के उपकरण का उपयोग किया गया है वह अधिग्रहणकर्ता बैंक है",
                    ENGLISH: "The bank which has acquired the transaction or the bank whose device has been used is acquirer bank",
                    MARATHI: " ज्या बँकेने व्यवहार विकत घेतला किंवा ज्या बँकेची उपकरणे वापरली गेली ती बँक ही अधिग्रहणकर्ता बँक आहे."
                }
            },
            {
                title: {
                    HINDI: "ऑन अस लेनदेन क्या है?",
                    ENGLISH: "What is an ON Us transaction?",
                    MARATHI: "ऑन अस व्यवहार काय आहे?"
                },
                subtitle: {
                    HINDI: "एक इंट्रा-बैंक (ओएन-यूएस) लेनदेन जहां आधार द्वारा शुरू किए गए लेनदेन का प्रभाव केवल एक ही बैंक के खातों में होता है और इसके लिए इंटरबैंक निपटान की आवश्यकता नहीं होती है। एक ग्राहक AePS सेवा का लाभ उठाने के लिए फ़ील्ड पर तैनात खाता धारक बैंक टर्मिनल का उपयोग कर सकता है।",
                    ENGLISH: "An intra-bank (ON-US) transaction where an Aadhaar initiated transaction has effects only in accounts within one and same bank and does not necessitate an interbank settlement. A Customer can use Account holding bank terminal deployed on the field for availing AePS Service.",
                    MARATHI: "एक आंतर-बँक (ON-US) व्यवहार जेथे आधारने सुरू केलेला व्यवहार फक्त त्याच बँकेच्या खात्यांवर प्रभाव पडतो आणि त्याला आंतरबँक सेटलमेंटची आवश्यकता नसते. एईपीएस सेवेचा लाभ घेण्यासाठी ग्राहक फील्डवर तैनात असलेल्या खातेधारक बँक टर्मिनलचा वापर करू शकतो."
                }
            },
            {
                title: {
                    HINDI: "ऑफ- अस लेनदेन क्या है?",
                    ENGLISH: "What is an OFF-US transaction?",
                    MARATHI: "ऑफ- अस व्यवहार म्हणजे काय?"
                },
                subtitle: {
                    HINDI: "एक अंतर-बैंक (ऑफ- अस) लेनदेन वह है जहां एक बैंक से दूसरे बैंक में धन की आवाजाही होती है जिसके लिए अंतरबैंक निपटान की आवश्यकता होती है। ग्राहक AePS सेवा का लाभ उठाने के लिए अन्य बैंक टर्मिनलों से संपर्क कर सकते हैं।",
                    ENGLISH: "An inter-bank (OFF-US) transaction is one where there is movement of funds from one bank to another necessitating an interbank settlement. Customer can approach other Bank terminals for availing AePS Service.",
                    MARATHI: "आंतर-बँक (ऑफ-ऑन) व्यवहार म्हणजे एका बँकेतून दुसऱ्या बँकेत निधीची वाहतूक होते ज्यासाठी आंतरबँक सेटलमेंट आवश्यक असते. AePS सेवेचा लाभ घेण्यासाठी ग्राहक इतर बँक टर्मिनल्सशी संपर्क साधू शकतात."
                }
            },
            {
                title: {
                    HINDI: "ग्राहक के बैंक में उसके आधार नंबर से जुड़े कई खाते हैं। वह उस खाता संख्या को कैसे निर्दिष्ट कर सकता है जिससे लेनदेन किया जाना है?",
                    ENGLISH: "The Customer has multiple accounts linked to his/her Aadhaar number in his/her bank. How can he/she specify the account number from which the transaction needs to be done?",
                    MARATHI: "ग्राहकाची बँकेत अनेक खाती त्याच्या आधार क्रमांकाशी जोडलेली आहेत. ज्या खात्यातून व्यवहार करायचा आहे तो खाते क्रमांक तो कसा सांगू शकतो?"
                },
                subtitle: {
                    HINDI: "यदि ग्राहक के पास एक ही बैंक में आधार से जुड़े कई खाते हैं, तो AePS सेवाएं केवल आधार से जुड़े प्राथमिक खाते के साथ काम करेंगी। प्राथमिक खाता लिंकिंग निर्दिष्ट/बदलने के लिए ग्राहक को अपने बैंक से संपर्क करना होगा।",
                    ENGLISH: "If customer has multiple account within the same Bank linked to Aadhaar, AEPS services will work with only the primary account associated with the Aadhaar. Customer need to get in touch with his/her bank to specify/change the primary account linking.",
                    MARATHI: "ग्राहकाची एकाच बँकेत आधारशी जोडलेली अनेक खाती असल्यास, AePS सेवा केवळ आधारशी लिंक केलेल्या प्राथमिक खात्यासह कार्य करतील. प्राथमिक खाते लिंकिंग निर्दिष्ट करण्यासाठी/बदलण्यासाठी ग्राहकाला त्याच्या/तिच्या बँकेशी संपर्क साधण्याची आवश्यकता आहे."
                }
            },
            {
                title: {
                    HINDI: "यदि किसी ग्राहक के कई बैंकों में उसके आधार नंबर से जुड़े कई खाते हैं, तो निकासी के लिए किस खाते से डेबिट किया जाएगा?",
                    ENGLISH: "If a customer has multiple accounts linked to his Aadhaar number across multiple Banks, which account will be debited for withdrawal?",
                    MARATHI: "एखाद्या ग्राहकाची अनेक बँकांमध्ये त्याच्या आधार क्रमांकाशी अनेक खाती लिंक असल्यास, पैसे काढण्यासाठी कोणते खाते डेबिट केले जाईल?"
                },
                subtitle: {
                    HINDI: "• चूंकि खाते अलग-अलग (एकाधिक) बैंकों में हैं, इसलिए ग्राहक के पास AePS ऐप में उस बैंक का नाम चुनने का विकल्प होगा जहां से लेनदेन किया जाना है।",
                    ENGLISH: "Since the accounts are held with different (multiple) Banks, Customer will have a choice to select the Bank name in the AEPS app from where the transaction need to be done.",
                    MARATHI: "खाती वेगवेगळ्या (एकाधिक) बँकांमध्ये असल्याने, AePS ॲपमध्ये ज्या बँकेतून व्यवहार करायचे आहेत त्या बँकेचे नाव निवडण्याचा पर्याय ग्राहकाला असेल."
                }
            },
            {
                title: {
                    HINDI: "क्या ग्राहक को लेन-देन के लिए आधार कार्ड ले जाना आवश्यक है?",
                    ENGLISH: "Does the customer need to carry Aadhaar Card for transacting?",
                    MARATHI: "व्यवहारासाठी ग्राहकाकडे आधार कार्ड असणे आवश्यक आहे का?"
                },
                subtitle: {
                    HINDI: "आधार कार्ड ले जाना अनिवार्य नहीं है, लेकिन लेनदेन को सफलतापूर्वक संसाधित करने के लिए बैंक खाते के साथ आधार संख्या का लिंक होना एक शर्त है।",
                    ENGLISH: "Carrying Aadhaar card is not mandatory but linkage of Aadhaar Number with Bank account is a prerequisite for transaction to be processed successfully.",
                    MARATHI: "आधार कार्ड असणे अनिवार्य नाही, परंतु बँक खात्याशी आधार क्रमांक लिंक करणे ही यशस्वी व्यवहारासाठी पूर्व अट आहे."
                }
            },
            {
                title: {
                    HINDI: " क्या AePS नकद निकासी और फंड ट्रांसफर लेनदेन पर कोई सीमा है? यदि हाँ तो सीमाएँ क्या हैं?",
                    ENGLISH: "Is there any limit on AEPS Cash Withdrawal and Fund Transfer transactions? If so what are the limits.",
                    MARATHI: "AePS रोख काढणे आणि निधी हस्तांतरण व्यवहारांवर काही मर्यादा आहे का? होय असल्यास, मर्यादा काय आहेत?"
                },
                subtitle: {
                    HINDI: "2 नवंबर 2023 के NPCI परिपत्र के अनुसार, बैंकों को सलाह दी गई है कि वे अपने ग्राहकों को 50000/- प्रति माह की संचयी सीमा के साथ AePS नकद निकासी और भीम आधार भुगतान लेनदेन की अनुमति दें। सीमा को 30 दिनों के रोलिंग आधार पर निर्धारित करने की अनुशंसा की जाती है। ",
                    ENGLISH: "As per the NPCI circular dated 2nd November 2023, banks have been advised to allow their customers AePS cash withdrawal and BHIM aadhar Pay transactions with a cumulative limit of 50000/- per month. It is recommended to set the limit on a 30 days rolling basis.",
                    MARATHI: "NPCI च्या 2 नोव्हेंबर 2023 च्या परिपत्रकानुसार, बँकांना त्यांच्या ग्राहकांना AePS रोख पैसे काढण्याची आणि BHIM आधार पेमेंट व्यवहारांना दरमहा रु 50000/- च्या संचयी मर्यादेसह परवानगी देण्याचा सल्ला देण्यात आला आहे. 30 दिवसांच्या रोलिंगच्या आधारावर मर्यादा सेट करण्याची शिफारस केली जाते."
                }
            },
            {
                title: {
                    HINDI: " ग्राहक का अन्य बैंक में संयुक्त खाता है। क्या AePS द्वारा दी जाने वाली AEPS सेवाएँ मेरे लिए उपलब्ध होंगी? क्या संयुक्त खाताधारकों की अतिरिक्त सहमति की आवश्यकता होगी?",
                    ENGLISH: "The customer has a Joint Account with other Bank. Will AEPS services offered by AePS available to me? Will additional consent of Joint Account holders will be required?",
                    MARATHI: "ग्राहकाचे दुसऱ्या बँकेत संयुक्त खाते आहे. AePS द्वारे ऑफर केलेल्या AEPS सेवा मला उपलब्ध असतील का? संयुक्त खातेदारांची अतिरिक्त संमती आवश्यक आहे का?"
                },
                subtitle: {
                    HINDI: " यदि आधार संयुक्त खाते से जुड़ा हुआ है और जहां संचालन की स्थिति या तो जीवित है या उत्तरजीवी है, तो किसी भी आधार विवरण का उपयोग करके सेवाओं का लाभ उठाया जा सकता है। । ",
                    ENGLISH: "In case Aadhaar is linked to the joint account and where the operation condition is either or survivor, then services can be availed using any of the Aadhaar details.",
                    MARATHI: "जर आधार संयुक्त खात्याशी जोडलेला असेल आणि जिथे ऑपरेशनल स्थिती एकतर जिवंत असेल किंवा वाचलेले असेल, तर कोणत्याही आधार तपशील वापरून सेवांचा लाभ घेता येईल."
                }
            },
            {
                title: {
                    HINDI: " क्या AEPS सेवाओं का लाभ उठाने के लिए कोई आयु मानदंड है? क्या 10 वर्ष से अधिक उम्र का कोई नाबालिग ऐसी सेवाओं का लाभ उठा सकता है? क्या 10 वर्ष से कम आयु का कोई नाबालिग ऐसी सेवाओं का लाभ उठा सकता है?",
                    ENGLISH: "Is there any age criteria to avail AEPS services? Can a minor above 10 years of age, avail such services? Can a minor below age of 10 years, avail such services?",
                    MARATHI: "AEPS सेवांचा लाभ घेण्यासाठी वयाचे काही निकष आहेत का? 10 वर्षांवरील अल्पवयीन मुल अशा सेवांचा लाभ घेऊ शकतात का? "
                },
                subtitle: {
                    HINDI: "नाबालिगों के लिए इसकी अनुमति है, लेकिन यह जारीकर्ता बैंक पर निर्भर करता है। ",
                    ENGLISH: "It is allowed for minors, but it depends on the issuer bank.",
                    MARATHI: "अल्पवयीन मुलांसाठी  याची परवानगी आहे, परंतु ते जारी करणाऱ्या बँकेवर अवलंबून आहे."
                }
            },
            {
                title: {
                    HINDI: " ग्राहक के खाते से डेबिट हो गया है और उसे अपने बैंक से पुष्टि प्राप्त हो गई है लेकिन MATM/CBS में लेनदेन पूरा नहीं हो सका है?",
                    ENGLISH: "Customer account has got debited and he/she has received confirmation from his/her Bank but transaction in MATM/CBS could not be completed",
                    MARATHI: "ग्राहकाचे खाते डेबिट झाले आहे आणि त्याला त्याच्या बँकेकडून पुष्टी मिळाली आहे परंतु MATM/CBS मध्ये व्यवहार पूर्ण झाला नाही? "
                },
                subtitle: {
                    HINDI: "• संभवतः, लेनदेन विफल हो गया है। रिफंड ग्राहक ए/सी में वापस चला जाएगा लेकिन इसमें 5 कार्य दिवस लग सकते हैं। यदि राशि 5 दिनों के भीतर वापस जमा नहीं की जाती है, तो उस बैंक से राशि जुटाई जा सकती है जिसके पास खाता है। ",
                    ENGLISH: "Possibly, the transaction has failed. The refund will go back to the customer A/C but it might take 5 working days. If the amount is not credited back within 5 days, same can be raised with bank they hold the account.",
                    MARATHI: "शक्यतो, व्यवहार अयशस्वी झाला आहे. परतावा ग्राहकाच्या A/C वर परत जाईल परंतु यास 5 कार्य दिवस लागू शकतात. जर 5 दिवसांच्या आत रक्कम जमा झाली नाही, तर खाते असलेल्या बँकेतून रक्कम उभी केली जाऊ शकते."
                }
            },
            {
                title: {
                    HINDI: "विफल लेनदेन के लिए ग्राहक शिकायत कहां दर्ज कराए?",
                    ENGLISH: "Where do a customer register a complaint for failed transaction?",
                    MARATHI: "अयशस्वी व्यवहारासाठी ग्राहकाने तक्रार कोठे करावी? "
                },
                subtitle: {
                    HINDI: "ग्राहक जिस बैंक में खाता रखता है, उसके साथ विवाद/अनुपालन कर सकता है। बैंक इसे NPCI के विवाद प्रबंधन प्रणाली के माध्यम से संबंधित बैंक के साथ बढ़ाएगा।",
                    ENGLISH: "The customer can raise a dispute/compliant with the bank they hold the account. Bank will further raise it with the concerned bank via NPCI’s Dispute Management System.",
                    MARATHI: "ग्राहक ज्या बँकेत खाते ठेवतो त्या बँकेशी विवाद/पालन करू शकतो. NPCI च्या विवाद व्यवस्थापन प्रणालीद्वारे बँक संबंधित बँकेकडे ते वाढेल."
                }
            },
            {
                title: {
                    HINDI: "AePs सुविधा का उपयोग कैसे करें?",
                    ENGLISH: "How to Use the AePs Facility?",
                    MARATHI: "AePs सुविधेचा वापर कसा करावा? "
                },
                subtitle: {
                    HINDI: "AePS सुविधा का उपयोग करने के लिए, लिंक किए गए बैंक खाते वाला आधार कार्ड धारक इन चरणों का पालन कर सकता है: , स्थानीय सीएसपी पर जाएँ , प्वाइंट ऑफ सेल (पीओएस) मशीन में आधार नंबर दर्ज करें। , लेनदेन प्रकार का चयन करें और बैंक का नाम निर्दिष्ट करें। , लेनदेन को आगे बढ़ाने के लिए लेनदेन राशि दर्ज करें। , भुगतान की पुष्टि के लिए बायोमेट्रिक प्रमाणीकरण (फिंगरप्रिंट या आईरिस स्कैन) प्रदान करें। , सफल समापन पर, लेनदेन के लिए एक रसीद जारी की जाएगी।",
                    ENGLISH: "To utilize the AePS facility, an Aadhaar card holder with a linked bank account can follow these steps: 1. Visit a local CSP 2. Enter the Aadhaar number into the Point of Sale (PoS) machine. 3. Select the transaction type and specify the bank’s name. 4. Enter the transaction amount to proceed with the transaction. 5. Provide biometric authentication (fingerprint or iris scan) to confirm the payment. 6. Upon successful completion, a receipt will be issued for the transaction.",
                    MARATHI: "AePS सुविधेचा वापर करण्यासाठी, लिंक केलेले बँक खाते असलेले आधार कार्ड धारक या चरणांचे अनुसरण करू शकतात: स्थानिक CSP वर जा पॉइंट ऑफ सेल (POS) मशिनमध्ये आधार क्रमांक टाका. व्यवहाराचा प्रकार निवडा आणि बँकेचे नाव निर्दिष्ट करा. व्यवहार सुरू ठेवण्यासाठी व्यवहाराची रक्कम प्रविष्ट करा. पेमेंटची पुष्टी करण्यासाठी बायोमेट्रिक प्रमाणीकरण (फिंगरप्रिंट किंवा आयरिस स्कॅन) प्रदान करा. यशस्वीरित्या पूर्ण झाल्यावर, व्यवहाराची पावती दिली जाईल"
                }
            },
            {
                title: {
                    HINDI: "AePs लेनदेन करने में शामिल संस्थाएँ कौन हैं?",
                    ENGLISH: "Who are the entities involved in carrying out an AePs transaction?",
                    MARATHI: "AePs व्यवहार पार पाडण्यात गुंतलेली संस्था कोण आहेत?"
                },
                subtitle: {
                    HINDI: "ऐसे लेनदेन को सुविधाजनक बनाने में कई संस्थाएँ शामिल हैं:  लेनदेन करने का इच्छुक व्यक्ति।  मध्यस्थ, जो एक व्यापारी या स्टोर मालिक, या एक बैंकिंग संवाददाता हो सकता है, जिसके माध्यम से लेनदेन किया जाता है।  आधार-सक्षम बैंक।  यूआईडीएआई, फिंगरप्रिंट प्रमाणीकरण के लिए जिम्मेदार है।  NPCI (नेशनल पेमेंट्स कॉरपोरेशन ऑफ इंडिया), लेनदेन निपटान के लिए जिम्मेदार है।",
                    ENGLISH: "A number of entities are involved in facilitating such transactions: The individual wishing to carry out the transaction. The intermediary, which could be a merchant or store owner, or a banking correspondent, through whom the transaction is conducted. The Aadhaar-enabled bank. UIDAI, responsible for fingerprint authentication. NPCI (National Payments Corporation of India), responsible for transaction settlement.",
                    MARATHI: "असे व्यवहार सुलभ करण्यात अनेक संस्थांचा सहभाग आहे: व्यवहार करण्यास इच्छुक व्यक्ती. मध्यस्थ, जो व्यापारी किंवा स्टोअर मालक किंवा बँकिंग वार्ताहर असू शकतो, ज्यांच्याद्वारे व्यवहार केले जातात. आधार-सक्षम बँका. UIDAI फिंगरप्रिंट प्रमाणीकरणासाठी जबाबदार आहे. NPCI (नॅशनल पेमेंट कॉर्पोरेशन ऑफ इंडिया), व्यवहार सेटलमेंटसाठी जबाबदार आहे. "
                }
            },
            {
                title: {
                    HINDI: "AePS में धोखाधड़ी को नियंत्रित करने के लिए NPCI द्वारा क्या कदम उठाए गए हैं?",
                    ENGLISH: "What are the steps taken by NPCI to control fraud in AePS?",
                    MARATHI: "AePS मधील फसवणूक नियंत्रित करण्यासाठी NPCI ने कोणती पावले उचलली आहेत?"
                },
                subtitle: {
                    HINDI: "• NPCI ने बैंकों से बैंकिंग करेस्पोंडेंट्स /एजेंटों के प्रमाणीकरण से संबंधित उपायों को 1 जनवरी, 2024 से तीन महीने की प्रारंभिक अवधि के लिए लागू करने के लिए कहा, जिसके बाद आगे की कार्रवाई तय करने के लिए प्रभाव की समीक्षा की जाएगी।",
                    ENGLISH: "NPCI asked banks to implement the measures relating to authentication of BCs/Agents from January 1, 2024, for an initial period of three months, after which the impact will be reviewed to decide the further course of action",
                    MARATHI: "NPCI ने बँकांना 1 जानेवारी 2024 पासून तीन महिन्यांच्या सुरुवातीच्या कालावधीसाठी बँकिंग करस्पॉन्डंट/एजंटच्या प्रमाणीकरणाशी संबंधित उपाययोजना अंमलात आणण्यास सांगितले, त्यानंतर पुढील कारवाईचा निर्णय घेण्यासाठी प्रभावाचा आढावा घेतला जाईल. "
                }
            },
            
        ],
        bbpsque: [
            {
                title: {
                    HINDI: " BBPS क्या है?",
                    ENGLISH: "What is BBPS?",
                    MARATHI: " BBPS म्हणजे काय? "
                },
                subtitle: {
                    HINDI: " भारत बिल भुगतान प्रणाली (BBPS) एक एकीकृत मंच है जो बिलों के सुरक्षित और सुविधाजनक इलेक्ट्रॉनिक भुगतान को सक्षम बनाता है।",
                    ENGLISH: "Bharat Bill Payment System (BBPS) is an integrated platform that enables secure and convenient electronic payment of bills.",
                    MARATHI: "भारत बिल पेमेंट सिस्टम (BBPS) हे एकात्मिक प्लॅटफॉर्म आहे जे बिलांचे सुरक्षित आणि सोयीस्कर इलेक्ट्रॉनिक पेमेंट सक्षम करते."
                }
            },
            {
                title: {
                    HINDI: "BBPS कैसे काम करता है?",
                    ENGLISH: "How does BBPS work?",
                    MARATHI: " BBPS कसे काम करते?"
                },
                subtitle: {
                    HINDI: "BBPS एक मानकीकृत मंच के माध्यम से ग्राहकों, बैंकों और बिलर्स को जोड़कर काम करता है, जिससे इलेक्ट्रॉनिक रूप से निर्बाध बिल भुगतान की अनुमति मिलती है।",
                    ENGLISH: "BBPS works by connecting customers, banks, and billers through a standardized platform, allowing seamless bill payments electronically.",
                    MARATHI: "BBPS ग्राहक, बँका आणि बिलर्सना प्रमाणित प्लॅटफॉर्मद्वारे जोडून काम करते, इलेक्ट्रॉनिक पद्धतीने अखंड बिल पेमेंट करण्याची परवानगी देते."
                }
            },
            {
                title: {
                    HINDI: "भारत में BBPS को कौन नियंत्रित करता है?",
                    ENGLISH: "Who regulates BBPS in India?",
                    MARATHI: " भारतात BBPS चे नियंत्रण कोण करते?"
                },
                subtitle: {
                    HINDI: "BBPS को नेशनल पेमेंट कारपोरेशन ऑफ़ इंडिया (NPCI) द्वारा विनियमित किया जाता है, जो मानकों और दिशानिर्देशों का अनुपालन सुनिश्चित करता है।",
                    ENGLISH: "BBPS is regulated by the National Payments Corporation of India (NPCI), ensuring compliance with standards and guidelines.",
                    MARATHI: "BBPS चे नियमन नॅशनल पेमेंट कॉर्पोरेशन ऑफ इंडिया (NPCI) द्वारे केले जाते, जे मानके आणि मार्गदर्शक तत्त्वांचे पालन सुनिश्चित करते."
                }
            },
            {
                title: {
                    HINDI: "BBPS के माध्यम से किस प्रकार के बिलों का भुगतान किया जा सकता है?",
                    ENGLISH: "What types of bills can be paid through BBPS?",
                    MARATHI: "BBPS द्वारे कोणत्या प्रकारची बिले भरली जाऊ शकतात?"
                },
                subtitle: {
                    HINDI: "BBPS बिजली, पानी, गैस, दूरसंचार, डीटीएच और अन्य सहित बिल श्रेणियों की एक विस्तृत श्रृंखला का समर्थन करता है।",
                    ENGLISH: "BBPS supports a wide range of bill categories, including electricity, water, gas, telecom, DTH, and more.",
                    MARATHI: "BBPS वीज, पाणी, गॅस, दूरसंचार, DTH आणि इतरांसह बिल श्रेणींच्या विस्तृत श्रेणीला समर्थन देते."
                }
            },
            {
                title: {
                    HINDI: "क्या BBPS व्यक्तिगत और व्यावसायिक बिल भुगतान दोनों के लिए उपलब्ध है?",
                    ENGLISH: "Is BBPS available for both individual and business bill payments?",
                    MARATHI: "वैयक्तिक आणि व्यावसायिक बिल पेमेंटसाठी BBPS उपलब्ध आहे का?"
                },
                subtitle: {
                    HINDI: "हाँ, BBPS व्यक्तिगत उपभोक्ताओं और व्यवसायों दोनों को उनकी बिल भुगतान आवश्यकताओं को पूरा करता है।",
                    ENGLISH: "Yes, BBPS caters to both individual consumers and businesses for their bill payment needs.",
                    MARATHI: "होय, BBPS वैयक्तिक ग्राहक आणि व्यवसाय दोघांनाही त्यांच्या बिल भरण्याच्या गरजा पूर्ण करते."
                }
            },
            {
                title: {
                    HINDI: " क्या BBPS के उपयोग से कोई शुल्क जुड़ा है?",
                    ENGLISH: "Are there any charges associated with using BBPS?",
                    MARATHI: "BBPS वापरण्याशी संबंधित काही शुल्क आहेत का?"
                },
                subtitle: {
                    HINDI: " सेवा प्रदाता नाममात्र शुल्क लगा सकते हैं, लेकिन BBPS स्वयं ग्राहकों पर कोई शुल्क नहीं लगाता है।",
                    ENGLISH: "Service providers may apply nominal charges, but BBPS itself does not levy any fees on customers.",
                    MARATHI: "सेवा प्रदाते नाममात्र शुल्क आकारू शकतात, परंतु BBPS स्वतः ग्राहकांवर कोणतेही शुल्क लादत नाही."
                }
            },
            {
                title: {
                    HINDI: " BBPS लेनदेन के लिए कौन से सुरक्षा उपाय मौजूद हैं?",
                    ENGLISH: "What security measures are in place for BBPS transactions?",
                    MARATHI: "BBPS व्यवहारांसाठी कोणते सुरक्षा उपाय आहेत?"
                },
                subtitle: {
                    HINDI: "लेनदेन की सुरक्षा सुनिश्चित करने के लिए BBPS एन्क्रिप्शन और प्रमाणीकरण सहित कड़े सुरक्षा प्रोटोकॉल का पालन करता है।",
                    ENGLISH: "Service providers may apply nominal charges, but BBPS itself does not levy any fees on customers.",
                    MARATHI: "BBPS व्यवहारांची सुरक्षितता सुनिश्चित करण्यासाठी एन्क्रिप्शन आणि प्रमाणीकरणासह कडक सुरक्षा प्रोटोकॉलचे पालन करते."
                }
            },
            {
                title: {
                    HINDI: " BBPS के माध्यम से बिल भुगतान संसाधित होने में कितना समय लगता है?",
                    ENGLISH: "How long does it take for a bill payment to be processed through BBPS?",
                    MARATHI: "BBPS द्वारे बिल पेमेंट प्रक्रिया होण्यासाठी किती वेळ लागतो?"
                },
                subtitle: {
                    HINDI: " BBPS लेनदेन आम तौर पर वास्तविक समय में संसाधित होते हैं, जिससे शीघ्र भुगतान की पुष्टि सुनिश्चित होती है।",
                    ENGLISH: "BBPS transactions are typically processed in real-time, ensuring prompt payment confirmation.",
                    MARATHI: "BBPS व्यवहारांवर सामान्यतः रिअल टाइममध्ये प्रक्रिया केली जाते, त्वरित पेमेंट पुष्टी सुनिश्चित करते."
                }
            },
        ],
        bbpsque: [
            {
                title: {
                    HINDI: " BBPS क्या है?",
                    ENGLISH: "What is BBPS?",
                    MARATHI: " BBPS म्हणजे काय? "
                },
                subtitle: {
                    HINDI: " भारत बिल भुगतान प्रणाली (BBPS) एक एकीकृत मंच है जो बिलों के सुरक्षित और सुविधाजनक इलेक्ट्रॉनिक भुगतान को सक्षम बनाता है।",
                    ENGLISH: "Bharat Bill Payment System (BBPS) is an integrated platform that enables secure and convenient electronic payment of bills.",
                    MARATHI: "भारत बिल पेमेंट सिस्टम (BBPS) हे एकात्मिक प्लॅटफॉर्म आहे जे बिलांचे सुरक्षित आणि सोयीस्कर इलेक्ट्रॉनिक पेमेंट सक्षम करते."
                }
            },
            {
                title: {
                    HINDI: "BBPS कैसे काम करता है?",
                    ENGLISH: "How does BBPS work?",
                    MARATHI: " BBPS कसे काम करते?"
                },
                subtitle: {
                    HINDI: "BBPS एक मानकीकृत मंच के माध्यम से ग्राहकों, बैंकों और बिलर्स को जोड़कर काम करता है, जिससे इलेक्ट्रॉनिक रूप से निर्बाध बिल भुगतान की अनुमति मिलती है।",
                    ENGLISH: "BBPS works by connecting customers, banks, and billers through a standardized platform, allowing seamless bill payments electronically.",
                    MARATHI: "BBPS ग्राहक, बँका आणि बिलर्सना प्रमाणित प्लॅटफॉर्मद्वारे जोडून काम करते, इलेक्ट्रॉनिक पद्धतीने अखंड बिल पेमेंट करण्याची परवानगी देते."
                }
            },
            {
                title: {
                    HINDI: "भारत में BBPS को कौन नियंत्रित करता है?",
                    ENGLISH: "Who regulates BBPS in India?",
                    MARATHI: " भारतात BBPS चे नियंत्रण कोण करते?"
                },
                subtitle: {
                    HINDI: "BBPS को नेशनल पेमेंट कारपोरेशन ऑफ़ इंडिया (NPCI) द्वारा विनियमित किया जाता है, जो मानकों और दिशानिर्देशों का अनुपालन सुनिश्चित करता है।",
                    ENGLISH: "BBPS is regulated by the National Payments Corporation of India (NPCI), ensuring compliance with standards and guidelines.",
                    MARATHI: "BBPS चे नियमन नॅशनल पेमेंट कॉर्पोरेशन ऑफ इंडिया (NPCI) द्वारे केले जाते, जे मानके आणि मार्गदर्शक तत्त्वांचे पालन सुनिश्चित करते."
                }
            },
            {
                title: {
                    HINDI: "BBPS के माध्यम से किस प्रकार के बिलों का भुगतान किया जा सकता है?",
                    ENGLISH: "What types of bills can be paid through BBPS?",
                    MARATHI: "BBPS द्वारे कोणत्या प्रकारची बिले भरली जाऊ शकतात?"
                },
                subtitle: {
                    HINDI: "BBPS बिजली, पानी, गैस, दूरसंचार, डीटीएच और अन्य सहित बिल श्रेणियों की एक विस्तृत श्रृंखला का समर्थन करता है।",
                    ENGLISH: "BBPS supports a wide range of bill categories, including electricity, water, gas, telecom, DTH, and more.",
                    MARATHI: "BBPS वीज, पाणी, गॅस, दूरसंचार, DTH आणि इतरांसह बिल श्रेणींच्या विस्तृत श्रेणीला समर्थन देते."
                }
            },
            {
                title: {
                    HINDI: "क्या BBPS व्यक्तिगत और व्यावसायिक बिल भुगतान दोनों के लिए उपलब्ध है?",
                    ENGLISH: "Is BBPS available for both individual and business bill payments?",
                    MARATHI: "वैयक्तिक आणि व्यावसायिक बिल पेमेंटसाठी BBPS उपलब्ध आहे का?"
                },
                subtitle: {
                    HINDI: "हाँ, BBPS व्यक्तिगत उपभोक्ताओं और व्यवसायों दोनों को उनकी बिल भुगतान आवश्यकताओं को पूरा करता है।",
                    ENGLISH: "Yes, BBPS caters to both individual consumers and businesses for their bill payment needs.",
                    MARATHI: "होय, BBPS वैयक्तिक ग्राहक आणि व्यवसाय दोघांनाही त्यांच्या बिल भरण्याच्या गरजा पूर्ण करते."
                }
            },
            {
                title: {
                    HINDI: " क्या BBPS के उपयोग से कोई शुल्क जुड़ा है?",
                    ENGLISH: "Are there any charges associated with using BBPS?",
                    MARATHI: "BBPS वापरण्याशी संबंधित काही शुल्क आहेत का?"
                },
                subtitle: {
                    HINDI: " सेवा प्रदाता नाममात्र शुल्क लगा सकते हैं, लेकिन BBPS स्वयं ग्राहकों पर कोई शुल्क नहीं लगाता है।",
                    ENGLISH: "Service providers may apply nominal charges, but BBPS itself does not levy any fees on customers.",
                    MARATHI: "सेवा प्रदाते नाममात्र शुल्क आकारू शकतात, परंतु BBPS स्वतः ग्राहकांवर कोणतेही शुल्क लादत नाही."
                }
            },
            {
                title: {
                    HINDI: " BBPS लेनदेन के लिए कौन से सुरक्षा उपाय मौजूद हैं?",
                    ENGLISH: "What security measures are in place for BBPS transactions?",
                    MARATHI: "BBPS व्यवहारांसाठी कोणते सुरक्षा उपाय आहेत?"
                },
                subtitle: {
                    HINDI: "लेनदेन की सुरक्षा सुनिश्चित करने के लिए BBPS एन्क्रिप्शन और प्रमाणीकरण सहित कड़े सुरक्षा प्रोटोकॉल का पालन करता है।",
                    ENGLISH: "Service providers may apply nominal charges, but BBPS itself does not levy any fees on customers.",
                    MARATHI: "BBPS व्यवहारांची सुरक्षितता सुनिश्चित करण्यासाठी एन्क्रिप्शन आणि प्रमाणीकरणासह कडक सुरक्षा प्रोटोकॉलचे पालन करते."
                }
            },
            {
                title: {
                    HINDI: " BBPS के माध्यम से बिल भुगतान संसाधित होने में कितना समय लगता है?",
                    ENGLISH: "How long does it take for a bill payment to be processed through BBPS?",
                    MARATHI: "BBPS द्वारे बिल पेमेंट प्रक्रिया होण्यासाठी किती वेळ लागतो?"
                },
                subtitle: {
                    HINDI: " BBPS लेनदेन आम तौर पर वास्तविक समय में संसाधित होते हैं, जिससे शीघ्र भुगतान की पुष्टि सुनिश्चित होती है।",
                    ENGLISH: "BBPS transactions are typically processed in real-time, ensuring prompt payment confirmation.",
                    MARATHI: "BBPS व्यवहारांवर सामान्यतः रिअल टाइममध्ये प्रक्रिया केली जाते, त्वरित पेमेंट पुष्टी सुनिश्चित करते."
                }
            },
        ]
    }
}