import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Reqimg from './img/Reqimg.jpg';
import './EgovReq.css';

const EgovReq = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [isOpen, setIsOpen] = useState([]);

    const toggleParagraph = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
        setIsOpen(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <>
            <div className='egovreq-home-container'>
                {/* <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center py-3'>
                            {/* <img className='egovreq-home-img' src={Reqimg} alt='aboutimg' /> 
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 p-0'>
                            <div className='egovreq-left-content text-dark d-flex flex-column'>
                                <h2>Aadhar enable Payment System<span> AePS</span></h2>
                                <ul className="item-list">
                                    <li className={activeIndex === 0 ? "active" : ""} onClick={() => toggleParagraph(0)}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="expanded-paragraph"> Cash Deposit
                                            </h6>
                                            <span className={`arrow ${isOpen[0] ? "rotate" : ""}`} onClick={() => toggleParagraph(0)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 10.793l6.354-6.353a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0l-7-7a.5.5 0 1 1 .708-.708L8 10.793z" />
                                                </svg>
                                            </span>
                                        </div>
                                        {activeIndex === 0 && <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>}
                                    </li>
                                    <li className={activeIndex === 1 ? "active" : ""} onClick={() => toggleParagraph(1)}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="expanded-paragraph"> Cash Withdrawal</h6>
                                            <span className={`arrow ${isOpen[1] ? "rotate" : ""}`} onClick={() => toggleParagraph(1)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 10.793l6.354-6.353a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0l-7-7a.5.5 0 1 1 .708-.708L8 10.793z" />
                                                </svg>
                                            </span>
                                        </div>
                                        {activeIndex === 1 && <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>}
                                    </li>
                                    <li className={activeIndex === 2 ? "active" : ""} onClick={() => toggleParagraph(2)}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="expanded-paragraph"> Aadhar Pay
                                            </h6>
                                            <span className={`arrow ${isOpen[2] ? "rotate" : ""}`} onClick={() => toggleParagraph(2)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 10.793l6.354-6.353a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0l-7-7a.5.5 0 1 1 .708-.708L8 10.793z" />
                                                </svg>
                                            </span>
                                        </div>
                                        {activeIndex === 2 && <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>}
                                    </li>
                                    <li className={activeIndex === 3 ? "active" : ""} onClick={() => toggleParagraph(3)}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="expanded-paragraph"> Mini statement
                                            </h6>
                                            <span className={`arrow ${isOpen[3] ? "rotate" : ""}`} onClick={() => toggleParagraph(3)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 10.793l6.354-6.353a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0l-7-7a.5.5 0 1 1 .708-.708L8 10.793z" />
                                                </svg>
                                            </span>
                                        </div>
                                        {activeIndex === 3 && <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='responsive-btn mt-3 d-flex align-items-center justify-content-center'>
                        <Link to="/">
                            <button style={{ backgroundColor: '#000', color: "#fff", }} className='btn csp-home-btn d-flex align-items-center px-3 px-lg-4 rounded-pill' >
                                Learn More
                                <span className='d-flex home-btn-arrow' style={{ transitionDuration: '0.3s', color: "#fff" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
                                    </svg>
                                </span>
                            </button>
                        </Link>
                    </div>
                </div> */}
            </div>
            <div className="egovreq-bottom">
                <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod </h2>
                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </h4>
            </div>
        </>
    )
}

export default EgovReq;
