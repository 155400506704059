import React from 'react'
import './About.css'
import AboutHome from './AboutHome/AboutHome'
import Enquiry from './Enquiry/Enquiry'
import MissionVision from './Mission-Vision/Mission-Vision'
import Values from './Values/Values'

const About = () => {
    return (
        <div className='about'>
            <AboutHome />
            <MissionVision />
            <Values />
            {/* <Leadership /> */}
            <Enquiry />
        </div>
    )
}

export default About