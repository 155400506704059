import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Landing/Home/Home';
import Footer from './Components/Footer/Footer';
import Services from './Components/Services/Services';
import Solutions from './Components/Solutions/Solutions';
import About from './Components/About/About';
import Careers from './Components/Careers/Careers';
import Knowledge from './Components/Knowledge/Knowledge';
import Media from './Components/Media/Media';
import Contact from './Components/Contact/Contact';
import Privacy from './Components/Privacy/Privacy';
import Terms from './Components/Terms/Terms';
import Cookie from './Components/Cookie/Cookie';
import Refund from './Components/Refund/Refund';
import Desclaimer from './Components/Desclaimer/Desclaimer';
import Aadhar from './Components/AadharPolicy/Aadhar';
import BecomePartner from './Components/BecomePartner/BecomePartner';
import Login from './Components/Login/Login';
import OTP from './Components/Join/OTP/OTP';
import DigitalTransformation from './Components/Knowledge/ReadMore/DigitalTransformation';
import ImpactDT from './Components/Knowledge/ReadMore/ImpactDT';
import GrowingEntrepreneurialAspirations from './Components/Knowledge/ReadMore/GrowingEntrepreneurialAspirations';
import AEPS from './Components/Knowledge/ReadMore/AEPS';
import Auth from './Components/Auth/Auth';
import AboutNew from './Components/AboutNew/AboutNew';
import Banking from './Components/NewServices/Banking/Banking';
import NewPayment from './Components/NewServices/Payment/NewPayment';
import NewFinancial from './Components/NewServices/NewFinancial/NewFinancial';
import NewInvestment from './Components/NewServices/NewInvestment/NewInvestment';
import NewTravel from './Components/NewServices/NewTravel/NewTravel';
import NewEgov from './Components/NewServices/NewEgov/NewEgov';
import CspBanking from './Components/CspServices/Banking/CspBanking';
import CspPayment from './Components/CspServices/Payment/CspPayment';
import CspFinanical from './Components/CspServices/Financial/Cspfinanical';
import CspInvestment from './Components/CspServices/Investment/CspInvestment';
import CspTravel from './Components/CspServices/Travel/CspTravel';
import CspEgov from './Components/CspServices/Egov/CspEgov';
import Faq from './Components/CspServices/Faq/Faq';
import NotFound from './Components/NotFound/Notfound';
import { GetFontValue } from './Components/Data/LanguageContext';

function App() {
    const is404Page = () => window.location.pathname === "/404";
    let fontstyle = GetFontValue()
    return (
        <>
            <div className='App' style={{fontFamily: fontstyle}}>
                {!is404Page() && <Navbar />}
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/Services' element={<Services />} />
                    <Route path='/Solutions' element={<Solutions />} />
                    <Route path='/About' element={<About />} />
                    <Route path='/Services/Banking' element={<Banking />} />
                    <Route path='/Services/payment' element={<NewPayment />} />
                    <Route path='/Services/financial' element={<NewFinancial />} />
                    <Route path='/Services/investment' element={<NewInvestment />} />
                    <Route path='/Services/travel' element={<NewTravel />} />
                    <Route path='/Services/e-governance' element={<NewEgov />} />
                    {/* <Route path='/Join' element={<Join />} /> */}
                    <Route path='/Careers' element={<Careers />} />

                    <Route path='/Resources' element={<Knowledge />} />
                    <Route path='/GrowingEntrepreneurialAspirations' element={<GrowingEntrepreneurialAspirations />} />
                    <Route path='/AEPS_Blog' element={<AEPS />} />
                    <Route path='/Digital_Transformation' element={<DigitalTransformation />} />
                    <Route path='/Impact_of_Digital_Transformation' element={<ImpactDT />} />

                    <Route path='/Media&Events' element={<Media />} />
                    <Route path='/Become-a-Partner' element={<BecomePartner />} />
                    <Route path='/ContactUs' element={<Contact />} />
                    <Route path='/PrivacyPolicy' element={<Privacy />} />
                    <Route path='/Terms&Conditions' element={<Terms />} />
                    <Route path='/CookiePolicy' element={<Cookie />} />
                    <Route path='/Refund&Cancellation' element={<Refund />} />
                    <Route path='/Desclaimer' element={<Desclaimer />} />
                    <Route path='/AadharDataPolicy' element={<Aadhar />} />
                    <Route path='/Login' element={<Login />} />
                    <Route path='/OTP' element={<OTP />} />
                    <Route path='/Auth' element={<Auth></Auth>} />

                    <Route path='/banking' element={<CspBanking />} />
                    <Route path='/payment' element={<CspPayment />} />
                    <Route path='/financial' element={<CspFinanical />} />
                    <Route path='/investment' element={<CspInvestment />} />
                    <Route path='/travel' element={<CspTravel />} />
                    <Route path='/egovernance' element={<CspEgov />} />
                    <Route path='/faq' element={<Faq />} />
                    {/* <Route path='/e-governance' element={<CspEgov />} /> */}
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
                <Footer />
            </div>
        </>
    );
}

export default App;
