export const InvestmentServicePageData = {
    INVESTMENTSERVICES: {
        investmentheading: {
            HINDI: "इन्वेस्टमेंट",
            ENGLISH: "Investment Services",
            MARATHI: "गुंतवणूक सेवा"
        },
        investmentheading2: {
            HINDI: "स्कोप और ऑफरिंग",
            ENGLISH: "Scope And Offering",
            MARATHI: "व्याप्ती आणि ऑफरिंग"
        },
        investmentservicepara: [
            {
                title: {
                    HINDI: "भारत में आम वित्तीय निवेशकों के लिए निवेश का मतलब संसाधनों को इस तरह से आवंटित करने के लिए रणनीतिक निर्णय लेना है जिससे रिटर्न अधिकतम हो, वित्तीय उद्देश्य प्राप्त हों और समग्र वित्तीय कल्याण बढ़े। बदलती बाजार स्थितियों और व्यक्तिगत परिस्थितियों के अनुकूल होने के लिए सावधानीपूर्वक योजना, शोध और निरंतर निगरानी की आवश्यकता होती है।",
                    ENGLISH: "Investment for individuals in India is about making strategic decisions to allocate resources in away that maximizes returns, achieves financial objectives, and enhances overall financial well-being. It requires careful planning, research, and ongoing monitoring to adapt to changing smarket conditions and personal circumstances.",
                    MARATHI: "भारतातील व्यक्तींसाठी गुंतवणूक म्हणजे जास्तीत जास्त परतावा देणारे, आर्थिक उद्दिष्टे साध्य करणारे आणि एकूणच आर्थिक कल्याण वाढवणाऱ्या संसाधनांचे वाटप करण्याचे धोरणात्मक निर्णय घेणे. बदलत्या स्मार्ट परिस्थिती आणि वैयक्तिक परिस्थितीशी जुळवून घेण्यासाठी काळजीपूर्वक नियोजन, संशोधन आणि सतत देखरेखीची आवश्यकता आहे."
                },
                title1: {

                    HINDI: " अर्ध-शहरी और ग्रामीण भारत में निवेश विकल्प आर्थिक विकास को गति देने, वित्तीय समावेशन को बढ़ावा देने, धन सृजन करने, जोखिमों को कम करने, उद्यमशीलता को बढ़ावा देने, बुनियादी ढांचे के विकास को बढ़ावा देने और सामाजिक प्रगति का समर्थन करने के लिए अपरिहार्य हैं। संसाधनों को प्रभावी ढंग से चैनलाइज़ करके और स्थानीय समुदायों को सशक्त बनाकर, ये निवेश रास्ते राष्ट्र के समग्र विकास में महत्वपूर्ण भूमिका निभाते हैं।",
                    ENGLISH: "Investment options in semi-urban and rural India are indispensable for driving economic growth, fostering financial inclusion, creating wealth, mitigating risks, promoting entrepreneurship, spurring infrastructure development, and supporting social progress. By channeling resources effectively and empowering local communities, these investment avenues play a vital role in the holistic development of the nation.",
                    MARATHI: "निमशहरी आणि ग्रामीण भारतातील गुंतवणुकीचे पर्याय आर्थिक विकासाला चालना देण्यासाठी, आर्थिक समावेशनाला चालना देण्यासाठी, संपत्ती निर्माण करण्यासाठी, जोखीम कमी करण्यासाठी, उद्योजकतेला चालना देण्यासाठी, पायाभूत सुविधांच्या विकासाला चालना देण्यासाठी आणि सामाजिक प्रगतीला पाठिंबा देण्यासाठी अपरिहार्य आहेत. संसाधने प्रभावीपणे चॅनेल करून आणि स्थानिक समुदायांना सक्षम बनवून, हे गुंतवणूकीचे मार्ग राष्ट्राच्या सर्वांगीण विकासात महत्त्वपूर्ण भूमिका बजावतात."
                },
                title2: {

                    HINDI: " CSP के ग्राहक प्रतिनिधि के रूप में, आपके पास अपने ग्राहकों के लिए निवेश विकल्प लाने का अवसर है, जो आकर्षक रिटर्न देने में मदद कर सकते हैं और ग्राहकों को अधिक बचत करने के लिए प्रेरित करके उनके व्यवहार को भी प्रभावित कर सकते हैं।",
                    ENGLISH: "As a Grahak Pratinidhi of CSP, one has an opportunity to bring to their customers, investment options, which can help yield handsome return and also impact customer behavior by motivating them to save more.",
                    MARATHI: "CSP चा ग्राहक प्रतिनिधी म्हणून, एखाद्याला त्यांच्या ग्राहकांसमोर गुंतवणुकीचे पर्याय आणण्याची संधी आहे, ज्यामुळे चांगला परतावा मिळू शकतो आणि ग्राहकांना अधिक बचत करण्यास प्रवृत्त करून त्यांच्या वर्तनावर परिणाम होऊ शकतो."
                }
            },
        ],
        digitalgoldheading: {
            HINDI: "डिजिटल गोल्ड",
            ENGLISH: "Digital Gold",
            MARATHI: "डिजिटल गोल्ड"
        },
        digitalgoldpara: [
            {
                title: {
                    HINDI: "हम भारतीयों के लिए सोना भावनात्मक रूप से बहुत मूल्यवान है, सोना खरीदना सिर्फ़ निवेश ही नहीं है, बल्कि यह पारिवारिक प्रतिष्ठा के समान है। भारत में सोने की बढ़ती खपत इस बात का संकेत है कि देश की संस्कृति में सोने की केंद्रीय भूमिका है और इसे धन का प्रतीक माना जाता है। 2018 की नीति आयोग की रिपोर्ट के अनुसार, सोने की बिक्री का 60% हिस्सा ग्रामीण भारत में होता है।",
                    ENGLISH: "For us, Indians, Gold holds a sentimental value, purchasing gold is not only an investment but is akin to a familial prestige. The growing consumption trend of gold in India is indicative of the central role that gold plays in the country’s culture and its consideration as a symbol of wealth. A 2018 Niti Aayog report attributes 60% of gold sale to rural India.",
                    MARATHI: " भारतीयांसाठी, सोन्याचे भावनिक मूल्य आहे, सोने खरेदी करणे ही केवळ गुंतवणूकच नाही तर कौटुंबिक प्रतिष्ठेसारखी आहे. भारतातील सोन्याचा वाढता वापर ही देशाच्या संस्कृतीत सोन्याची भूमिका निभावत असलेली मध्यवर्ती भूमिका आणि संपत्तीचे प्रतीक म्हणून त्याचा विचार दर्शवतो. 2018 च्या निती आयोगाच्या अहवालात 60% सोने विक्रीचे श्रेय ग्रामीण भारताला दिले आहे."
                },
                title1: {
                    HINDI: "डिजिटल गोल्ड सेगमेंट की पहुँच बढ़ाने में तकनीक एक प्रमुख सहायक होने के कारण, इस बाज़ार के बढ़ने और व्यक्तियों और व्यवसायों की तरलता और पूंजी की ज़रूरतों को पूरा करने की गुंजाइश है",
                    ENGLISH: "With technology being a key enabler in expanding the reach of the digital gold Segment, there is scope for this market to grow and fulfil the liquidity and capital needs of individuals and businesses",
                    MARATHI: "डिजिटल गोल्ड सेगमेंटचा आवाका वाढवण्यामध्ये तंत्रज्ञान हे एक महत्त्वाचे सहाय्यक असल्याने, या बाजाराला वाढण्यास आणि व्यक्ती आणि व्यवसायांच्या तरलता आणि भांडवली गरजा पूर्ण करण्यास वाव आहे."
                },
                title2: {
                    HINDI: "डिजिटल गोल्ड में निवेश करना सोने में निवेश करने का एक किफ़ायती और कुशल तरीका माना जाता है। डिजिटल गोल्ड की प्रत्येक इकाई 24K से ज़्यादा 99% शुद्धता वाले सोने से समर्थित होती है। कोई भी व्यक्ति 100 रुपये से भी कम कीमत में सोना खरीद सकता है। खरीद और बिक्री बाज़ार मूल्य पर ऑनलाइन होती है। ",
                    ENGLISH: "Investing in digital gold is considered to be a cost-effective and efficient way of investing in gold. Each unit of digital gold is backed by 24K over 99% purity gold. One can buy gold with an amount as low as INR 100. The purchase and sale happens online at market prices.",
                    MARATHI: "डिजिटल सोन्यात गुंतवणूक करणे हा सोन्यात गुंतवणुकीचा किफायतशीर आणि कार्यक्षम मार्ग मानला जातो. डिजिटल सोन्याच्या प्रत्येक युनिटला 99% शुद्ध सोन्यापेक्षा 24K चे समर्थन आहे. INR 100 एवढ्या कमी रकमेमध्ये कोणीही सोने खरेदी करू शकतो. खरेदी आणि विक्री ही बाजारभावानुसार ऑनलाइन होते."
                },
                title3: {
                    HINDI: "CSP ने Safegold के साथ रणनीतिक गठबंधन किया है, जो एक डिजिटल प्लेटफ़ॉर्म है जो ग्राहकों को कम कीमत पर वॉल्टेड सोना खरीदने, बेचने और प्राप्त करने की अनुमति देता है, यह आपके ग्राहकों को एक बेहतरीन निवेश विकल्प यानी डिजिटल गोल्ड प्रदान करता है। उत्पाद के मुख्य लाभ इस प्रकार हैं:-",
                    ENGLISH: "CSP in Strategic alliance with Safegold ,a digital platform that allows customers to buy, sell and receive vaulted gold at low ticket sizes, brings to you to provide to your customers, an excellent investment option i.e. Digital Gold.",
                    MARATHI: "सेफगोल्डसह धोरणात्मक युतीमध्ये CSP, ग्राहकांना कमी तिकीट आकारात व्हॉल्टेड सोने खरेदी, विक्री आणि प्राप्त करण्यास अनुमती देणारे डिजिटल प्लॅटफॉर्म, तुमच्या ग्राहकांना एक उत्कृष्ट गुंतवणूक पर्याय म्हणजे डिजिटल गोल्ड प्रदान करण्यासाठी तुमच्यासाठी आणते."
                },
            },
        ],
        digitalgolbelow: {
            HINDI: "उत्पाद के मुख्य लाभ इस प्रकार हैं:-",
            ENGLISH: "Key benefits of the product are as below :-",
            MARATHI: "उत्पादनाचे मुख्य फायदे खालीलप्रमाणे आहेत:-"
        },

        digitalgoldlist: [
            {
                title: {
                    HINDI: "24K सोने की गारंटी",
                    ENGLISH: "Guaranteed 24K Gold",
                    MARATHI: "24K सोन्याची हमी"
                },
                subtitle: {
                    HINDI: "स्थानीय विक्रेताओं के विपरीत, SafeGold के साथ, आप सीधे निर्माता से खरीदते हैं",
                    ENGLISH: "Unlike local vendors, with SafeGold, you directly buy from the manufacturer",
                    MARATHI: "स्थानिक विक्रेत्यांपेक्षा वेगळे, SafeGold सह, तुम्ही थेट निर्मात्याकडून खरेदी करता"
                }
            },
            {
                title: {
                    HINDI: "घर से कभी भी बेचें",
                    ENGLISH: "Sell anytime from home",
                    MARATHI: "घरबसल्या केव्हाही विक्री करा"
                },
                subtitle: {
                    HINDI: "बिना कहीं जाए, कभी भी बेचें और सीधे अपने खाते में पैसे प्राप्त करें।",
                    ENGLISH: "Sell anytime, without going anywhere and receive money direct in your account.",
                    MARATHI: "कुठेही न जाता कधीही विक्री करा आणि थेट तुमच्या खात्यात पैसे मिळवा."
                }
            },
            {
                title: {
                    HINDI: "सोने पर आय अर्जित करें",
                    ENGLISH: "Earn income on gold",
                    MARATHI: "सोन्यावर उत्पन्न मिळवा"
                },
                subtitle: {
                    HINDI: "आप SafeGold-सत्यापित उधारकर्ताओं को डिजिटल सोना उधार दे सकते हैं और सोने के रूप में मासिक किराये की आय अर्जित कर सकते हैं।",
                    ENGLISH: "You can lend digital gold to SafeGold-verified borrowers and earn monthly rental income in the form of gold.",
                    MARATHI: "तुम्ही SafeGold-सत्यापित कर्जदारांना डिजिटल सोने उधार देऊ शकता आणि सोन्याच्या रूपात मासिक भाड्याचे उत्पन्न मिळवू शकता.",
                }
            },
            {
                title: {
                    HINDI: "सुरक्षा की गारंटी",
                    ENGLISH: "Safety guaranteed",
                    MARATHI: "सुरक्षिततेची हमी"
                },
                subtitle: {
                    HINDI: "भौतिक सोने के विपरीत, आपको चोरी या महंगे लॉकर शुल्क के बारे में चिंता करने की ज़रूरत नहीं है। आपका सोना बैंक-ग्रेड लॉकर में निःशुल्क संग्रहीत किया जाता है।",
                    ENGLISH: "Unlike physical gold, you don’t have to worry about theft or expensive locker fees. You gold is stored in bank-grade lockers free of cost.",
                    MARATHI: "भौतिक सोन्याप्रमाणे, तुम्हाला चोरी किंवा महागड्या लॉकर फीबद्दल काळजी करण्याची गरज नाही. तुमचे सोने बँक-ग्रेड लॉकरमध्ये मोफत साठवले जाते.",
                }
            },
            {
                title: {
                    HINDI: "भौतिक सोने में बदलें",
                    ENGLISH: "Convert to physical gold",
                    MARATHI: "भौतिक सोन्यामध्ये रूपांतरित करा"
                },
                subtitle: {
                    HINDI: "आप हमारे भागीदारों के माध्यम से अपने डिजिटल सोने को कभी भी सिक्कों या आभूषणों के रूप में भौतिक सोने में बदल सकते हैं।",
                    ENGLISH: "You can convert your digital gold to physical gold anytime in the form of coins or jewellery through our partners.",
                    MARATHI: "रिअल टाइम कमिशन*"
                }
            },
            {
                title: {
                    HINDI: "₹10 से खरीदें",
                    ENGLISH: "Buy as low as ₹10",
                    MARATHI: "₹१० इतके कमी खरेदी करा"
                },
                subtitle: {
                    HINDI: "डिजिटल के लिए बड़ी राशि का निवेश करने की आवश्यकता नहीं होती है। आप अपने बजट के आधार पर निवेश कर सकते हैं",
                    ENGLISH: "Digital does not require to invest a large sum of money. You can invest based on your budget",
                    MARATHI: "डिजिटलसाठी मोठ्या रकमेची गुंतवणूक करण्याची गरज नाही. तुम्ही तुमच्या बजेटनुसार गुंतवणूक करू शकता"
                }
            },
        ],
        fordeatils: {
            HINDI: "विवरण के लिए csp.in पर लॉग इन करें",
            ENGLISH: "For details log in to csp.in",
            MARATHI: "तपशीलांसाठी csp.in वर लॉग इन करा"
        },


        mutualfundheading: {
            HINDI: "म्यूचुअल फंड",
            ENGLISH: "Mutual Fund",
            MARATHI: "म्यूचुअल फंड"
        },
        mutualfundheadingpara: [
            {
                title: {
                    HINDI: "म्यूचुअल फंड कई निवेशकों से विभिन्न प्रकार के निवेश खरीदने के लिए धन को जोड़ते हैं। इन परिसंपत्तियों का प्रबंधन पेशेवर निवेश प्रबंधकों द्वारा किया जाता है, जिनका उद्देश्य निवेशकों के लिए रिटर्न सृजित करना है। पेशेवर प्रबंधक तय करते हैं कि फंड के लिए कौन सा निवेश खरीदा और बेचता है। एक पेशेवर फंड प्रबंधक निवेश के इस मिश्रण को संभालता है, और इसकी परिसंपत्तियों और लक्ष्यों को एस फंड्स प्रॉस्पेक्टस में विस्तृत किया गया है।",
                    ENGLISH: "Mutual funds combine money from many investors to buy a variety of investments. The assets are managed by professional investment managers, who aim to generate returns for the investors. Professional managers decide which investments to buy and sell for the fund. A professional fund manager handles this mix of investments, and its assets and goals are detailed in s funds prospectus.",
                    MARATHI: "म्युच्युअल फंड विविध गुंतवणूक किंवा खरेदी करण्यासाठी अनेक गुंतवणूकदारांकडून पैसे एकत्र करतात. मालमत्तेचे व्यवस्थापन व्यावसायिक गुंतवणूक व्यवस्थापकांद्वारे केले जाते, ज्यांचे उद्दिष्ट गुंतवणूकदारांना परतावा देण्याचे असते. फंडासाठी कोणती गुंतवणूक खरेदी आणि विक्री करायची हे व्यावसायिक व्यवस्थापक ठरवतात. एक व्यावसायिक निधी व्यवस्थापक हे गुंतवणुकीचे मिश्रण हाताळतो. "
                },
                title1: {
                    HINDI: "बाजार में उपलब्ध म्यूचुअल फंड विकल्पों के अलावा, व्यक्तियों के लिए निवेश के संदर्भ में एक स्मार्ट दृष्टिकोण व्यवस्थित निवेश योजनाओं या एसआईपी में निवेश करना है। एसआईपी निवेश के लिए एक व्यवस्थित दृष्टिकोण है और इसमें नियमित अंतराल पर बाजार में निवेश के लिए पूर्व निर्धारित राशि का आवंटन करना शामिल है। यह वित्तीय अनुशासन को विकसित करने और भविष्य के लिए धन का निर्माण करने में मदद कर सकता है। एसआईपी के साथ, कोई भी छोटी शुरुआत कर सकता है और धीरे-धीरे एक व्यवस्थित और योजनाबद्ध तरीके से एक कॉर्पस बना सकता है और एक व्यक्ति को जोखिम के प्रबंधन में बेहतर ढंग से भाग लेने की अनुमति देता है।",
                    ENGLISH: "Apart from the Mutual fund options available in the market, a smart approach in terms of investment for individuals is to invest into Systematic Investment Plans or SIPs. A SIP is a systematic approach to investing and involves allocating a small pre-determined amount of money for investment in the market at regular intervals IT can help inculcate financial discipline and build wealth for the future. With SIPs, one can start small and gradually build a corpus in a systematic and planned manner and allows one to participate in the market managing risk better.",
                    MARATHI: "बाजारात उपलब्ध म्युच्युअल फंड पर्यायांव्यतिरिक्त, व्यक्तींसाठी गुंतवणूकीच्या दृष्टीने एक स्मार्ट दृष्टीकोन म्हणजे पद्धतशीर गुंतवणूक योजना किंवा SIP मध्ये गुंतवणूक करणे. एसआयपी हा गुंतवणुकीसाठी एक पद्धतशीर दृष्टीकोन आहे आणि त्यात नियमित अंतराने बाजारात गुंतवणुकीसाठी थोड्या पूर्व-निर्धारित रकमेचे वाटप करणे समाविष्ट आहे IT आर्थिक शिस्त लावण्यासाठी आणि भविष्यासाठी संपत्ती निर्माण करण्यात मदत करू शकते. SIPs सह, एखादी व्यक्ती लहानशी सुरुवात करू शकते आणि हळूहळू पद्धतशीर आणि नियोजित पद्धतीने एक कॉर्पस तयार करू शकते आणि जोखीम चांगल्या प्रकारे व्यवस्थापित करण्यासाठी मार्केटमध्ये सहभागी होऊ शकते."
                },
            },
        ],
        demataccountheading: {
            HINDI: "डीमैट खाता ",
            ENGLISH: "Demat Account",
            MARATHI: "डीमॅट खाते"
        },
        demataccountpara: [
            {
                title: {
                    HINDI: "डीमैट खाता (demat account) एक प्रकार का इलेक्ट्रॉनिक खाता (electronic account) होता है, जिसका प्रयोग प्रतिभूतियों (stocks), बॉन्ड (bond), म्यूचुअल फंड्स (mutual funds) और अन्य वित्तीय साधनों (financial instruments) को डिजिटल रूप में रखने के लिए किया जाता है। पिछले 10 वर्षों के दौरान भारतीय बाजारों ने बेंचमार्क स्‍तर पर 12-13 प्रतिशत कम्‍प्‍यूटिंग दी है।",
                    ENGLISH: "A demat account also known as a dematerialized account is a type of electronic account used to hold securities such as stocks, bonds, mutual funds, and other financial instruments in digital form. It is a safe and convenient way to hold, trade, and manage investments without physical documents or certificates.The Indian markets over the last 10 years have given 12-13% compounded on the benchmark level.",
                    MARATHI: "डिमॅट खाते हे डिमॅट खाते म्हणून ओळखले जाणारे इलेक्ट्रॉनिक खाते आहे जे सिक्युरिटीज जसे की स्टॉक, बाँड, म्युच्युअल फंड आणि इतर आर्थिक साधने डिजिटल स्वरूपात ठेवण्यासाठी वापरले जाते. भौतिक कागदपत्रे किंवा प्रमाणपत्रांशिवाय गुंतवणूक ठेवण्याचा, व्यापार करण्याचा आणि व्यवस्थापित करण्याचा हा एक सुरक्षित आणि सोयीस्कर मार्ग आहे. गेल्या 10 वर्षांत भारतीय बाजारांनी बेंचमार्क स्तरावर 12-13% चक्रवाढ दिली आहे."
                },
            },
        ],

        demataccountist: [
            {
                title: {
                    HINDI: "दिसंबर 2023 में 4.2 मिलियन से अधिक डीमैट खाते खोले गए, ट्रेंड्स ने घातीय वृद्धि का संकेत दिया",
                    ENGLISH: "Over 4.2 million Demat accounts were opened in December 2023, trends indicate an exponential Growth",
                    MARATHI: "डिसेंबर 2023 मध्ये 4.2 दशलक्षाहून अधिक डिमॅट खाती उघडण्यात आली, ट्रेंड घातांकीय वाढ दर्शवितात"
                },
                subtitle: {
                    HINDI: "स्थानीय विक्रेताओं के विपरीत, SafeGold के साथ, आप सीधे निर्माता से खरीदते हैं",
                    ENGLISH: "Unlike local vendors, with SafeGold, you directly buy from the manufacturer",
                    MARATHI: "स्थानिक विक्रेत्यांपेक्षा वेगळे, SafeGold सह, तुम्ही थेट निर्मात्याकडून खरेदी करता"
                }
            },
            {
                title: {
                    HINDI: "पहली बार 25-35 वर्ष की आयु वर्ग के निवेशक बड़ी संख्‍या में आ रहे हैं",
                    ENGLISH: "There has been a sizeable influx in the first time investors who are in the age group of 25-35 years",
                    MARATHI: "2 5-35 वर्षे वयोगटातील प्रथमच गुंतवणूकदारांमध्ये मोठ्या प्रमाणात वाढ झाली आहे."
                },
                subtitle: {
                    HINDI: "बिना कहीं जाए, कभी भी बेचें और सीधे अपने खाते में पैसे प्राप्त करें।",
                    ENGLISH: "Sell anytime, without going anywhere and receive money direct in your account.",
                    MARATHI: "कुठेही न जाता कधीही विक्री करा आणि थेट तुमच्या खात्यात पैसे मिळवा."
                }
            },
            {
                title: {
                    HINDI: "जो लोग 5-10 साल से काम कर रहे हैं और 5-15 लाख आय के वार्षिक वेतन वर्ग में बाजार में भाग ले रहे हैं",
                    ENGLISH: "People who have been working from 5-10 years and in an annual salary bracket of 5-15 lacs income are participating in the market",
                    MARATHI: "जे लोक 5-10 वर्षांपासून काम करत आहेत आणि त्यांचा वार्षिक पगार  कंसात 5-15 लाख उत्पन्न आहेत ते मार्केटमध्ये भाग घेत आहेत."
                },
                subtitle: {
                    HINDI: "आप SafeGold-सत्यापित उधारकर्ताओं को डिजिटल सोना उधार दे सकते हैं और सोने के रूप में मासिक किराये की आय अर्जित कर सकते हैं।",
                    ENGLISH: "You can lend digital gold to SafeGold-verified borrowers and earn monthly rental income in the form of gold.",
                    MARATHI: "तुम्ही SafeGold-सत्यापित कर्जदारांना डिजिटल सोने उधार देऊ शकता आणि सोन्याच्या रूपात मासिक भाड्याचे उत्पन्न मिळवू शकता.",
                }
            },
            {
                title: {
                    HINDI: "टीयर 2 और 3 भौगोलिक क्षेत्रों से शेयर बाजारों में भागीदारी के संबंध में महत्वपूर्ण प्रगति देखी गई है",
                    ENGLISH: "Significant traction with regard to participation in stock markets have been observed from Tier 2 and 3 geographies",
                    MARATHI: "टियर 2 आणि 3 भौगोलिक क्षेत्रांतून शेअर बाजारातील सहभागाबाबत लक्षणीय वाढ दिसून आली आहे."
                },
                subtitle: {
                    HINDI: "भौतिक सोने के विपरीत, आपको चोरी या महंगे लॉकर शुल्क के बारे में चिंता करने की ज़रूरत नहीं है। आपका सोना बैंक-ग्रेड लॉकर में निःशुल्क संग्रहीत किया जाता है।",
                    ENGLISH: "Unlike physical gold, you don’t have to worry about theft or expensive locker fees. You gold is stored in bank-grade lockers free of cost.",
                    MARATHI: "भौतिक सोन्याप्रमाणे, तुम्हाला चोरी किंवा महागड्या लॉकर फीबद्दल काळजी करण्याची गरज नाही. तुमचे सोने बँक-ग्रेड लॉकरमध्ये मोफत साठवले जाते.",
                }
            },
        ],

    }
}