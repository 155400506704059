import React from 'react'
import './Enterprise.css'
import Img1 from './1.png'
import Img5 from './5.png'
import Img6 from './6.png'
import Img10 from './10.png'

const Enterprise = () => {
    return (
        <div className='enterprise-container'>
            <div className='enterprise-main-container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-auto'>
                        <h2 className='mb-5'>
                            Trusted By Top Enterprises
                        </h2>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center py-4 enterprise-img-col'>
                        <img src={Img6} className='enterprise-img' alt='enterprise-imgs' />
                    </div>

                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center py-4 enterprise-img-col'>
                        <img src={Img5} className='enterprise-img py-2 px-1' alt='enterprise-imgs' />
                    </div>

                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center py-4 enterprise-img-col'>
                        <img src={Img10} alt='enterprise-imgs' />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-6 d-flex justify-content-center align-items-center py-4 enterprise-img-col'>
                        <img src={Img1} alt='enterprise-imgs' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Enterprise