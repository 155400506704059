import React from 'react'
import './Egovhome.css'
import BankingHomeimg from '../../Banking/BankingImg/Banking-img.jpg'
import { Link } from 'react-router-dom'


const EgovHome = () => {
    return (
        <div className='egov-home-container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 p-0'>
                        <div className='egov-left-content text-dark d-flex flex-column'>
                            <h2>Lorem ipsum dolor sit amet, <span>consectetur adipiscing</span></h2>
                            <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </h6>
                        </div>
                        <div className='egov-home-list'>
                            <ul>
                                <li>
                                    Lorem ipsum dolor sit amet,
                                </li>
                                <li>
                                    Lorem ipsum dolor sit amet,
                                </li>
                                <li>
                                    Lorem ipsum dolor sit amet,
                                </li>
                            </ul>
                            <div className='responsive-btn mt-3 d-flex'>
                                <Link to="/Login">
                                    <button style={{ backgroundColor: '#2C5363', color: "#fff" }} className='btn csp-home-btn d-flex align-items-center px-3 px-lg-4 rounded-pill' >
                                        Get Started
                                        <span className='d-flex home-btn-arrow' style={{ transitionDuration: '0.3s' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center py-3'>
                        <img className='egov-home-img' src={BankingHomeimg} alt='aboutimg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EgovHome