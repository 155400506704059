import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';

const CspBuisnessLoan = () => {


    const ServicesAeps = [

        {
            title: 'Business Loan (Upto 25 Lacs) and  MSME Loans',
            paragraph: 'A Business loan refers to a financial product offered by banks, non-banking financial companies(NBFCs), or government institutions to support the capital requirements of businesses operatingin various sectors of the economy. These loans are essential for business growth, expansion,and meeting day-to-day operational expenses.'
        },
        {
            title: 'Business Loan (Upto 25 Lacs) and  MSME Loans',
            paragraph: 'Offer to your customers unsecured business loans and MSME loans for various purposes, such as commercial loan for construction, commercial loan against property, commercial loan for business, commercial loan for shop, loan for factory, loan for machinery purchase, working capital for restaurant, business financing, and more. Key Features of the offering are loan tenure Flexibility, minimal documents, low interest rates, and fast approval.'
        },


    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 20 }}>
                    {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.businessheading)}
                </h1>
                <ul className="bottom-list">
                    {FinancialServicePageData.FINANCIALSERVICES.businessservicepara.map((sec, i) => (
                        <li>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title1)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                    <a href='window.location.origin/version2/auth/login'><p> {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspBuisnessLoan;
