import React from 'react'
import './Mission-Vision.css'
import MissionImg from '../../AboutNew/Assets/mission.png'
import VisionImg from '../../AboutNew/Assets/vision.svg'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { AboutPageData } from '../../Data/AboutPageData'

const MissionVision = () => {
    return (
        <div className='mission-container'>
            <div className='mission-main-container'>
                <div className='pb-5 d-flex justify-content-center'>
                    <h2>
                        {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.vissonandmission)}
                    </h2>
                </div>
                <div className='row  justify-content-center align-items-center '>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center mission-outer-padding'>
                        <div className='about-card position-relative px-3 py-5'>
                            <div className='about-card-content d-flex flex-column'>
                                <div className='icon d-flex align-items-center'>
                                    <img className='about-mission-img' src={VisionImg} alt='mission' />
                                    <div className='px-2'><div className='hr'></div></div>
                                </div>
                                <h5 className='pt-3 pb-2 '>
                                    {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.visson)}
                                </h5>
                                <p className=''>
                                    {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.vissoncontent)}
                                </p>
                            </div>
                            <div className='position-absolute rotate-text-vision m-0'>
                                <h5>Vision</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center mission-outer-padding'>
                        <div className='about-card about-color-card position-relative px-4 py-4'>



                            <div className='about-card-content d-flex flex-column'>
                                <div className='icon d-flex align-items-center'>
                                    <img className='about-mission-img' src={MissionImg} alt='mission' />
                                    <div className='px-2'><div className='hr'></div></div>
                                </div>
                                <h5 className='pt-3 pb-2'>
                                    {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.mission)}
                                </h5>
                                <p>
                                    {GetTranlatedValue(AboutPageData?.ABOUTHEADING?.missioncontent)}
                                </p>
                            </div>
                            <div className='position-absolute rotate-text-mission m-0'>
                                <h5>Mission</h5>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MissionVision