import React from 'react'
import './Goverment.css'

const Govermentsub = () => {



    return (
        <div className='goverment-sub-container'>
            <div className='goverment-sub-main-container'>
                {/* <h2 className='text-white text-center'>Goverment Services</h2> */}
                <div className='row text-center icon-services-container pb-3 pt-1'>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='goverment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-id-card d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Pan Card
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='goverment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-identity-card d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Voter ID
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='goverment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-id-card d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Aayushman Bharat
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='goverment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-badge d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            eShram Card
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='goverment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-id-card-1 d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Jeevan Pramaan
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='goverment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-app d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            More Services
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Govermentsub